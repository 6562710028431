import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "../../containers/Shared";

/**
 * This is the Modal Component
 *
 * @param {number} pageCount - Accepts a number to set number of pages
 * @param {func} onPageChange- Accepts a function to handle on click will callback with selected page no
 * @param {number} activePage - Accepts a number which denotes the selected page number
 * @example
 *
 * <QIPagination pageCount={10} onPageChange={(requestedPage)=>{}} activepage={1}>
 */

export const QIPagination = ({
  pageCount,
  onPageChange,
  activePage,
  setDockAlign,
  setShowDetails,
  removeLayerOnClose,
  setDeviceId = () => {},
  setHighlight,
}) => {
  const PageItem = ({ disabled = false, pageRef, onClick, children }) => {
    return (
      <li
        className={`qi-pagination_item ${pageRef == activePage ? "active" : ""} ${
          disabled ? "disabled" : ""
        }`}
        onClick={onClick}
      >
        <span className={`qi-pagination_item_link`}>{children}</span>
      </li>
    );
  };

  const onPageChangeHandler = (requestPage) => {
    if (requestPage !== activePage && requestPage >= 1 && requestPage <= pageCount) {
      onPageChange(requestPage);
      try {
        setShowDetails(false);
        setDockAlign("cross");
        removeLayerOnClose();
        setDeviceId(null);
        setHighlight(null);
      } catch (e) {}
    }
  };

  const manageEndPages = () => {
    if (activePage === 1) {
      items = [
        <PageItem pageRef={1} onClick={() => onPageChangeHandler(1)}>
          {1}
        </PageItem>,
        pageCount > 1 && (
          <PageItem pageRef={2} onClick={() => onPageChangeHandler(2)}>
            {2}
          </PageItem>
        ),
        pageCount > 2 && <PageItem onClick={() => {}}>...</PageItem>,
        pageCount > 2 && (
          <PageItem pageRef={pageCount} onClick={() => onPageChangeHandler(pageCount)}>
            {pageCount}
          </PageItem>
        ),
      ];
    } else if (activePage === pageCount) {
      items = [
        <PageItem pageRef={1} onClick={() => onPageChangeHandler(1)}>
          {1}
        </PageItem>,
        pageCount > 2 && <PageItem onClick={() => {}}>...</PageItem>,
        <PageItem pageRef={activePage - 1} onClick={() => onPageChangeHandler(activePage - 1)}>
          {activePage - 1}
        </PageItem>,
        <PageItem pageRef={activePage} onClick={() => onPageChangeHandler(activePage)}>
          {activePage}
        </PageItem>,
      ];
    } else if (activePage === pageCount - 1) {
      items = [
        <PageItem pageRef={1} onClick={() => onPageChangeHandler(1)}>
          {1}
        </PageItem>,
        pageCount > 2 && <PageItem onClick={() => {}}>...</PageItem>,

        <PageItem pageRef={activePage} onClick={() => onPageChangeHandler(activePage)}>
          {activePage}
        </PageItem>,
        pageCount > 2 && (
          <PageItem pageRef={pageCount} onClick={() => onPageChangeHandler(pageCount)}>
            {pageCount}
          </PageItem>
        ),
      ];
    } else if (activePage === pageCount - 2) {
      items = [
        <PageItem pageRef={1} onClick={() => onPageChangeHandler(1)}>
          {1}
        </PageItem>,
        pageCount > 2 && <PageItem onClick={() => {}}>...</PageItem>,

        <PageItem pageRef={activePage} onClick={() => onPageChangeHandler(activePage)}>
          {activePage}
        </PageItem>,
        pageCount > 2 && (
          <PageItem pageRef={pageCount} onClick={() => onPageChangeHandler(activePage + 1)}>
            {activePage + 1}
          </PageItem>
        ),
        pageCount > 2 && (
          <PageItem pageRef={pageCount} onClick={() => onPageChangeHandler(pageCount)}>
            {pageCount}
          </PageItem>
        ),
      ];
    } else {
      items = [
        <PageItem pageRef={activePage} onClick={() => onPageChangeHandler(activePage)}>
          {activePage}
        </PageItem>,
        pageCount > activePage + 1 && (
          <PageItem pageRef={activePage + 1} onClick={() => onPageChangeHandler(activePage + 1)}>
            {activePage + 1}
          </PageItem>
        ),
        pageCount > activePage + 2 && <PageItem onClick={() => {}}>...</PageItem>,
        pageCount > activePage + 2 && (
          <PageItem pageRef={pageCount} onClick={() => onPageChangeHandler(pageCount)}>
            {pageCount}
          </PageItem>
        ),
      ];
    }
  };

  let items = [];

  if (pageCount >= 5) {
    for (let i = activePage - 2; i <= activePage + 2 && i <= pageCount; i += 1) {
      if (i >= 1) {
        items.push(
          <PageItem pageRef={i} active={i === activePage} onClick={() => onPageChangeHandler(i)}>
            {i}
          </PageItem>
        );
      }
    }
    manageEndPages();
  } else {
    for (let i = 1; i <= pageCount; i += 1) {
      items.push(
        <PageItem pageRef={i} active={i === activePage} onClick={() => onPageChangeHandler(i)}>
          {i}
        </PageItem>
      );
    }
  }

  return (
    <ul className="qi-pagination">
      <PageItem disabled={activePage === 1} onClick={() => onPageChangeHandler(1)}>
        <SvgIcon name="up-arrow-double" wrapperClass="first" svgClass="" />
      </PageItem>
      <PageItem disabled={activePage === 1} onClick={() => onPageChangeHandler(activePage - 1)}>
        <SvgIcon name="up-arrow" wrapperClass="previous" svgClass="" />
      </PageItem>
      {items.map((Item, index) => (
        <React.Fragment key={index}>{Item}</React.Fragment>
      ))}
      <PageItem
        disabled={activePage === pageCount}
        onClick={() => onPageChangeHandler(activePage + 1)}
      >
        <SvgIcon name="up-arrow" wrapperClass="next" svgClass="rotate-180" />
      </PageItem>
      <PageItem disabled={activePage === pageCount} onClick={() => onPageChangeHandler(pageCount)}>
        <SvgIcon name="up-arrow-double" wrapperClass="last" svgClass="rotate-180" />
      </PageItem>
    </ul>
  );
};

QIPagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
