import React from "react";
import { QITrackingTable } from "../../../components";
import { MPDeleteButton, MPEditButton } from "./Button";
import { utcToLocal } from "../../../shared/utility";
import { CurrentUser } from "../../../shared/currentUser";
import { Loading, FetchError, PaginationHeader, PaginationFooter, NoData } from "../../Shared";

export const TrackingTable = ({
  resourceName,
  data,
  error,
  isLoading,
  isSuccess,
  headers,
  pagination,
  onEdit,
  onDelete,
  skipClientId = false,
  tableHandlers = {},
  setHighlight,
  highlight,
  setShowDetails,
  setDockAlign,
  removeLayerOnClose,
  setDeviceId = () => {},
  setShowTrack,
  simpleSearch,
}) => {
  const currentUser = new CurrentUser(resourceName);

  const Actions = ({ data }) => {
    return (
      <>
        {(data.client_id || skipClientId) && (
          <>
            {currentUser.canUpdate && (
              <MPEditButton className="me-2" onClick={() => onEdit(data)} />
            )}
            {currentUser.canDelete && <MPDeleteButton onClick={() => onDelete(data)} />}
          </>
        )}
      </>
    );
  };

  // update created at
  let updatedHeaders = headers.map((header) => {
    if (header.key === "created_at" || header.utc) {
      return {
        ...header,
        label: header.label,
        nestedValue: true,
        getNestedValue: ({ created_at }) => utcToLocal(created_at),
      };
    }

    return header;
  });

  if (onEdit || onDelete) {
    updatedHeaders.push({ label: "", type: "component", component: Actions, className: "action" });
  }

  return (
    <>
      <section className="">
        {isLoading && <Loading />}
        {!isLoading && error && <FetchError />}

        <div className="">
          {isSuccess && data.length > 0 ? (
            <>
              {!simpleSearch && <PaginationHeader pagination={pagination} />}
              <QITrackingTable
                headers={updatedHeaders}
                data={data}
                handler={tableHandlers}
                className="device-list"
                setHighlight={setHighlight}
                highlight={highlight}
              />
            </>
          ) : (
            <>{!isLoading && <NoData />}</>
          )}
        </div>
      </section>
      {isSuccess && pagination && !simpleSearch && (
        <PaginationFooter
          pagination={pagination}
          showHeader={false}
          setDockAlign={setDockAlign}
          setShowDetails={setShowDetails}
          removeLayerOnClose={removeLayerOnClose}
          setDeviceId={setDeviceId}
          setShowTrack={setShowTrack}
          setHighlight={setHighlight}
        />
      )}
    </>
  );
};
