import React, { useEffect, useMemo, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { QIFlashScreen, QISpinner } from "../../components";
import { NativeSvgIcon } from "./Shared/";
import { CommonHeader } from "./Shared";
import { useGetLanguagesQuery, useGetLocalizationsQuery, useGetContainersQuery } from "./services";
import { tHelper } from "../../shared/translation";
import { English, Bahasa, Malay, Chinese } from "../../lang";
import selectedLanguage from "../../shared/selectedLanguage.json";
import { FMDPStorage } from "../../shared/helper";
import { useDispatch, useSelector } from "react-redux";
import { useSelectClientAppMutation } from "../Authentication/service";
import { setlastVisitedApp } from "../../reduxStore/features";
import { getLocalizedString } from "../../shared/translation";
//Toast Message
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BytesizeIcon from "./Shared/BytesizeIcon";

export const MPLayout = ({ children, ...props }) => {
  const [selectedAppId, setLayoutSelectedAppId] = useState(FMDPStorage.get("selected-app-id"));
  const [appToToggle, setAppToToggle] = useState(selectedAppId);
  const [switchClicked, setSwitchClicked] = useState(false);
  const flashScreenStatus = useSelector((state) => state.common.flash_screen);
  const [showDefaultLangMsg, setShowDefaultLangMsg] = useState(false);

  const dispatch = useDispatch();
  const [currentUserLanguage, setCurrentUserLanguage] = useState(
    FMDPStorage?.get("current-user")?.locale?.code
      ? FMDPStorage?.get("current-user")?.locale?.code
      : "en"
  );

  const isReseller = FMDPStorage?.get("reseller");

  const [
    selectClientApp,
    {
      isSuccess: selectClientAppSuccess,
      data: clientAppData,
      reset: resetSelectClientApp,
      error: selectClientAppError,
    },
  ] = useSelectClientAppMutation({
    fixedCacheKey: "shared-client-app-data",
  });

  const currentUser = FMDPStorage?.get("current-user")?.client_apps;

  const iDforClient = currentUser?.find((user, id) => {
    return user.id === selectedAppId;
  })?.client?.id;

  const filteredClients = currentUser?.filter((user) => {
    return user?.client?.id === iDforClient;
  });

  // Get the user config json and the localization profile
  let userConfigJson = "";
  let LocalizationProfile = "";
  filteredClients?.map((user) => {
    if (user?.id === selectedAppId) {
      userConfigJson = user?.client?.config_json;
    }
  });

  try {
    LocalizationProfile = JSON.parse(userConfigJson)?.tracking?.web?.localization_profile;
  } catch {}

  useEffect(() => {
    if (switchClicked) {
      try {
        if (filteredClients.length > 1) {
          const toggleId = filteredClients?.filter((item) => {
            return item.id !== selectedAppId;
          });
          setAppToToggle(toggleId[0]?.id);

          // Execute selectClientApp only if appToToggle has changed
          if (toggleId[0]?.id !== appToToggle) {
            selectClientApp({ client_app_id: toggleId[0]?.id });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [selectedAppId, currentUser, switchClicked, filteredClients, appToToggle]);

  const {
    data: localizationData,
    isSuccess: isLocalizationSuccess,
    isLoading: isLocalizationLoading,
    error: localizationError,
  } = useGetLocalizationsQuery({
    language: currentUserLanguage,
    profile: LocalizationProfile || "Tracksynq Web",
    per_page: 1000,
  });

  const [transformedData, setTransformedData] = useState({});

  useEffect(() => {
    const result = {};
    if (localizationData?.data) {
      localizationData?.data?.forEach((item) => {
        result[item.key] = item.value;
      });
      setTransformedData(JSON.stringify(result, null, 2));
    }
  }, [localizationData]);

  useEffect(() => {
    if (currentUserLanguage && localizationData?.data?.length === 0) {
      setShowDefaultLangMsg(true);
    } else {
      setShowDefaultLangMsg(false);
    }
  }, [currentUserLanguage, localizationData]);

  const { data: languages, isSuccess: languageSuccess } = useGetLanguagesQuery({
    per_page: 1000,
    order_by: "name",
  });

  useEffect(() => {
    switch (currentUserLanguage) {
      case "en":
        selectedLanguage.Strings = English;
        break;
      case "id":
        selectedLanguage.Strings = Bahasa;
        break;
      case "ms":
        selectedLanguage.Strings = Malay;
        break;
      case "zh-TW":
        selectedLanguage.Strings = Chinese;
        break;
      default:
        console.log(
          "The application will use English as the following locale not supported: ",
          currentUserLanguage
        );
        selectedLanguage.Strings = English;
        break;
    }
  }, [currentUserLanguage]);

  useEffect(() => {
    if (transformedData) {
      tHelper.setLocalizations(transformedData);
    }
  }, [transformedData]);

  const { data: newContainers } = useGetContainersQuery({ page: 0, per_page: 500 });

  const mpcontainers = newContainers?.data?.filter((container) => {
    if (!container?.system_default) {
      return container?.client_app_names.includes("Management Portal");
    }
  });

  const MPEntity = useMemo(() => {
    const baseEntities = isReseller
      ? [
          {
            label: getLocalizedString("dashboard", "Dashboard"),
            path: "dashboard",
            icon: "dashboard",
          },
          {
            label: getLocalizedString("devices", "Devices"),
            path: "devices",
            icon: "device",
            subNavigation: [
              { label: getLocalizedString("devices", "Devices"), path: "devices" },
              { label: getLocalizedString("models", "Models"), path: "devices/models" },
            ],
          },
          {
            label: getLocalizedString("vehicles", "Vehicles"),
            path: "vehicles",
            icon: "vehicle",
            subNavigation: [
              { label: getLocalizedString("vehicles", "Vehicles"), path: "vehicles" },
              {
                label: getLocalizedString("specifications", "Specifications"),
                path: "vehicles/specifications",
              },
              { label: getLocalizedString("variants", "Variants"), path: "vehicles/variants" },
              {
                label: getLocalizedString("models", "Models"),
                path: "vehicles/manufacturers_models",
              },
              {
                label: getLocalizedString("manufacturers", "Manufacturers"),
                path: "vehicles/manufacturers",
              },
              { label: getLocalizedString("classes", "Classes"), path: "vehicles/classes" },
              { label: getLocalizedString("types", "Types"), path: "vehicles/types" },
            ],
          },
          {
            label: getLocalizedString("simcards", "Simcards"),
            path: "sim_cards",
            icon: "simcard",
          },
          {
            label: getLocalizedString("groups", "Groups"),
            path: "groups",
            icon: "group",
            subNavigation: [
              { label: getLocalizedString("vehicles", "Vehicles"), path: "groups/vehicle" },
              { label: getLocalizedString("devices", "Devices"), path: "groups/device" },
              { label: getLocalizedString("users", "Users"), path: "groups/user" },
            ],
          },
          {
            label: getLocalizedString("configurations", "Configurations"),
            path: "configurations",
            icon: "undefined",
            subNavigation: [
              {
                label: getLocalizedString("backends", "Backends"),
                path: "configurations/backends",
              },
              {
                label: getLocalizedString("geofences", "Geofences"),
                path: "configurations/geofence",
              },
              {
                label: getLocalizedString("events", "Events"),
                path: "configurations/events",
              },
              {
                label: getLocalizedString("event_plans", "Event Plans"),
                path: "configurations/event_plans",
              },
              {
                label: getLocalizedString("trips", "Trips"),
                path: "configurations/trips",
              },
              {
                label: getLocalizedString("reports", "Reports"),
                path: "configurations/report",
              },
            ],
          },
          {
            label: getLocalizedString("clients", "Clients"),
            path: "clients",
            icon: "client",
          },
          {
            label: getLocalizedString("users_and_roles", "Users And Roles"),
            path: "users_roles",
            icon: "user",
            subNavigation: [
              { label: getLocalizedString("users", "Users"), path: "users_roles/users" },
              { label: getLocalizedString("roles", "Roles"), path: "users_roles/roles" },
            ],
          },
          {
            label: getLocalizedString("audit_trail", "Audit Trail"),
            path: "audit-trail",
            icon: "audit-log",
          },
          /* {
            label: "Plugins",
            path: "plugin/:path",
            icon: "plugin",
          }, */
        ]
      : [
          {
            label: getLocalizedString("dashboard", "Dashboard"),
            path: "dashboard",
            icon: "dashboard",
          },
          {
            label: getLocalizedString("devices", "Devices"),
            path: "devices",
            icon: "device",
            subNavigation: [
              { label: getLocalizedString("devices", "Devices"), path: "devices" },
              { label: getLocalizedString("models", "Models"), path: "devices/models" },
            ],
          },
          {
            label: getLocalizedString("vehicles", "Vehicles"),
            path: "vehicles",
            icon: "vehicle",
            subNavigation: [
              { label: getLocalizedString("vehicles", "Vehicles"), path: "vehicles" },
              {
                label: getLocalizedString("specifications", "Specifications"),
                path: "vehicles/specifications",
              },
              { label: getLocalizedString("variants", "Variants"), path: "vehicles/variants" },
              {
                label: getLocalizedString("models", "Models"),
                path: "vehicles/manufacturers_models",
              },
              {
                label: getLocalizedString("manufacturers", "Manufacturers"),
                path: "vehicles/manufacturers",
              },
              { label: getLocalizedString("classes", "Classes"), path: "vehicles/classes" },
              { label: getLocalizedString("types", "Types"), path: "vehicles/types" },
            ],
          },
          {
            label: getLocalizedString("simcards", "Simcards"),
            path: "sim_cards",
            icon: "simcard",
          },
          {
            label: getLocalizedString("groups", "Groups"),
            path: "groups",
            icon: "group",
            subNavigation: [
              { label: getLocalizedString("vehicles", "Vehicles"), path: "groups/vehicle" },
              { label: getLocalizedString("devices", "Devices"), path: "groups/device" },
              { label: getLocalizedString("users", "Users"), path: "groups/user" },
            ],
          },
          {
            label: getLocalizedString("configurations", "Configurations"),
            path: "configurations",
            icon: "undefined",
            subNavigation: [
              {
                label: getLocalizedString("backends", "Backends"),
                path: "configurations/backends",
              },
              {
                label: getLocalizedString("geofences", "Geofences"),
                path: "configurations/geofence",
              },
              {
                label: getLocalizedString("events", "Events"),
                path: "configurations/events",
              },
              {
                label: getLocalizedString("event_plans", "Event Plans"),
                path: "configurations/event_plans",
              },
              {
                label: getLocalizedString("trips", "Trips"),
                path: "configurations/trips",
              },
              {
                label: getLocalizedString("reports", "Reports"),
                path: "configurations/report",
              },
            ],
          },
          {
            label: getLocalizedString("users_and_roles", "Users And Roles"),
            path: "users_roles",
            icon: "user",
            subNavigation: [
              { label: getLocalizedString("users", "Users"), path: "users_roles/users" },
              { label: getLocalizedString("roles", "Roles"), path: "users_roles/roles" },
            ],
          },
          {
            label: getLocalizedString("audit_trail", "Audit Trail"),
            path: "audit-trail",
            icon: "audit-log",
          },
          /* {
            label: "Plugins",
            path: "plugin/:path",
            icon: "plugin",
          }, */
        ];

    /* const newContainerEntities =
      mpcontainers?.map((container) => {
        if (!container.plugin_ids.length) {
          return;
        }
        return {
          label: getLocalizedString(container.name, container.name),
          path: `container/${container.path}`,
          icon: container.icon,
        };
      }) || []; */

    return [...baseEntities /*  ...newContainerEntities */];
  }, [mpcontainers]);

  useEffect(() => {
    if (selectedAppId) {
      dispatch(setlastVisitedApp(selectedAppId));
    }
  }, [selectedAppId, dispatch]);

  return (
    <>
      <QIFlashScreen currentAppId={iDforClient} flashScreenStatus={flashScreenStatus}>
        <>
          {isLocalizationSuccess &&
            localizationData?.data?.length === 0 &&
            showDefaultLangMsg &&
            toast.error(
              "The selected language is not supported. Try selecting a different language.",
              {
                toastId: "",
                onClose: () => setShowDefaultLangMsg(false),
              }
            )}
          {isLocalizationSuccess && transformedData && (
            <>
              <CommonHeader
                setLayoutSelectedAppId={setLayoutSelectedAppId}
                appToToggle={appToToggle}
                appName="Management Portal"
              />

              <div className="admin-dashboard flex">
                <aside className="qi-nav-bar primary left">
                  <nav className="qi-nav-bar_navigation">
                    <div>
                      {filteredClients?.length > 1 && (
                        <>
                          {/* Disabled MP */}
                          <div
                            onClick={() => {}}
                            className={`qi-nav-bar_navigation_item active`}
                            key={Math.random()}
                          >
                            <NativeSvgIcon name={"management"} />
                            <div className="qi-nav-bar_navigation_item_info">
                              <span className="">
                                {getLocalizedString("management_protal", "Management Portal")}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      <ul className="qi-nav-bar_navigation_sub-nav">
                        {MPEntity.map((element, index) => {
                          if (element?.path) {
                            return (
                              <li className="qi-nav-bar_navigation_sub-nav_item">
                                <NavLink
                                  to={`/management_portal/${element.path}`}
                                  className={`qi-nav-bar_navigation_sub-nav_item_link ${
                                    element?.subNavigation?.length > 0 ? "" : "visible"
                                  }`}
                                  key={index}
                                >
                                  {!element.path.startsWith("container") && (
                                    <NativeSvgIcon name={`${element.icon}`} />
                                  )}
                                  {element.path.startsWith("container") && (
                                    <BytesizeIcon name={element.icon} />
                                  )}
                                  {element?.subNavigation?.length == undefined && (
                                    <div className="qi-nav-bar_navigation_sub-nav_item_info">
                                      <span className="">{element.label}</span>
                                    </div>
                                  )}
                                </NavLink>

                                {element?.subNavigation?.length > 0 && (
                                  <ul className="qi-nav-bar_navigation_sub-nav_nesting-nav">
                                    {element?.subNavigation?.map((subNav) => (
                                      <li className="qi-nav-bar_navigation_sub-nav_nesting-nav_item">
                                        <NavLink
                                          to={`/management_portal/${subNav.path}`}
                                          className={`qi-nav-bar_navigation_sub-nav_nesting-nav_item_link`}
                                          key={index}
                                        >
                                          <span className="">{subNav.label}</span>
                                        </NavLink>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                    {/* Enabled Tracking */}
                    {filteredClients?.length > 1 && (
                      <div
                        onClick={() => {
                          setSwitchClicked(true);
                        }}
                        className={`qi-nav-bar_navigation_item`}
                        key={Math.random()}
                      >
                        <NativeSvgIcon name={"tracking"} />
                        <div className="qi-nav-bar_navigation_item_info">
                          <span className="">{getLocalizedString("Tracking", "Tracking")}</span>
                        </div>
                      </div>
                    )}
                  </nav>

                  <div className="copyright">
                    <span>&copy;</span>
                    <p className="copyright_info">
                      {getLocalizedString("copy_right", "Copyright")} &copy;
                      {new Date().getFullYear()}{" "}
                      {getLocalizedString(
                        "fleet_management_all_rights_reserved",
                        "Fleet Management. All rights reserved."
                      )}
                    </p>
                  </div>
                </aside>

                <main className="tab-content grow">
                  <Outlet />
                </main>
              </div>
            </>
          )}
          {isLocalizationLoading && (
            <main className="login-layout">
              <section className="text-center happenings">
                <QISpinner />
              </section>
            </main>
          )}
          {localizationError && (
            <div>{getLocalizedString("something_went_wrong", "Something went wrong")}!!</div>
          )}
        </>
      </QIFlashScreen>
      <ToastContainer />
    </>
  );
};
