import TrackPointsTableWrapper from "./TrackPointsTableWrapper";
import TrackPointHeader from "./TrackPointHeader";
import "./trackpoint.scss";
import { useGetVehicleRoutingQuery } from "../../services";
import { useEffect, useState } from "react";
import { QIErrorBoundary, QISpinner } from "../../../../components";
import moment from "moment";
import {
  extractKeyValuePairsTrackPoints,
  flattenObject,
  removeDuplicates,
  uniqueArray,
} from "../../utils";
import { NoData } from "../../../Shared";
import { useSelector } from "react-redux";

const TrackPoints = ({
  deviceId,
  selectedVehiclePlate,
  liveData,
  selectedRange,
  setResetCount,
  showConfig,
  setCheckData,
  configDivRef,
  setDeviceId = () => {},
  setMapPoint = () => {},
  setDockAlign = () => {},
}) => {
  const variableData = useSelector((state) => state.common.variableDatas);
  const [selectedId, setSelectedId] = useState();

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedColumnItems, setSelectedColumnItems] = useState([
    "source_time",
    "gps.position.address.name",
    "ignition",
    "velocity.speed",
    "mileage",
    "voltage.external",
    "voltage.internal",
  ]);
  const [perPage, setPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [columnData, setColumnData] = useState([]);

  //Pagination
  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  useEffect(() => {
    return () => {
      setResetCount((prev) => prev + 1);
      setPage(1);
    };
  }, [selectedRange, deviceId]);

  //Fetch Track Points Data
  const {
    data: response,
    isFetching,
    isSuccess,
    error,
  } = useGetVehicleRoutingQuery(
    {
      deviceId,
      start_time: moment(selectedRange.startDate).valueOf(),
      end_time: moment(selectedRange.endDate).valueOf(),
      per_page: perPage,
      page: page - 1,
      isTime: true,
      noFields: true,
    },
    { enabled: true }
  );

  useEffect(() => {
    if (response?.data?.length > 0) {
      setDeviceId(response?.data[0]?.source_id);
      setMapPoint(response?.data[0]?.gps?.position);
      setSelectedId(0);
    } else {
      setMapPoint(null);
    }
  }, [response?.data]);

  //Handle Row Selection
  const handleRowClick = (data) => {
    setDeviceId(data?.source_id);

    const gpsObj = {
      alt: data["gps.position.alt"],
      is_valid: data["gps.position.is_valid"],
      lat: data["gps.position.lat"],
      lng: data["gps.position.lng"],
    };
    setMapPoint(gpsObj);
  };

  //Check if Data is present, then display config button
  useEffect(() => {
    setCheckData(response?.data);
  }, [response, setCheckData]);

  useEffect(() => {
    //Iteratively Collect Data and Stacked Together
    if (response) {
      setData([]);
      response?.data.map((item, id) => {
        setData((prev) => [...prev, flattenObject(item)]);
      });
    }

    // If time gap greater than 24 hr stop live data flow
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    const selectedTime = moment(selectedRange.startDate).format("YYYY-MM-DD HH:mm:ss");
    const duration = moment.duration(moment(currentTime).diff(moment(selectedTime)));
    const hours = duration.asHours();

    //Real Time data flow
    /*
    - Real Time data will only flow if packet types equals "device_data"
    - Data will be coming if the time duration is last 24 hrs
    */
    /*  if (
      page === 0 &&
      hours <= 25 &&
      liveData[deviceId] &&
      liveData[deviceId]?.packet_type === "device_data"
    ) {
      setData((prevData) => [liveData[deviceId], ...prevData]);
    } */
  }, [response, selectedRange.startDate, deviceId]);

  useEffect(() => {
    setColumnData([]);
    extractKeyValuePairsTrackPoints(data)?.map((item, id) => {
      const [key] = Object.entries(item)[0];
      setColumnData((prev) => [
        ...prev,
        {
          name: key,
          id: id,
          enabled: true,
          key: key,
        },
      ]);
    });
  }, [data, variableData]);

  //Set Default columns
  useEffect(() => {
    setSelectedColumns([]);
    uniqueArray(columnData).map((item) => {
      if ([...new Set(selectedColumnItems)].includes(item?.key)) {
        setSelectedColumns((prev) => [...prev, item?.id]);
      }
    });
  }, [columnData, selectedColumnItems]);

  const message = {
    header: error?.data?.message,
  };

  return (
    <>
      {showConfig && (
        <TrackPointHeader
          imei={deviceId}
          selectedVehiclePlate={selectedVehiclePlate}
          setSelectedColumns={setSelectedColumns}
          selectedColumns={selectedColumns}
          columnData={columnData}
          configDivRef={configDivRef}
          setSelectedColumnItems={setSelectedColumnItems}
          variableData={variableData}
        />
      )}
      {error && <QIErrorBoundary message={message} />}
      {isFetching && (
        <div className="no-data">
          <QISpinner size="50px" />
        </div>
      )}
      {!isFetching && isSuccess && data?.length > 0 ? (
        <TrackPointsTableWrapper
          data={removeDuplicates(data)}
          selectedColumns={selectedColumns}
          isSuccess={isSuccess}
          pagination={{ ...pagination, count: response?.total_count }}
          variableData={variableData}
          selectedId={selectedId}
          tableHandlers={{
            rowHandler: handleRowClick,
          }}
        />
      ) : (
        isSuccess && !error && !isFetching && <NoData />
      )}
    </>
  );
};
export default TrackPoints;
