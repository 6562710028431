import { useEffect, useState } from "react";
import { useGetEventsQuery } from "../services";
import moment from "moment";
import EventList from "./EventList";
import EventsMap from "./EventMap";
import { useWebSocket } from "../../../hooks";
import EventSDetails from "./EventsDetails";
import "./events.scss";
import { ListCard, MapViewFlyOut } from "../Shared";
import { displayKeyName, removeDuplicates, valueFormatter } from "../utils";
import { header } from "./tableHeader";
import { QIDragAndDropDetails } from "../../../components";
import { extractKeyValuePairs, capitalizeAllFirstLetters } from "../utils";
import { Dnd, MinimisedTab, useDndContainer, WindowTab } from "../Dnd";
import { FMDPStorage } from "../../../shared/helper";
import Scrollbars from "react-custom-scrollbars";
import { useVariableDataQuery } from "../../../hooks/QIVariableDataQuery";
import { getLocalizedString } from "../../../shared/translation";
import { useGetPluginsQuery } from "../../ManagementPortal/services";
import { PluginContainer } from "../../Plugins";

export const EventsAlternateContainer = () => {
  const selectedPlateNumbers = FMDPStorage.get("selected-plate-numbers")?.length
    ? FMDPStorage.get("selected-plate-numbers")
    : [""];
  const { variableData } = useVariableDataQuery();

  const [deviceId, setDeviceId] = useState(null);
  const [highlight, setHighlight] = useState(null);
  const [mapPoint, setMapPoint] = useState(null);
  const [eventSearch, setEventSearch] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const [selectedRange, setSelectedRange] = useState({
    startDate: moment().subtract(24, "hours"),
    endDate: moment(),
  });
  const [resetCount, setResetCount] = useState(0);
  const [clientId, setClientId] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [allEventsData, setAllEventsData] = useState({});
  const [mapExpand, setMapExpand] = useState(false);
  const [showEventsDetails, setShowEventsDetails] = useState(false);
  const [dockAlign, setDockAlign] = useState("false");
  const [showTrack, setShowTrack] = useState(false);
  const [maximizeMinimize, setmaximizeMinimize] = useState(false);
  const [currentTab, setCurrentTab] = useState("Map");

  const { activeBoxes, addNewBox, removeBox, revertBox, isItemsDragging, handleDragChange } =
    useDndContainer();

  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  const { realTimedata } = useWebSocket(String(clientId), "event", "sub_event");

  const currentUser = FMDPStorage.get("current-user");

  const selectedAppId = FMDPStorage.get("selected-app-id");
  const getFilterList = (type) => {
    const currentAppRoles = currentUser?.client_app_roles;
    let hasAdminRole = false;
    currentAppRoles?.filter((clientData) => {
      if (clientData?.client_app_id === selectedAppId) {
        clientData?.roles?.filter((role) => {
          if (role?.name === "Admin" || role?.name === "Fleet Admin") {
            hasAdminRole = true;
          }
        });
      }
    });
    if (type === "checkAdmin") {
      if (hasAdminRole === false) {
        return false;
      } else {
        return true;
      }
    } else {
      if (hasAdminRole === false && type === "device") {
        return "";
      } else if (hasAdminRole === false && type === "plateNo") {
        return selectedPlateNumbers?.length > 0 ? selectedPlateNumbers : "";
      } else {
        return "";
      }
    }
  };

  //Event Search
  useEffect(() => {
    if (eventSearch) {
      setPage(1);
    }
  }, [eventSearch]);

  //Get Events data
  const {
    data: eventsData,
    error,
    isLoading,
  } = useGetEventsQuery(
    clientId && {
      isAdmin: getFilterList("checkAdmin"),
      startTime: moment(selectedRange.startDate).valueOf(),
      endTime: moment(selectedRange.endDate).valueOf(),
      // deviceIds: getFilterList("device"),
      plate_number: selectedPlateNumbers,
      search: true,
      clientId: clientId,
      //Page count starts from zero.
      page: page - 1,
      perPage: perPage,
      q: eventSearch.toUpperCase(),
    }
  );

  //Modify Data with Api values
  useEffect(() => {
    if (eventsData) {
      setAllEventsData((prev) => {
        return { ...prev, ...eventsData };
      });
    }
  }, [eventsData]);

  //Modify Data RealTime values
  useEffect(() => {
    if (realTimedata) {
      const newEventsData = Object?.values(realTimedata)?.filter((item) => item?.events);
      // Check if the user is on page 1 or the starting page and the time difference is less than or equal to 25 hours
      if (
        page === 1 &&
        moment(selectedRange.endDate).diff(selectedRange.startDate, "hours") <= 25
      ) {
        if (newEventsData.length > 0) {
          setAllEventsData((prev) => ({
            ...prev,
            events: newEventsData.concat(prev.events),
          }));
        }
      }
    }
  }, [realTimedata, page, selectedRange]);

  //Get Client ID
  useEffect(() => {
    const selectedClientId = JSON.parse(localStorage.getItem("selected-app-id"));
    const currentUser = JSON.parse(localStorage.getItem("current-user"))?.client_apps?.find(
      (item) => {
        return item.id === selectedClientId;
      }
    );

    setClientId(currentUser?.client?.id);
    //cleanup
    return () => setClientId(null);
  }, []);

  //clean up calendar value on compoonent unmount
  useEffect(() => {
    return setResetCount((prev) => prev + 1);
  }, []);

  useEffect(() => {
    setHighlight(null);
    setMapPoint(null); // Clean up mapPoint when details are hidden
    setShowEventsDetails(null); // Clean up Events Details
  }, [selectedRange]);

  const removeRowSelection = () => {
    setSelectedRowId(null);
    setDeviceId(null);
    setMapPoint(null);
    setShowEventsDetails(false);
    setDockAlign("cross");
  };

  const onRowClick = (data, id) => {
    if (selectedRowId === id) {
      // deselect if same row clicked
      removeRowSelection();
    } else {
      // select row
      setSelectedRowId(id);
      setDeviceId(data.source_id);
      setMapPoint(data.gps.position);
      setShowEventsDetails(data);
      setDockAlign("split-down");
    }
  };

  const keyValues = extractKeyValuePairs(showEventsDetails || []);

  const renderDetails = keyValues.map((pair) => {
    const key = Object.keys(pair)[0];
    let value = pair[key];

    return (
      <>
        {value !== null && !key.startsWith("_") && (
          <li className="details-block_other-data_item">
            <strong title={key} className="header truncate">
              {getLocalizedString(key, displayKeyName(variableData, key))}
            </strong>
            <span title={valueFormatter(key, value, variableData)} className="body truncate">
              {valueFormatter(key, value, variableData)}
            </span>
          </li>
        )}
      </>
    );
  });

  const getListDetails = () => {
    return (
      <section className="details-block_data-container">
        <ul className="details-block_data details-block_other-data">{renderDetails}</ul>
      </section>
    );
  };

  const openDeviceModal = () => {
    setShowDetails(false);
    addNewBox(deviceId);
  };

  const funSetActive = () => {
    setCurrentTab("Map");
  };

  const showdetailsTabs = (tab) => {
    setCurrentTab(tab);
  };

  const { data: dataPlugins = { data: [], total_count: 0 } } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  const addPlugins = dataPlugins.data.filter(
    (plugin) =>
      plugin.tracker_containers.map((s) => parseInt(s, 10)).includes(4) && plugin.is_enabled
  );
  const isPluginTab = addPlugins.some((plugin) => plugin.name === currentTab);
  const selectedPlugin = addPlugins.find((plugin) => plugin.name == currentTab);

  return (
    <>
      <div className="tracking-events">
        <div className="tracking-app-main-container">
          <MapViewFlyOut
            data={removeDuplicates(allEventsData?.events || [])}
            pagination={{ ...pagination, count: allEventsData?.total_count }}
            resetCount={resetCount}
            setResetCount={setResetCount}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            error={error}
            setDeviceId={setDeviceId}
            highlight={highlight}
            setHighlight={setHighlight}
            setMapPoint={setMapPoint}
            setMapExpand={setMapExpand}
            listType={"Events"}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            setShowDetails={setShowEventsDetails}
            showDetails={showEventsDetails}
            setDockAlign={setDockAlign}
            simpleSearch={eventSearch}
            setSimpleSearch={setEventSearch}
            selectedPage={"Events"}
            title={getLocalizedString("events", "Events")}
            header={header}
            isLoading={isLoading}
            search_by_message={getLocalizedString(
              "search_by_plate_no_event_name_type_subtype_status",
              "Search by Plate No, Event Name, Type, Sub Type, Status"
            )}
          >
            <Scrollbars>
              <ListCard
                data={allEventsData?.events || []}
                cardType="Events"
                onRowClick={onRowClick}
                highlight={highlight}
                setHighlight={setHighlight}
                selectedRowId={selectedRowId}
              />
            </Scrollbars>
          </MapViewFlyOut>

          <article className="tracking-app-main-container_block ${currentTab}">
            <nav className="map-view-tabs">
              <ul className="map-and-details_tabs">
                <li>
                  <span
                    className={`map-and-details_tabs_item ${currentTab === "Map" && "active"}`}
                    onClick={funSetActive}
                  >
                    {getLocalizedString("map", "Map")}
                  </span>
                </li>
                {addPlugins &&
                  addPlugins.map((plugin, index) => (
                    <li key={index}>
                      <span
                        className={`map-and-details_tabs_item ${
                          currentTab === plugin.name && "active"
                        }`}
                        onClick={() => showdetailsTabs(plugin.name)}
                      >
                        {getLocalizedString("plugins", plugin.name)}
                      </span>
                    </li>
                  ))}
              </ul>
            </nav>
            <article
              className={`map-and-details events-map ${dockAlign} ${
                maximizeMinimize ? "minimized-details" : ""
              }`}
            >
              {currentTab === "Map" ? (
                <EventsMap
                  dockAlign={dockAlign}
                  mapExpand={mapExpand}
                  deviceId={deviceId}
                  mapPoint={mapPoint}
                  selectedRowId={selectedRowId}
                  showEventsDetails={showEventsDetails}
                  setDockAlign={setDockAlign}
                  maximizeMinimize={maximizeMinimize}
                />
              ) : isPluginTab ? (
                <PluginContainer selectedPlugin={selectedPlugin} />
              ) : null}
              {showEventsDetails && (
                <article className={`details-block`}>
                  <QIDragAndDropDetails
                    setDetails={setShowEventsDetails}
                    deviceData={showEventsDetails || {}}
                    setPopup={openDeviceModal}
                    removeRowSelection={removeRowSelection}
                    setDockAlign={setDockAlign}
                    dockAlign={dockAlign}
                    deviceId={deviceId}
                    realTimedata={realTimedata}
                    setDeviceId={setDeviceId}
                    showTrack={showTrack}
                    setShowTrack={setShowTrack}
                    headerName={getLocalizedString("plate_number", "Plate Number")}
                    listDetails={getListDetails()}
                    selectedVehiclePlate={showEventsDetails?.plate_number}
                    maximizeMinimize={maximizeMinimize}
                    setmaximizeMinimize={setmaximizeMinimize}
                  />
                </article>
              )}
            </article>
          </article>
          <div>
            <MinimisedTab layoutName="trackingLayout" />
          </div>
        </div>
        <Dnd
          layoutName="trackingLayout"
          isItemsDragging={isItemsDragging}
          handleDragChange={handleDragChange}
          setDetails={setShowDetails}
        >
          {Object.keys(activeBoxes).map((key) => (
            <WindowTab
              key={key}
              id={key}
              label={key}
              {...activeBoxes[key].props}
              onClose={removeBox}
              onMinimise={revertBox}
              setShowDetails={setShowDetails}
              setDockAlign={setDockAlign}
              setDeviceId={setDeviceId}
              setShowTrack={setShowTrack}
              selectedVehiclePlate={showEventsDetails?.plate_number}
              setHighlight={setHighlight}
              removeLayerOnClose={false}
              removeRowSelection={removeRowSelection}
            >
              {getListDetails()}
            </WindowTab>
          ))}
        </Dnd>
      </div>
    </>
  );
};
