import sprite from "../../assets/icons.svg";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";

export const QITableCustom = ({
  headers,
  children,
  objectItem = {},
  handleSubmit,
  handleOnCancel,
  validator,
  initialValues = [],
  displayNameMap = {},
  mapColumnNames = [],
  validationRequired = true,
  error,
  readOnly,
  setSelectedVariableId,
  expandForm,
}) => {
  const [showNewInput, setShowNewInput] = useState(false);
  const [inputData, setInputData] = useState([]);
  const [showTableHeader, setShowTableHeader] = useState(true);

  // To set the initial values for edit form
  useEffect(() => {
    if (initialValues?.length > 0) {
      setInputData(initialValues);
    }
  }, [initialValues]);

  //Adding a row if there is no error present
  const addNewObject = async () => {
    if (validationRequired) {
      if (!validator(objectItem, inputData)) {
        setInputData((prevState) => {
          const updatedState = [...prevState, objectItem];
          setShowNewInput(false);
          handleSubmit(updatedState); // Call handleSubmit after updating the state
          return updatedState;
        });
      }
    } else {
      setInputData((prevState) => {
        const updatedState = [...prevState, objectItem];
        setShowNewInput(false);
        handleSubmit(updatedState); // Call handleSubmit after updating the state
        return updatedState;
      });
    }
  };

  const onCancel = () => {
    setShowNewInput(false);
    handleOnCancel();
  };

  const removeObject = (objectKey) => {
    const updatedData = inputData.filter((data) => {
      for (const key in objectKey) {
        if (data[key] !== objectKey[key]) {
          return true; // Keep the object if any property doesn't match
        }
      }
      return false; // Remove the object if all properties match
    });

    setInputData(updatedData);
    handleSubmit(updatedData);
  };

  const getMappedData = (objectKey, key) => {
    return mapColumnNames.includes(key) ? displayNameMap[key][objectKey[key]] : objectKey[key];
  };

  return (
    <section className="qi-table-custom">
      <header className="qi-list-view_header">
        {headers?.map((header, index) => (
          <span
            className={`qi-list-view_column ${header.className}`}
            key={index}
            title={header.label}
          >
            {header.label}
          </span>
        ))}
      </header>

      <Scrollbars autoHeight autoHeightMax="170px">
        <ul className={`qi-list-view_list custom-field_list ${expandForm ? "list_expand" : ""}`}>
          {inputData?.map((objectKey, index) => {
            return (
              <li className={`qi-list-view_list_item`} key={index}>
                {Object.keys(objectKey).map((key, index) => {
                  return (
                    objectKey[key] && (
                      <span
                        key={index}
                        className={`qi-list-view_column  ${key}`}
                        title={getMappedData(objectKey, key)}
                      >
                        {(objectKey[key] === true && (
                          <input type="checkbox" disabled={true} defaultChecked />
                        )) ||
                          (objectKey[key] === false && <input type="checkbox" disabled={true} />) ||
                          getMappedData(objectKey, key)}
                      </span>
                    )
                  );
                })}
                <span className="qi-list-view_column action">
                  <span
                    className="icon-wrapper icon-wrapper"
                    onClick={() => removeObject(objectKey)}
                  >
                    <svg className="icon icon-delete">
                      <use href={`${sprite}#delete`}></use>
                    </svg>
                  </span>
                </span>
              </li>
            );
          })}

          {showNewInput && (
            <li>
              <form className="qi-table-custom_form">
                {children}
                <div className="qi-list-view_column action add-admin-user_button-set">
                  <span className="icon-wrapper tick-icon" onClick={addNewObject}>
                    <svg className="icon icon-tick">
                      <use href={`${sprite}#tick`}></use>
                    </svg>
                  </span>
                  <span className="icon-wrapper cross-icon" onClick={onCancel}>
                    <svg className="icon icon-cross">
                      <use href={`${sprite}#cross`}></use>
                    </svg>
                  </span>
                </div>
              </form>
            </li>
          )}
        </ul>
      </Scrollbars>

      {!readOnly && error && (
        <div className="qi-input_error">
          <span className="icon-wrapper">
            <svg className="icon">
              <use href={`${sprite}#info`}></use>
            </svg>
          </span>
          <p className="qi-input_error_message">{error}</p>
        </div>
      )}

      {!showNewInput && (
        <span
          className="icon-wrapper add-new"
          title="Add New"
          onClick={() => {
            setShowNewInput(true);
            setSelectedVariableId(null);
          }}
        >
          <svg className="icon icon-add">
            <use href={`${sprite}#add`}></use>
          </svg>
        </span>
      )}
    </section>
  );
};
