import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { locationImg } from "../assets";
import {
  useGetEventConfigurationQuery,
  useGetGeofenceQuery,
} from "../../ManagementPortal/services";
import { getLocalizedString } from "../../../shared/translation";

const EventsMap = ({
  deviceId,
  mapPoint,
  mapExpand,
  dockAlign,
  selectedRowId,
  showEventsDetails,
  setDockAlign,
  maximizeMinimize,
}) => {
  const [map, setMap] = useState(null);
  const [geofenceData, setGeofenceData] = useState({});
  const countRef = useRef(0);
  const markerRef = useRef(null);
  const circleRef = useRef(null);
  const polygonRef = useRef(null);
  const mapContainerRef = useRef(null);

  //Get Geofence Data
  const { data: planData } = useGetEventConfigurationQuery(
    showEventsDetails?.events?.config_id && {
      id: showEventsDetails?.events?.config_id,
    }
  );

  const { data: geoResponse } = useGetGeofenceQuery(
    planData?.geofence_id && { id: planData?.geofence_id }
  );

  useEffect(() => {
    if (geoResponse?.json_data) {
      setGeofenceData(JSON.parse(geoResponse?.json_data));
    }
  }, [geoResponse]);

  useEffect(() => {
    // Initialize the map
    const mapInstance = L.map("device-map-container", {
      center: [0, 0],
      zoom: 1,
      minZoom: 2,
      maxZoom: 25,
    });

    // Add a tile layer (replace with your desired tile layer)
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "© OpenStreetMap contributors",
    }).addTo(mapInstance);

    setMap(mapInstance);

    // Clean up on component unmount
    return () => {
      mapInstance.remove();
    };
  }, []); // Run this effect only once on mount

  useEffect(() => {
    try {
      if (mapPoint && deviceId && (selectedRowId || selectedRowId === 0)) {
        // Remove previous layers
        if (markerRef.current) map.removeLayer(markerRef.current);
        if (circleRef.current) map.removeLayer(circleRef.current);
        if (polygonRef.current) map.removeLayer(polygonRef.current);

        // Draw Track Marker
        const trackMarker = L.marker(mapPoint, {
          icon: L.icon({
            iconUrl: locationImg,
            iconSize: [38, 38],
          }),
          name: `track_${countRef.current}`,
        }).addTo(map);
        markerRef.current = trackMarker;

        trackMarker.bindTooltip(() => {
          return `<div>${getLocalizedString("latitude", "Latitude")}: ${Number(
            mapPoint?.lat
          ).toFixed(5)}, ${getLocalizedString("longitude", "Longitude")}: ${Number(
            mapPoint?.lng
          ).toFixed(5)} </div>`;
        });

        // Draw Circle
        if (showEventsDetails?.events?.type === "geofence") {
          // Draw Circle only if the event type is "geofence"
          const coordinates = geofenceData?.coordinates[0]?.map((cord) => [cord[1], cord[0]]) || [];
          if (geofenceData?.type === "Circle") {
            const circle = L.circle(coordinates, {
              radius: 500,
              color: "green",
              weight: 4,
              fillOpacity: 0.4,
              name: `circle_${countRef.current}`,
            }).addTo(map);
            circleRef.current = circle;
          } else if (geofenceData?.type === "Polygon") {
            const polygon = L.polygon(coordinates, {
              color: "green",
              weight: 4,
              fillOpacity: 0.4,
              name: `polygon_${countRef.current}`,
            }).addTo(map);
            polygonRef.current = polygon;
          }
        }
        // Set the view to the exact point
        map.setView(mapPoint, 14); // Adjust the zoom level as needed

        countRef.current += 1;
      } else {
        // If mapPoint, deviceId, and selectedRowId are null, set map to default view and remove previous layers
        map.setView([0, 0], 1);
        if (markerRef.current) map.removeLayer(markerRef.current);
        if (circleRef.current) map.removeLayer(circleRef.current);
        if (polygonRef.current) map.removeLayer(polygonRef.current);
        // setDockAlign("cross");
      }
    } catch (e) {
      console.error("Error:", e);
    }
  }, [
    map,
    deviceId,
    mapPoint,
    selectedRowId,
    dockAlign,
    mapExpand,
    showEventsDetails,
    geofenceData,
    setDockAlign,
  ]);

  // Update map size and take up the whole space when dockAlign or mapExpand changes
  useEffect(() => {
    if (map && mapContainerRef.current) {
      map.invalidateSize();
    }
  }, [map, dockAlign, mapExpand, maximizeMinimize]);

  return <div className={`map-container`} id="device-map-container" ref={mapContainerRef}></div>;
};

export default EventsMap;
