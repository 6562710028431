import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { QITable, QISpinner, QIDropdown } from "../../../components";
import { SvgIcon } from "../../Shared";
import { MPDeleteButton, MPEditButton, MPUploadCsvButton } from "./Button";
import { setUpdatedBy, utcToLocalGlobal } from "../../../shared/utility";
import { CurrentUser } from "../../../shared/currentUser";
import { PaginationFooter, PaginationHeader } from "./Pagination";
import { AuditTrailModal } from ".";
import { getLocalizedString } from "../../../shared/translation";

export const MPSharedTable = ({
  resourceName,
  data,
  error,
  isLoading,
  isSuccess,
  headers,
  pagination,
  onEdit,
  onDelete,
  skipClientId = false,
  auditResource = "",
  auditKey = "name",
  className = "",
  additionalActions = [],
  skipCanEdit,
  skipCanDelete,
  onUploadCsv,
  labelName = getLocalizedString("actions", "Actions"),
  detailedView = false,
  detailedData,
  editDeleteCheck,
}) => {
  const navigate = useNavigate();
  const currentUser = new CurrentUser(resourceName);

  const [showAudit, setShowAudit] = useState(false);
  const [auditId, setAuditId] = useState("");

  const Actions = ({ data }) => {
    return (
      !data?.system_default && (
        <QIDropdown
          className="more-options-group"
          toggleComponent={
            !detailedView && (
              <SvgIcon
                wrapperClass="more-options"
                name="more-options"
                title={getLocalizedString("more_options", "More Options")}
              />
            )
          }
        >
          <>
            {additionalActions.map((action, index) => (
              <React.Fragment key={index}>{action.component(data)}</React.Fragment>
            ))}
            {(data.client_id || skipClientId) && !data[editDeleteCheck] && (
              <>
                {(currentUser.canUpdate || skipCanEdit) && onEdit && (
                  <MPEditButton className="me-2" onClick={() => onEdit(data)} />
                )}
                {(currentUser.canDelete || skipCanDelete) && onDelete && (
                  <MPDeleteButton onClick={() => onDelete(data)} />
                )}
                {onUploadCsv && <MPUploadCsvButton onClick={() => onUploadCsv(data)} />}
              </>
            )}
            {auditKey && (
              <SvgIcon
                wrapperClass="clickable"
                name="audit-log"
                onClick={() => {
                  setShowAudit(true);
                  setAuditId(data.id);
                }}
                title={getLocalizedString("audit_log", "Audit log")}
              />
            )}
          </>
        </QIDropdown>
      )
    );
  };

  // update created at
  let updatedHeaders = headers.map((header) => {
    if (header.key === "created_at" || header.utc) {
      return {
        ...header,
        label: header.label,
        nestedValue: true,
        getNestedValue: ({ created_at }) => utcToLocalGlobal(created_at),
      };
    }

    if (header.key === "user_email") {
      return {
        ...header,
        label: header.label,
        nestedValue: true,
        getNestedValue: ({ user_email }) => setUpdatedBy(user_email),
      };
    }

    return header;
  });

  if (onEdit || onDelete || additionalActions.length > 0) {
    updatedHeaders.push({
      label: labelName,
      type: "component",
      component: Actions,
      className: "action",
    });
  }

  return (
    <>
      {isLoading && (
        <div className="no-data">
          <QISpinner size="50px" />
          <p>{getLocalizedString("loading", "Loading")}...</p>
        </div>
      )}

      {!isLoading && error && (
        <h2>
          {getLocalizedString(
            "something_wrong_try_again",
            "Something went wrong. Please try again."
          )}
        </h2>
      )}

      {!isLoading && isSuccess && data.length > 0 ? (
        <QITable
          detailedView={detailedView}
          detailedData={detailedData}
          headers={updatedHeaders}
          data={data}
          className={className}
        />
      ) : (
        <>
          {!isLoading && (
            <div className="no-data">
              <SvgIcon wrapperClass="no-data-icon" svgClass="icon-noData" name="app-icon" />{" "}
              <p>{getLocalizedString("no_data_available", "No Data Available")}</p>
            </div>
          )}
        </>
      )}

      {pagination && (
        <aside className="qi-pagination-wrapper">
          <PaginationHeader pagination={pagination} />
          {isSuccess && pagination && <PaginationFooter pagination={pagination} />}
        </aside>
      )}

      {showAudit && (
        <AuditTrailModal
          resourceType={auditResource}
          onClose={() => setShowAudit(false)}
          auditId={auditId}
          data={data || []}
        />
      )}
    </>
  );
};
