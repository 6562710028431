import { QIExpand, QICustomDateRangePicker, QITable, QISpinner } from "../../../components";
import { PaginationFooter, NoData } from "../../Shared";
import { FMDPStorage } from "../../../shared/helper";

export const MapViewFlyOut = ({
  data,
  resetCount,
  selectedRange,
  setSelectedRange,
  listType,
  setResetCount,
  setMapExpand,
  simpleSearch,
  setSimpleSearch,
  isSuccess = true,
  isLoading,
  selectedPage,
  pagination,
  children,
  search_by_message = "",
  title,
  getHeaderList = () => {},
}) => {
  const isAlternativeUI = FMDPStorage?.get("alternative-layout");

  return (
    <>
      <QIExpand
        simpleSearch={simpleSearch}
        setSimpleSearch={setSimpleSearch}
        simpleSearchPlaceholder={search_by_message}
        setMapExpand={setMapExpand}
        title={title}
        alternativeUI={isAlternativeUI}
      >
        {selectedPage !== "Vehicles" && (
          <QICustomDateRangePicker
            resetCount={resetCount}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            setResetCount={setResetCount}
          />
        )}
        {isLoading && (
          <div className="no-data">
            <QISpinner size="50px" />
          </div>
        )}
        {data?.length > 0 && getHeaderList()}
        {data?.length > 0 ? (
          <ul className={`${listType.toLowerCase()}-list card-view-list asset-list`}>{children}</ul>
        ) : (
          !isLoading && <NoData />
        )}
        {data?.length > 0 && isSuccess && pagination && (
          <PaginationFooter pagination={pagination} />
        )}
      </QIExpand>
    </>
  );
};
