import { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";

export const useWebSocket = (client_id, group = "all", method = "sub_all") => {
  const [connection, setConnection] = useState(null);
  const [realTimedata, setRealTimeData] = useState({});
  const [clientId, setClientId] = useState(null);

  //Socket Connection
  useEffect(() => {
    const newSocketConnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_PUBLISHER_URL)
      .build();
    setConnection(newSocketConnection);
    setClientId(client_id);
  }, [client_id]);

  //Setting Real Time Data
  useEffect(() => {
    if (connection) {
      async function start() {
        try {
          if (clientId.length) {
            await connection.start();
            await connection.invoke("UnSubscribeByClientId", clientId, group);
            await connection.invoke("SubscribeByClientId", clientId, group);
            connection.serverTimeoutInMilliseconds = 5 * 60 * 1000;
            connection.on(method, function (allData) {
              const { Data } = JSON.parse(allData);

              setRealTimeData({ [Data?.source_id]: Data });
            });
          }
        } catch (e) {
          // console.log(e);
        }
      }
      start();
      //Restart Connection if State is not Connected
      connection["_connectionState"] !== "Connected" &&
        connection.onclose(async () => {
          await start();
        });
    }
    return () => {
      //Cleanup after url change
      try {
        connection.stop();
        //connection && connection.invoke("UnSubscribe", clientId, group);
      } catch (e) {}
    };
  }, [connection, clientId]);
  //End

  return { realTimedata };
};
