import EventList from "../../Events/EventList";
import { useEffect, useState } from "react";
import { useGetEventsQuery } from "../../services";
import { QIErrorBoundary } from "../../../../components";
import moment from "moment";
import "./style.scss";
import "./style.scss";
import { getLocalizedString } from "../../../../shared/translation";

export const EventsContainer = ({
  resetCount,
  setResetCount,
  deviceId,
  selectedRange,
  setSelectedRange,
  clientId,
  selectedVehiclePlate,
  type,
  setDeviceId = () => {},
  setMapPoint = () => {},
  setDockAlign = () => {},
}) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [eventSearch, setEventSearch] = useState("");
  const [selectedId, setSelectedId] = useState();

  //Pagination
  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  //Get Events data
  const {
    data: eventsData,
    error,
    isSuccess,
    isFetching,
  } = useGetEventsQuery(
    deviceId && {
      startTime: moment(selectedRange.startDate).valueOf(),
      endTime: moment(selectedRange.endDate).valueOf(),
      deviceIds: deviceId,
      clientId: clientId,
      plate_number: [selectedVehiclePlate],
      source_id: [deviceId],
      type: type,
      search: false,
      //Page count starts from zero.
      page: page - 1,
      perPage: perPage,
      q: eventSearch.toUpperCase(),
    }
  );

  useEffect(() => {
    if (eventsData?.events?.length > 0) {
      setDeviceId(eventsData?.events[0]?.source_id);
      setMapPoint(eventsData?.events[0]?.gps?.position);
      setSelectedId(0);
    } else {
      setMapPoint(null);
    }
  }, [eventsData?.events]);

  const message = {
    header: getLocalizedString("no_device_selected", "No Device Selected"),
    content: getLocalizedString("please_select_a_device", "Please Select a Device"),
  };

  return deviceId ? (
    <section className="vehicle-events">
      <EventList
        data={eventsData || []}
        resetCount={resetCount}
        isSuccess={isSuccess}
        isFetching={isFetching}
        setResetCount={setResetCount}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        error={error}
        listType={"Events"}
        page={page}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        setDeviceId={setDeviceId}
        setMapPoint={setMapPoint}
        setDockAlign={setDockAlign}
        selectedId={selectedId}
      />
    </section>
  ) : (
    <QIErrorBoundary message={message} />
  );
};
