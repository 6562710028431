import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import {
  useGetDMansQuery,
  useGetDModelsQuery,
  useGetAppsQuery,
  useCreateDeviceMutation,
  useGetDeviceQuery,
  useUpdateDeviceMutation,
  useGetBackendDropdownQuery,
  useGetDeviceGroupsQuery,
  useGetNewDeviceQuery,
  useGetVariableMappingsQuery,
  useGetGeolocationsQuery,
  useGetDefaultEventConfigurationsQuery,
  useGetSimCardsQuery,
  useGetDModelQuery,
  useGetClientsQuery,
} from "../services";
import Countrycode from "../../../shared/countryCode.json";
import { deviceFormSchema, resellerDeviceFormSchema } from "../validations";
import { mergeObjects } from "../../../shared/helper";
import { FormContainer } from "../Shared";
import { useForm, useDropDownSearch, useStaticDropDownSearch } from "../../../hooks";
import { SvgIcon } from "../../Shared";
import { getLocalizedString } from "../../../shared/translation";
import { FMDPStorage } from "../../../shared/helper";

/**
* This is an add/edit form of Devices
*
* @param {number} [idToEdit:"null"]- A number for the id of the selected edit form and null for the add form
* @param {func} [closeForm] - A function to close the add/edit form

* @example
* <DeviceForm
    idToEdit=45
    closeForm={() => setShowForm(false)}
  />
 */

export const DeviceForm = ({ idToEdit = null, onClose }) => {
  const dropdownParams = { per_page: 1000, order_by: "name" };

  //To maintain the values of all the fields in the form
  const [initialValues, setInitialValues] = useState({
    name: "",
    device_manufacturer_id: "",
    device_id: "",
    device_model_id: "",
    simcard_no: "",
    firmware_version: "",
    credentials: { username: "", password: "" },
    enabled: true,
    //app_ids: [],
    backend_ids: [],
    device_group_ids: [],
    variable_mapping_id: "",
    backend_ssl_certificate: null,
    remove_backend_ssl_certificate: false,
    backend_credentials: { username: "", password: "" },
    geolocation_plan_id: "",
    trip_event_configuration_id: "",
    primary_sim_card_id: "",
    // secondary_sim_card_id: "",
    client_id: "",
  });

  const [resetPasswordWarning, setResetPasswordWarning] = useState(false);
  const [resetBackendPasswordWarning, setResetBackendPasswordWarning] = useState(false);
  //If the value of manufacturer or model is changed then the state is made true, otherwise it is false
  const [manufacturerModelChanged, setManufacturerModelChanged] = useState(false);
  const [defaultTripConfigurationId, setDefaultTripConfigurationId] = useState(null);
  const [defaultTrip, setDefaultTrip] = useState(false);
  const [allClients, setAllClients] = useState([]);

  const isReseller = FMDPStorage?.get("reseller");
  const selectedClientId = FMDPStorage?.get("selected-client-id");
  const selectedClientName = FMDPStorage?.get("selected-client");

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: isReseller ? resellerDeviceFormSchema : deviceFormSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create, update } = useForm({
    createMutation: useCreateDeviceMutation,
    updateMutation: useUpdateDeviceMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const { data: apps = [], isSuccess: isAppsSuccess } = useGetAppsQuery(dropdownParams);

  // Get the device manufacturers list using Dropdown Search
  const { data: dMans, setSearchKey: setDManSearch } = useDropDownSearch({
    useData: useGetDMansQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.device_manufacturer_id,
  });

  // Get the geolocation plan list using Dropdown Search
  const {
    data: geolocation_plans,
    setSearchKey: setGeolocationPlanSearchKey,
    isSuccess: isGeolocationPlansSuccess,
  } = useDropDownSearch({
    useData: useGetGeolocationsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.geolocation_plan_id,
  });

  // Get the variable mapping list using Dropdown Search
  const {
    data: variableMappings,
    setSearchKey: setVariableMappingSearch,
    isSuccess: isVariableMappingSuccess,
  } = useDropDownSearch(
    {
      useData: useGetVariableMappingsQuery,
      additionalParams: { device_model_id: formik.values.device_model_id },
      simpleSearchKey: "name_cont",
      selectedIds: formik.values.variable_mapping_id,
    },
    {
      skip: !formik.values.variable_mapping_id,
    }
  );

  // Get the device models list using Dropdown Search
  const { data: dModels, setSearchKey: setSearchDModel } = useDropDownSearch(
    {
      useData: useGetDModelsQuery,
      simpleSearchKey: "name_cont",
      additionalParams: { device_manufacturer_id: formik.values.device_manufacturer_id },
      selectedIds: formik.values.device_model_id,
    },
    {
      skip: !formik.values.device_manufacturer_id,
    }
  );

  // Get the backends list using Dropdown Search
  const {
    data: backends,
    setSearchKey: setBackendsSearchKey,
    isSuccess: isBackendSuccess,
  } = useDropDownSearch({
    useData: useGetBackendDropdownQuery,
    simpleSearchKey: "name_or_backend_type_cont",
    selectedIds: formik.values.backend_ids,
    additionalParams: { device_id: idToEdit == null ? "" : idToEdit },
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: isClientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.client_id,
    additionalParamsToHook: { skip: !isReseller },
  });

  // Get the simcards list using Dropdown Search
  const {
    data: simCards,
    setSearchKey: setSimCardsSearchKey,
    isSuccess: isSimCardSuccess,
  } = useDropDownSearch({
    useData: useGetSimCardsQuery,
    simpleSearchKey: "display_name_or_phone_no_or_imsi_cont",
    selectedIds: formik.values.primary_sim_card_id,
  });

  // Get the country code list using Dropdown Search for static data
  const { data: countryCode, setSearchKey: setCountryCodeSearchKey } = useStaticDropDownSearch({
    dataSet: Countrycode,
    selectedIds: formik.values.country_code,
  });

  const queryParamsForTrips = {
    q: JSON.stringify({ event_type_id_eq: 2 }),
  };

  //Get trip event configurations list
  const { data: tripEventsList } = useGetDefaultEventConfigurationsQuery({
    device_model_id: formik.values.device_model_id,
    q: encodeURIComponent(queryParamsForTrips.q),
    per_page: 600,
  });

  //To set the default trips
  useEffect(() => {
    if (
      (tripEventsList && !isReseller) ||
      (tripEventsList && isReseller && selectedClientId == formik?.values?.client_id)
    ) {
      if (formik.values.device_model_id && !formik.values.trip_event_configuration_id) {
        tripEventsList?.data.map((trip) => {
          if (trip?.is_default) {
            formik.setFieldValue("trip_event_configuration_id", trip.id);
            setDefaultTripConfigurationId(trip.id);
          }
        });
      }
    }
  }, [tripEventsList]);

  //To set the default variable mapping
  useEffect(() => {
    if (formik.values.device_model_id && !formik.values.variable_mapping_id) {
      variableMappings?.data.map((variableMapping) => {
        if (variableMapping.default && manufacturerModelChanged) {
          formik.setFieldValue("variable_mapping_id", variableMapping.id);
        }
      });
    }
  }, [variableMappings]);

  //Add own client in the client list for reseller client
  useEffect(() => {
    if (isClientsSuccess) {
      setAllClients([...clients?.data, { id: selectedClientId, name: selectedClientName }]);
    }
  }, [clients]);

  const { data: deviceGroups, isSuccess: isDeviceGroupsSuccess } =
    useGetDeviceGroupsQuery(dropdownParams);

  const { data: newdata } = useGetNewDeviceQuery({}, { skip: idToEdit });
  const { data } = useGetDeviceQuery({ id: idToEdit }, { skip: !idToEdit });

  const [touchedManufacturer, setTouchedManufacturer] = useState(false);

  // Set form data in case of edit

  useEffect(() => {
    if (idToEdit && data) {
      const updatedFormData = mergeObjects(formik.values, data);
      setInitialValues(updatedFormData);
      if (data?.primary_sim_card_id) {
        simCards?.data?.map((simCard) => {
          if (simCard?.id == data?.primary_sim_card_id) {
            let contactDetails = {
              country_code: simCard?.dial_code,
              simcard_phone_no: simCard?.phone_no,
            };
            const updatedFormDataWithContactDetails = mergeObjects(updatedFormData, contactDetails);
            setInitialValues(updatedFormDataWithContactDetails);
          }
        });
      }
    }
  }, [data]);

  // Check for default trip configuration for edit form
  useEffect(() => {
    if (idToEdit && data && data?.trip_event_configuration_id === null) {
      setDefaultTrip(true);
    }
  }, [data]);

  // Set form data in case of new device
  useEffect(() => {
    if (!idToEdit && newdata && !isReseller) {
      setInitialValues(mergeObjects(initialValues, newdata));
    }
  }, [newdata]);

  // Get the default trip configuration id
  const DefaultTripConfiguration = ({ modelId, setDefaultTripConfigurationId }) => {
    const { data: deviceModelData } = useGetDModelQuery({ id: modelId });
    useEffect(() => {
      if (deviceModelData) {
        setDefaultTripConfigurationId(deviceModelData?.trip_event_configuration_id);
      }
    }, [deviceModelData]);
    return <></>;
  };

  const getFileNameFromUrl = (url) => {
    let paths = url?.split("/") || [];

    if (paths.length >= 1) {
      return paths[paths.length - 1];
    }
    return "";
  };

  const handleFormSubmit = (formData) => {
    let finalFormData = {};
    if (isReseller) {
      finalFormData = {
        ...formData,
      };
    } else {
      finalFormData = {
        ...formData,
        client_id: selectedClientId,
      };
    }
    delete finalFormData.manufacturer;
    let fObj = new FormData();

    Object.keys(finalFormData).forEach((key) => {
      if (key !== "backend_ssl_certificate_url" && finalFormData[key] != null) {
        if (key === "backend_ssl_certificate") {
          fObj.append(key, finalFormData[key]);
        } else {
          if (typeof finalFormData[key] === "object") {
            fObj.append(key, JSON.stringify(finalFormData[key]));
          } else {
            fObj.append(key, finalFormData[key]);
          }
        }
      }
    });

    if (idToEdit) {
      update({ id: idToEdit, formData: fObj });
    } else {
      create({ formData: fObj });
    }
  };

  const clientName = localStorage.getItem("selected-client") || "Client";

  const handleBackendSelection = ({ client_id, forSelect, enabled, name, default: isDefault }) => {
    if (client_id == null) {
      return `${name}-${
        enabled ? "Enabled" : "Disabled"
      } (Super Admin added backends can't be removed or selected)`;
    } else if (client_id != null) {
      return `${name}-${enabled ? "Enabled" : "Disabled"}-${
        isDefault ? "Default" : "Not Default"
      } (${clientName} Admin)`;
    }
    if (forSelect && !enabled) {
      return `${name}-Disabled backend can't be selected`;
    }

    return "";
  };

  const isBackendSelectable = ({ client_id, enabled }) => {
    if (!client_id) {
      return false;
    }
    return true;
  };

  const isBackendDeletable = ({ client_id, enabled, default: isDefault }) => {
    if (!client_id || !enabled || isDefault) {
      return false;
    }
    return true;
  };

  const getClassName = ({ client_id, forSelect, enabled, default: isDefault }) => {
    if (client_id == null) {
      return `by-superadmin-${enabled ? "enabled" : "disabled"}`;
    } else {
      return `by-client-${enabled ? "enabled" : "disabled"}-${
        isDefault ? "default" : "not-default"
      }`;
    }
  };

  // To get default variable mapping from the variable mapping list api response
  const getDefaultVariableMapping = () => {
    if (!formik.values.device_model_id) {
      return "";
    } else {
      variableMappings?.data.map((variableMapping) => {
        if (variableMapping.default) {
          return variableMapping.id;
        }
      });
    }
  };

  // To get default trip from list api response
  const getDefaultTrip = () => {
    if (!formik.values.device_model_id) {
      return "";
    } else {
      tripEventsList?.data.map((trip) => {
        if (trip.is_default) {
          return trip.id;
        }
      });
    }
  };

  useEffect(() => {
    if (touchedManufacturer) {
      formik.setFieldValue("device_model_id", "");
    }
  }, [formik.values.device_manufacturer_id]);

  // Secondary SIM Card option will be available in further release
  // Set secondary sim card blank if primary sim card is made blank
  // useEffect(() => {
  //   if (formik?.values?.primary_sim_card_id == "") {
  //     formik?.setFieldValue("secondary_sim_card_id", "");
  //   }
  // }, [formik?.values?.primary_sim_card_id]);

  const handleDeviceManufacturerChange = (value) => {
    formik.setFieldValue("device_manufacturer_id", value);
    formik.setFieldValue("variable_mapping_id", "");
    formik.setFieldValue("trip_event_configuration_id", "");
    setTouchedManufacturer(true);
  };

  const handleDeviceModelChange = (value) => {
    formik.setFieldValue("device_model_id", Number(value));
    formik.setFieldValue("variable_mapping_id", "");
    formik.setFieldValue("trip_event_configuration_id", "");
    setManufacturerModelChanged(true);
  };

  //To handle the country code when primary simcard is updated
  const handlePrimarySimCard = (value) => {
    formik.setFieldValue("primary_sim_card_id", value);
    setSimCardsSearchKey("");
    if (value) {
      simCards?.data?.map((simCard) => {
        if (simCard?.id == value) {
          formik.setFieldValue("country_code", simCard?.dial_code);
          formik.setFieldValue("simcard_phone_no", simCard?.phone_no);
        }
      });
    } else {
      formik.setFieldValue("country_code", "");
      formik.setFieldValue("simcard_phone_no", "");
    }
  };

  // Get the trip value
  const getTripValue = (tripVal) => {
    if (tripVal) {
      return tripVal;
    } else if (idToEdit && tripVal === "" && defaultTripConfigurationId) {
      return defaultTripConfigurationId;
    } else {
      return getDefaultTrip();
    }
  };

  // To handle client change for reseller client
  const handleClientChange = (value) => {
    formik.setFieldValue("client_id", value);
    setClientSearch("");
    if (isReseller) {
      if (value == selectedClientId) {
        formik.setFieldValue("backend_ids", newdata?.backend_ids);
        formik.setFieldValue("geolocation_plan_id", newdata?.geolocation_plan_id);
      } else {
        formik.setFieldValue("backend_ids", []);
        formik.setFieldValue("geolocation_plan_id", "");
        formik.setFieldValue("device_group_ids", []);
        formik.setFieldValue("credentials.password", "");
        formik.setFieldValue("country_code", "");
        formik.setFieldValue("primary_sim_card_id", "");
        formik.setFieldValue("trip_event_configuration_id", "");
        formik.setFieldValue("variable_mapping_id", "");
      }
    }
  };

  return (
    <>
      {isReseller ? (
        <FormContainer
          resourceName={getLocalizedString("device", "Device")}
          closeForm={() => onClose(false)}
          handleFormSubmit={formik.handleSubmit}
          idToEdit={idToEdit}
        >
          <Components.QICustomSelect
            label={getLocalizedString("client", "Client")}
            value={formik.values.client_id}
            onChange={(value) => handleClientChange(value)}
            onSearch={setClientSearch}
            error={formik.touched.client_id && formik.errors.client_id}
          >
            {isClientsSuccess &&
              allClients?.map((client) => (
                <li key={client.id} value={client.id}>
                  {client.name}{" "}
                  <small className="default-model">{`${
                    selectedClientId == client.id ? "(Self/Reseller)" : ""
                  }`}</small>
                </li>
              ))}
          </Components.QICustomSelect>

          <Components.QICustomSelect
            label={getLocalizedString("manufacturer", "Manufacturer")}
            onChange={(value) => {
              handleDeviceManufacturerChange(value);
              setDManSearch("");
              setManufacturerModelChanged(true);
            }}
            value={formik.values.device_manufacturer_id}
            onSearch={setDManSearch}
            error={formik.touched.device_manufacturer_id && formik.errors.device_manufacturer_id}
            labelClassName="manufacturer"
            disabled={formik?.values?.client_id ? false : true}
          >
            {dMans?.data?.map((manufacturer, index) => (
              <li value={manufacturer.id} key={index}>
                {manufacturer.name}
              </li>
            ))}
          </Components.QICustomSelect>

          <Components.QICustomSelect
            label={getLocalizedString("model", "Model")}
            onChange={(value) => handleDeviceModelChange(value)}
            disabled={!formik.values.device_manufacturer_id}
            value={formik.values.device_model_id}
            onSearch={setSearchDModel}
            error={formik.touched.device_model_id && formik.errors.device_model_id}
            labelClassName="model"
          >
            {dModels?.data.length == 0 ? (
              <li className="no-item-availlable">No Item Available.</li>
            ) : (
              dModels?.data?.map((model, index) => (
                <li value={model.id} key={index}>
                  {model.name}
                </li>
              ))
            )}
          </Components.QICustomSelect>

          {selectedClientId == formik?.values?.client_id && (
            <Components.QICustomSelect
              label={getLocalizedString("variable_mapping", "Variable Mapping")}
              value={
                formik.values.variable_mapping_id
                  ? formik.values.variable_mapping_id
                  : getDefaultVariableMapping()
              }
              onChange={(value) => {
                formik.setFieldValue("variable_mapping_id", value);
                setVariableMappingSearch("");
              }}
              disabled={!formik.values.device_model_id}
              onSearch={setVariableMappingSearch}
              labelClassName="variable-mapping"
            >
              <li value="">{getLocalizedString("select", "Select")}</li>
              {variableMappings?.data.map((variableMapping) => (
                <li value={variableMapping.id} key={variableMapping.id}>
                  {variableMapping.name}
                </li>
              ))}
            </Components.QICustomSelect>
          )}

          {selectedClientId == formik?.values?.client_id && (
            <Components.QICustomSelect
              label={getLocalizedString("trip_configuration", "Trip Configuration")}
              value={getTripValue(formik.values.trip_event_configuration_id)}
              onChange={(value) => {
                formik.setFieldValue("trip_event_configuration_id", value);
              }}
              disabled={!formik.values.device_model_id}
              error={
                formik.touched.trip_event_configuration_id &&
                formik.errors.trip_event_configuration_id
              }
              labelClassName="trip-configuration"
            >
              <li value="">{getLocalizedString("select", "Select")}</li>
              {tripEventsList?.data?.map((trip) => (
                <li value={trip.id} key={trip.id}>
                  {trip.name}
                  <small className="default-model">{`${
                    defaultTripConfigurationId == trip.id ? " (default model)" : ""
                  }`}</small>
                </li>
              ))}
            </Components.QICustomSelect>
          )}

          <Components.QIInput
            label={getLocalizedString("name", "Name")}
            {...formik.getFieldProps("name")}
            error={formik.touched.name && formik.errors.name}
            disabled={formik?.values?.client_id ? false : true}
          />
          <Components.QIInput
            label="ID/IMEI"
            {...formik.getFieldProps("device_id")}
            error={formik.touched.device_id && formik.errors.device_id}
            disabled={formik?.values?.client_id ? false : true}
          />
          {selectedClientId == formik?.values?.client_id && (
            <Components.QICustomSelect
              label={getLocalizedString("primary_sim_card", "Primary SIM Card")}
              value={formik.values.primary_sim_card_id}
              onChange={(value) => handlePrimarySimCard(value)}
              error={formik.touched.primary_sim_card_id && formik.errors.primary_sim_card_id}
              onSearch={(value) => setSimCardsSearchKey(value)}
              labelClassName="primary-sim-card"
            >
              <li value="">{getLocalizedString("select", "Select")}</li>
              {(simCards?.data || []).map((simCard) => (
                <li value={simCard.id} key={simCard.id} title={simCard?.imsi}>
                  {simCard?.imsi}
                </li>
              ))}
            </Components.QICustomSelect>
          )}

          {selectedClientId == formik?.values?.client_id && (
            <div className="flex justify-between country-code-and-number">
              <Components.QICustomSelect
                label="Country Code"
                placeholder="Country code"
                disabled={true}
                className="country-code-and-number_item1"
                {...formik.getFieldProps("country_code")}
                onChange={(value) => formik.setFieldValue("country_code", value)}
                onSearch={setCountryCodeSearchKey}
                error={formik.touched.country_code && formik.errors.country_code}
                labelClassName="country-code"
              >
                <li value="">Select</li>
                {countryCode.map((code, index) => (
                  <li value={code.dial_code} title={`${code.dial_code} (${code.name})`} key={index}>
                    {code.dial_code} ({code.name})
                  </li>
                ))}
              </Components.QICustomSelect>

              <Components.QIInput
                label="Phone No."
                placeholder="Phone No"
                disabled={true}
                className="country-code-and-number_item2"
                customLabelClass="phone-number"
                customWrapperClass="phone-number-wrapper"
                {...formik.getFieldProps("simcard_phone_no")}
                error={formik.touched.simcard_phone_no && formik.errors.simcard_phone_no}
              />
            </div>
          )}

          {selectedClientId == formik?.values?.client_id && (
            <div className="qi-input credentials">
              <label className="qi-input_label">
                {getLocalizedString("sms_credentials", "SMS Credentials")}
              </label>
              <div className="qi-input_wrapper">
                <Components.QIInput
                  label={getLocalizedString("username", "Username")}
                  {...formik.getFieldProps("credentials.username")}
                  error={
                    formik.touched.credentials?.username && formik.errors.credentials?.username
                  }
                />
                <Components.QIInput
                  label={getLocalizedString("password", "Password")}
                  {...formik.getFieldProps("credentials.password")}
                  onChangeEdit={(value) => {
                    formik.setFieldValue("credentials.password", value);
                  }}
                  type="password"
                  edit={idToEdit}
                  disabled={idToEdit}
                  error={
                    formik.touched.credentials?.password && formik.errors.credentials?.password
                  }
                  handleWarningMessage={(value) => setResetPasswordWarning(value)}
                  warningMessage={`${resetPasswordWarning ? "Please tick to reset password." : ""}`}
                />
              </div>
            </div>
          )}
          <Components.QISwitch
            label={getLocalizedString("enabled", "Enabled")}
            value={formik.values.enabled}
            onChange={() => formik.setFieldValue("enabled", !formik.values.enabled)}
            disabled={formik?.values?.client_id ? false : true}
            readOnly={formik?.values?.client_id ? false : true}
          />

          {selectedClientId == formik?.values?.client_id && (
            <Components.QIMultiSelectDropDown
              label={getLocalizedString("groups", "Groups")}
              selected={formik.values.device_group_ids}
              onChange={(value) => formik.setFieldValue("device_group_ids", value)}
              data={deviceGroups?.data || []}
              className="narrow-multiselect-dropdown"
            />
          )}
          {selectedClientId == formik?.values?.client_id && (
            <Components.QIMultiSelectDropDown
              label={getLocalizedString("backends", "Backends")}
              className="backends-owner"
              data={backends?.data || []}
              labelKey={(data) => `${data.name}`}
              selected={formik.values.backend_ids}
              onChange={(updatedList) => {
                formik.setFieldValue("backend_ids", updatedList);
                setBackendsSearchKey("");
              }}
              disabled={!isBackendSuccess}
              onSearch={(value) => setBackendsSearchKey(value)}
              actionError={handleBackendSelection}
              getRowClass={getClassName}
              isSelectable={isBackendSelectable}
              isDeletable={isBackendDeletable}
            />
          )}
          {selectedClientId == formik?.values?.client_id && (
            <div className="qi-input credentials">
              <label className="qi-input_label">
                {getLocalizedString("backend_credentials", "Backend Credentials")}
              </label>
              <div className="qi-input_wrapper">
                <Components.QIInput
                  label={getLocalizedString("username", "Username")}
                  {...formik.getFieldProps("backend_credentials.username")}
                  error={
                    formik.touched.backend_credentials?.username &&
                    formik.errors.backend_credentials?.username
                  }
                />
                <Components.QIInput
                  label={getLocalizedString("password", "Password")}
                  {...formik.getFieldProps("backend_credentials.password")}
                  onChangeEdit={(value) => {
                    formik.setFieldValue("backend_credentials.password", value);
                  }}
                  type="password"
                  edit={idToEdit}
                  disabled={idToEdit}
                  error={
                    formik.touched.backend_credentials?.password &&
                    formik.errors.backend_credentials?.password
                  }
                  handleWarningMessage={(value) => setResetBackendPasswordWarning(value)}
                  warningMessage={`${
                    resetBackendPasswordWarning ? "Please tick to reset password." : ""
                  }`}
                />
              </div>
            </div>
          )}
          {selectedClientId == formik?.values?.client_id && (
            <Components.QICustomSelect
              label={getLocalizedString("geolocation_plan", "Geolocation Plan")}
              value={formik.values.geolocation_plan_id}
              onChange={(value) => {
                formik.setFieldValue("geolocation_plan_id", value);
                setGeolocationPlanSearchKey("");
              }}
              error={formik.touched.geolocation_plan_id && formik.errors.geolocation_plan_id}
              onSearch={(value) => setGeolocationPlanSearchKey(value)}
              labelClassName="geolocation-plan"
            >
              <li value="">{getLocalizedString("select", "Select")}</li>
              {(geolocation_plans?.data || []).map((g) => (
                <li value={g.id} key={g.id}>
                  {g.name}
                </li>
              ))}
            </Components.QICustomSelect>
          )}
          <Components.QIInput
            label={getLocalizedString("firmware_version", "Firmware Version")}
            {...formik.getFieldProps("firmware_version")}
            error={formik.touched.firmware_version && formik.errors.firmware_version}
            disabled={formik?.values?.client_id ? false : true}
          />

          {selectedClientId == formik?.values?.client_id && idToEdit && (
            <div className="qi-input">
              <label className="qi-input_label">
                {getLocalizedString("available_ssl", "Available SSL")}
              </label>
              <div className="qi-input_wrapper">
                {formik.values.backend_ssl_certificate_url &&
                !formik.values.remove_backend_ssl_certificate ? (
                  <div className="chip-container available-ssl-chip">
                    <span className="qi-chip">
                      <a
                        href={`${formik.values.backend_ssl_certificate_url}`}
                        className="flex items-center"
                      >
                        <SvgIcon
                          name="download"
                          wrapperClass="icon-download"
                          svgClass="icon"
                          onClick={() =>
                            formik.setFieldValue("remove_backend_ssl_certificate", true)
                          }
                        />

                        {getFileNameFromUrl(formik.values.backend_ssl_certificate_url)}
                      </a>

                      <SvgIcon
                        name="cross"
                        wrapperClass="qi-chip_cross"
                        svgClass="icon"
                        onClick={() => formik.setFieldValue("remove_backend_ssl_certificate", true)}
                      />
                    </span>
                  </div>
                ) : (
                  getLocalizedString("no_file_selected", "No file selected")
                )}
              </div>
            </div>
          )}
          {selectedClientId == formik?.values?.client_id && (
            <Components.QIFileUploader
              label={getLocalizedString("ssl", "SSL")}
              placeholder={getLocalizedString("only_pem_and_crt_files", "Only .pem and .crt files")}
              value={formik.values.backend_ssl_certificate}
              onChange={(files) => {
                formik.setFieldValue("backend_ssl_certificate", files.length > 0 ? files[0] : null);
              }}
              error={
                formik.touched.backend_ssl_certificate && formik.errors.backend_ssl_certificate
              }
            />
          )}
        </FormContainer>
      ) : (
        <>
          {defaultTrip && (
            <DefaultTripConfiguration
              modelId={data?.device_model_id}
              setDefaultTripConfigurationId={setDefaultTripConfigurationId}
            />
          )}
          <FormContainer
            resourceName={getLocalizedString("device", "Device")}
            closeForm={() => onClose(false)}
            handleFormSubmit={formik.handleSubmit}
            idToEdit={idToEdit}
          >
            <Components.QICustomSelect
              label={getLocalizedString("manufacturer", "Manufacturer")}
              onChange={(value) => {
                handleDeviceManufacturerChange(value);
                setDManSearch("");
                setManufacturerModelChanged(true);
              }}
              value={formik.values.device_manufacturer_id}
              onSearch={setDManSearch}
              error={formik.touched.device_manufacturer_id && formik.errors.device_manufacturer_id}
              labelClassName="manufacturer"
            >
              {dMans?.data?.map((manufacturer, index) => (
                <li value={manufacturer.id} key={index}>
                  {manufacturer.name}
                </li>
              ))}
            </Components.QICustomSelect>

            <Components.QICustomSelect
              label={getLocalizedString("model", "Model")}
              onChange={(value) => handleDeviceModelChange(value)}
              disabled={!formik.values.device_manufacturer_id}
              value={formik.values.device_model_id}
              onSearch={setSearchDModel}
              error={formik.touched.device_model_id && formik.errors.device_model_id}
              labelClassName="model"
            >
              {dModels?.data.length == 0 ? (
                <li className="no-item-availlable">No Item Available.</li>
              ) : (
                dModels?.data?.map((model, index) => (
                  <li value={model.id} key={index}>
                    {model.name}
                  </li>
                ))
              )}
            </Components.QICustomSelect>

            <Components.QICustomSelect
              label={getLocalizedString("variable_mapping", "Variable Mapping")}
              value={
                formik.values.variable_mapping_id
                  ? formik.values.variable_mapping_id
                  : getDefaultVariableMapping()
              }
              onChange={(value) => {
                formik.setFieldValue("variable_mapping_id", value);
                setVariableMappingSearch("");
              }}
              disabled={!formik.values.device_model_id}
              onSearch={setVariableMappingSearch}
              labelClassName="variable-mapping"
            >
              <li value="">{getLocalizedString("select", "Select")}</li>
              {variableMappings?.data.map((variableMapping) => (
                <li value={variableMapping.id} key={variableMapping.id}>
                  {variableMapping.name}
                </li>
              ))}
            </Components.QICustomSelect>

            <Components.QICustomSelect
              label={getLocalizedString("trip_configuration", "Trip Configuration")}
              value={getTripValue(formik.values.trip_event_configuration_id)}
              onChange={(value) => {
                formik.setFieldValue("trip_event_configuration_id", value);
              }}
              disabled={!formik.values.device_model_id}
              error={
                formik.touched.trip_event_configuration_id &&
                formik.errors.trip_event_configuration_id
              }
              labelClassName="trip-configuration"
            >
              <li value="">{getLocalizedString("select", "Select")}</li>
              {tripEventsList?.data?.map((trip) => (
                <li value={trip.id} key={trip.id}>
                  {trip.name}{" "}
                  <small className="default-model">{`${
                    defaultTripConfigurationId == trip.id ? "(default model)" : ""
                  }`}</small>
                </li>
              ))}
            </Components.QICustomSelect>

            <Components.QIInput
              label={getLocalizedString("name", "Name")}
              {...formik.getFieldProps("name")}
              error={formik.touched.name && formik.errors.name}
            />
            <Components.QIInput
              label="ID/IMEI"
              {...formik.getFieldProps("device_id")}
              error={formik.touched.device_id && formik.errors.device_id}
            />
            <Components.QICustomSelect
              label={getLocalizedString("primary_sim_card", "Primary SIM Card")}
              value={formik.values.primary_sim_card_id}
              onChange={(value) => handlePrimarySimCard(value)}
              error={formik.touched.primary_sim_card_id && formik.errors.primary_sim_card_id}
              onSearch={(value) => setSimCardsSearchKey(value)}
              labelClassName="primary-sim-card"
            >
              <li value="">{getLocalizedString("select", "Select")}</li>
              {(simCards?.data || []).map((simCard) => (
                <li value={simCard.id} key={simCard.id} title={simCard?.imsi}>
                  {simCard?.imsi}
                </li>
              ))}
            </Components.QICustomSelect>

            <div className="flex justify-between country-code-and-number">
              <Components.QICustomSelect
                label="Country Code"
                placeholder="Country code"
                disabled={true}
                className="country-code-and-number_item1"
                {...formik.getFieldProps("country_code")}
                onChange={(value) => formik.setFieldValue("country_code", value)}
                onSearch={setCountryCodeSearchKey}
                error={formik.touched.country_code && formik.errors.country_code}
                labelClassName="country-code"
              >
                <li value="">Select</li>
                {countryCode.map((code, index) => (
                  <li value={code.dial_code} title={`${code.dial_code} (${code.name})`} key={index}>
                    {code.dial_code} ({code.name})
                  </li>
                ))}
              </Components.QICustomSelect>

              <Components.QIInput
                label="Phone No."
                placeholder="Phone No"
                disabled={true}
                className="country-code-and-number_item2"
                customLabelClass="phone-number"
                customWrapperClass="phone-number-wrapper"
                {...formik.getFieldProps("simcard_phone_no")}
                error={formik.touched.simcard_phone_no && formik.errors.simcard_phone_no}
              />
            </div>

            {/*
      Secondary SIM Card option will be available in further release
      <Components.QICustomSelect
        label={getLocalizedString("secondary_sim_card", "Secondary SIM Card")}
        value={formik.values.secondary_sim_card_id}
        onChange={(value) => {
          formik.setFieldValue("secondary_sim_card_id", value);
          setSimCardsSearchKey("");
        }}
        error={formik.touched.secondary_sim_card_id && formik.errors.secondary_sim_card_id}
        onSearch={(value) => setSimCardsSearchKey(value)}
        disabled={formik?.values?.primary_sim_card_id ? false : true}
      >
        <li value="">{getLocalizedString("select", "Select")}</li>
        {(simCards?.data || []).map((simCard) => (
          <li
            value={simCard.id}
            key={simCard.id}
            title={`${simCard?.display_name} (${simCard?.dial_code}-${simCard?.phone_no})`}
          >
            {simCard?.display_name} ({simCard?.dial_code}-{simCard?.phone_no})
          </li>
        ))}
      </Components.QICustomSelect> */}

            <div className="qi-input credentials">
              <label className="qi-input_label">
                {getLocalizedString("sms_credentials", "SMS Credentials")}
              </label>
              <div className="qi-input_wrapper">
                <Components.QIInput
                  label={getLocalizedString("username", "Username")}
                  {...formik.getFieldProps("credentials.username")}
                  error={
                    formik.touched.credentials?.username && formik.errors.credentials?.username
                  }
                />
                <Components.QIInput
                  label={getLocalizedString("password", "Password")}
                  {...formik.getFieldProps("credentials.password")}
                  onChangeEdit={(value) => {
                    formik.setFieldValue("credentials.password", value);
                  }}
                  type="password"
                  edit={idToEdit}
                  disabled={idToEdit}
                  error={
                    formik.touched.credentials?.password && formik.errors.credentials?.password
                  }
                  handleWarningMessage={(value) => setResetPasswordWarning(value)}
                  warningMessage={`${resetPasswordWarning ? "Please tick to reset password." : ""}`}
                />
              </div>
            </div>
            <Components.QISwitch
              label={getLocalizedString("enabled", "Enabled")}
              value={formik.values.enabled}
              onChange={() => formik.setFieldValue("enabled", !formik.values.enabled)}
            />
            <Components.QIMultiSelectDropDown
              label={getLocalizedString("groups", "Groups")}
              selected={formik.values.device_group_ids}
              onChange={(value) => formik.setFieldValue("device_group_ids", value)}
              data={deviceGroups?.data || []}
              className="narrow-multiselect-dropdown"
            />
            {/* <Components.QIMultiSelectDropDown
        label={getLocalizedString("applications", "Applications")}
        selected={formik.values.app_ids}
        onChange={(value) => formik.setFieldValue("app_ids", value)}
        data={apps?.data || []}
      /> */}
            <Components.QIMultiSelectDropDown
              label={getLocalizedString("backends", "Backends")}
              className="backends-owner"
              data={backends?.data || []}
              labelKey={(data) => `${data.name}`}
              selected={formik.values.backend_ids}
              onChange={(updatedList) => {
                formik.setFieldValue("backend_ids", updatedList);
                setBackendsSearchKey("");
              }}
              disabled={!isBackendSuccess}
              onSearch={(value) => setBackendsSearchKey(value)}
              actionError={handleBackendSelection}
              getRowClass={getClassName}
              isSelectable={isBackendSelectable}
              isDeletable={isBackendDeletable}
            />
            <div className="qi-input credentials">
              <label className="qi-input_label">
                {getLocalizedString("backend_credentials", "Backend Credentials")}
              </label>
              <div className="qi-input_wrapper">
                <Components.QIInput
                  label={getLocalizedString("username", "Username")}
                  {...formik.getFieldProps("backend_credentials.username")}
                  error={
                    formik.touched.backend_credentials?.username &&
                    formik.errors.backend_credentials?.username
                  }
                />
                <Components.QIInput
                  label={getLocalizedString("password", "Password")}
                  {...formik.getFieldProps("backend_credentials.password")}
                  onChangeEdit={(value) => {
                    formik.setFieldValue("backend_credentials.password", value);
                  }}
                  type="password"
                  edit={idToEdit}
                  disabled={idToEdit}
                  error={
                    formik.touched.backend_credentials?.password &&
                    formik.errors.backend_credentials?.password
                  }
                  handleWarningMessage={(value) => setResetBackendPasswordWarning(value)}
                  warningMessage={`${
                    resetBackendPasswordWarning ? "Please tick to reset password." : ""
                  }`}
                />
              </div>
            </div>
            <Components.QICustomSelect
              label={getLocalizedString("geolocation_plan", "Geolocation Plan")}
              value={formik.values.geolocation_plan_id}
              onChange={(value) => {
                formik.setFieldValue("geolocation_plan_id", value);
                setGeolocationPlanSearchKey("");
              }}
              error={formik.touched.geolocation_plan_id && formik.errors.geolocation_plan_id}
              onSearch={(value) => setGeolocationPlanSearchKey(value)}
              labelClassName="geolocation-plan"
            >
              <li value="">{getLocalizedString("select", "Select")}</li>
              {(geolocation_plans?.data || []).map((g) => (
                <li value={g.id} key={g.id}>
                  {g.name}
                </li>
              ))}
            </Components.QICustomSelect>
            <Components.QIInput
              label={getLocalizedString("firmware_version", "Firmware Version")}
              {...formik.getFieldProps("firmware_version")}
              error={formik.touched.firmware_version && formik.errors.firmware_version}
            />
            {idToEdit && (
              <div className="qi-input">
                <label className="qi-input_label">
                  {getLocalizedString("available_ssl", "Available SSL")}
                </label>
                <div className="qi-input_wrapper">
                  {formik.values.backend_ssl_certificate_url &&
                  !formik.values.remove_backend_ssl_certificate ? (
                    <div className="chip-container available-ssl-chip">
                      <span className="qi-chip">
                        <a
                          href={`${formik.values.backend_ssl_certificate_url}`}
                          className="flex items-center"
                        >
                          <SvgIcon
                            name="download"
                            wrapperClass="icon-download"
                            svgClass="icon"
                            onClick={() =>
                              formik.setFieldValue("remove_backend_ssl_certificate", true)
                            }
                          />

                          {getFileNameFromUrl(formik.values.backend_ssl_certificate_url)}
                        </a>

                        <SvgIcon
                          name="cross"
                          wrapperClass="qi-chip_cross"
                          svgClass="icon"
                          onClick={() =>
                            formik.setFieldValue("remove_backend_ssl_certificate", true)
                          }
                        />
                      </span>
                    </div>
                  ) : (
                    getLocalizedString("no_file_selected", "No file selected")
                  )}
                </div>
              </div>
            )}
            <Components.QIFileUploader
              label={getLocalizedString("ssl", "SSL")}
              placeholder={getLocalizedString("only_pem_and_crt_files", "Only .pem and .crt files")}
              value={formik.values.backend_ssl_certificate}
              onChange={(files) => {
                formik.setFieldValue("backend_ssl_certificate", files.length > 0 ? files[0] : null);
              }}
              error={
                formik.touched.backend_ssl_certificate && formik.errors.backend_ssl_certificate
              }
            />
          </FormContainer>
        </>
      )}
    </>
  );
};
