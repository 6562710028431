import React, { useEffect, useState } from "react";
import QIMap from "@qinvent/map";
import mapboxgl from "mapbox-gl"; // Mapbox SDK
import "mapbox-gl/dist/mapbox-gl.css";
import { Loader } from "@googlemaps/js-api-loader"; // Google Maps Loader

export const QIMapLoader = ({
  containerId,
  className = "",
  countryConfig = {
    center: [0, 0],
    minZoom: 1,
    zoom: 1,
    maxZoom: 25,
  },
  dataFormat = {
    lat: "lat",
    lon: "lng",
    time: "recorded_on",
  },
  mapProvider = "qiMap", // "qiMap", "mapbox", or "google"
  mapboxAccessToken,
  googleMapsApiKey,
  showTraffic = true,
  setMapRef = () => {},
  children,
  mapExpand,
  dockAlign,
  selectedRowId,
  maximizeMinimize,
}) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if ((maximizeMinimize || dockAlign || mapExpand || selectedRowId) && map) {
      if (mapProvider === "qiMap") {
        map.getMapReference().updateSize();
      } else if (mapProvider === "mapbox" || mapProvider === "google") {
        map.resize?.();
      }
    }
  }, [mapExpand, map, dockAlign, selectedRowId, maximizeMinimize, mapProvider]);

  useEffect(() => {
    const initializeMap = async () => {
      if (mapProvider === "qiMap") {
        const qiMap = new QIMap({
          containerId,
          baseLayer: ["OSM"],
          dataFormat,
          currentCountry: countryConfig,
          defaultBaseLayer: "OSM",
        });
        qiMap.createMap();

        qiMap.removeLayerSource = (layerName) => {
          const layerArr = Object.keys(qiMap.layer);
          const strVal = layerName.toString();
          const eleIndex = layerArr.indexOf(strVal);
          const layer = qiMap.layer[layerArr[eleIndex]];
          qiMap.resetZoomLevel();
          layer?.getSource()?.clear();
        };

        qiMap.resetZoomLevel = () => {
          const initialSize = qiMap.getMapReference().getSize();
          const map = qiMap.getMapReference();
          const view = map.getView();
          view.setZoom(countryConfig.zoom);
          view.centerOn(countryConfig.center, initialSize, [500, 400]);
        };

        setMap(qiMap);
        setMapRef(qiMap);
      } else if (mapProvider === "mapbox") {
        mapboxgl.accessToken = mapboxAccessToken;

        const container = document.getElementById(containerId);
        if (!container) {
          console.error(`Container with ID "${containerId}" not found.`);
          return;
        }

        const mapboxMap = new mapboxgl.Map({
          container: containerId,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [...countryConfig.center].reverse(),
          zoom: countryConfig.zoom,
        });

        mapboxMap.on("load", () => {
          if (showTraffic) {
            mapboxMap.addSource("traffic", {
              type: "vector",
              url: "mapbox://mapbox.mapbox-traffic-v1",
            });

            mapboxMap.addLayer({
              id: "traffic-layer",
              type: "line",
              source: "traffic",
              "source-layer": "traffic",
              paint: {
                "line-color": [
                  "match",
                  ["get", "congestion"],
                  "low",
                  "#46b979",
                  "moderate",
                  "#f1f075",
                  "heavy",
                  "#e55e5e",
                  "severe",
                  "#ff0000",
                  "#cccccc",
                ],
                "line-width": 2,
              },
              layout: {
                "icon-image": "marker", // Default Mapbox marker icon
                "icon-size": 1,
                "text-field": ["get", "plate_number"],
                "text-size": 12,
                "text-offset": [0, -1.5],
                "text-anchor": "top",
              },
            });
          }
        });

        setMap(mapboxMap);
        setMapRef(mapboxMap);
      } else if (mapProvider === "google") {
        const loader = new Loader({
          apiKey: googleMapsApiKey,
          libraries: ["visualization"],
        });

        loader.load().then((google) => {
          const googleMap = new google.maps.Map(document.getElementById(containerId), {
            center: { lat: countryConfig.center[1], lng: countryConfig.center[0] },
            zoom: countryConfig.zoom,
          });

          if (showTraffic) {
            const trafficLayer = new google.maps.TrafficLayer();
            trafficLayer.setMap(googleMap);
          }

          setMap(googleMap);
          setMapRef(googleMap);
        });
      }
    };

    initializeMap();
  }, [mapProvider]);

  return (
    <main className="relative">
      <aside className="collapsable-side-panel">{children}</aside>
      <article className={`qiMap ${className}`}>
        <div id={containerId} style={{ width: "100%", height: "100%" }}></div>
      </article>
    </main>
  );
};
