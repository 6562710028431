import { SvgIcon } from "../../containers/Shared";

export const QISimpleSearch = ({
  searchKey,
  handleSearchChange,
  simpleSearchPlaceholder = "Search",
  handleSearchSwitch,
  handleAdvanceSearchKeys,
  setDetails,
  setDockAlign,
  setDeviceId = () => {},
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <form
        className="search-form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="qi-input qi-form-element">
          <SvgIcon
            wrapperClass="icons-wrapper search-icon"
            svgClass="icon icon-search"
            name="search"
          />

          <div className="qi-input_wrapper">
            <input
              className="qi-input_text"
              onChange={(e) => handleSearchChange(e.target.value)}
              onKeyDown={handleKeyDown}
              value={searchKey}
              type="search"
              title={simpleSearchPlaceholder}
              placeholder={simpleSearchPlaceholder}
              autoFocus
            />
          </div>

          {searchKey && (
            <button
              className="qi-btn secondary sm"
              onClick={(e) => {
                handleSearchChange("");
                try {
                  setTimeout(() => {
                    setDeviceId(null);
                    setDetails(false);
                    setDockAlign("cross");
                  }, 100);
                } catch (e) {}
              }}
            >
              clear
            </button>
          )}

          {handleAdvanceSearchKeys && (
            <SvgIcon
              wrapperClass="clickable filter-icon"
              svgClass="icon-filter"
              name="filter"
              onClick={handleSearchSwitch}
              title="Filter"
            />
          )}
        </div>
      </form>
    </>
  );
};
