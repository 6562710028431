import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { FMDPStorage } from "../../../shared/helper";
import {
  QIInput,
  QIMultiSelectDropDown,
  QIPhotoUploader,
  QIModal,
  QIModalBody,
  QIModalFooter,
  QIButton,
  QICustomSelect,
} from "../../../components";
import {
  useResetPasswordMutation,
  useUpdateUserProfileMutation,
  useGetLanguagesQuery,
  useGetTranslationQuery,
  useGetLocalizationsQuery,
} from "../services";
import { useCreateAuthMutation } from "../../Authentication/service";
import { parseServerError } from "../../../shared/helper";
import { changePasswordFormValidation, userProfileSchema } from "../validations";
import { userImg } from "../../../assets";
import { SvgIcon } from "../../Shared";
import { getLocalizedString } from "../../../shared/translation";
import { useDropDownSearch } from "../../../hooks";

const ChangePasswordForm = () => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordFormValidation,
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  const [update, { error: updateError, isSuccess: updateSuccess, reset: resetUpdate }] =
    useResetPasswordMutation();

  const handleSubmit = (formData) => {
    const currentUser = FMDPStorage.get("current-user");
    update({ formData, id: currentUser.id });
  };

  const handleCancel = () => {
    formik.resetForm();

    setShowChangePassword(false);
  };

  useEffect(() => {
    if (updateError) {
      formik.setErrors(parseServerError(updateError));
    }
  }, [updateError]);

  useEffect(() => {
    if (updateSuccess) {
      handleCancel();
      setShowSuccessModal(true);
      resetUpdate();
    }
  }, [updateSuccess]);

  return (
    <>
      <form className="change-password ">
        <div className="flex">
          <h1 className="page-title xl mb-3 mr-2">
            {getLocalizedString("change_password", "Change Password")}
          </h1>
          <SvgIcon
            wrapperClass="edit-icon clickable"
            name="edit"
            svgClass="icon-edit"
            onClick={
              showChangePassword ? handleCancel : () => setShowChangePassword((prevVal) => !prevVal)
            }
          />
        </div>

        {formik.serverError && <p>Something went wrong</p>}
        <div>
          <QIInput
            label={getLocalizedString("current_password", "Current Password")}
            type="password"
            {...formik.getFieldProps("current_password")}
            error={formik.touched.current_password && formik.errors.current_password}
            disabled={!showChangePassword}
          />
          <QIInput
            label={getLocalizedString("new_password", "New Password")}
            type="password"
            {...formik.getFieldProps("password")}
            error={formik.touched.password && formik.errors.password}
            disabled={!showChangePassword}
          />
          <QIInput
            label={getLocalizedString("repeat", "Repeat")}
            type="password"
            {...formik.getFieldProps("password_confirmation")}
            error={formik.touched.password_confirmation && formik.errors.password_confirmation}
            disabled={!showChangePassword}
          />
        </div>
        {showChangePassword && (
          <div className="flex items-center justify-center">
            <QIButton className="secondary sm mr-2" onClick={handleCancel}>
              {getLocalizedString("cancel", "Cancel")}
            </QIButton>
            <QIButton className="primary sm" onClick={formik.handleSubmit}>
              {getLocalizedString("update", "Update")}
            </QIButton>
          </div>
        )}
      </form>
      <QIModal show={showSuccessModal} className="confirm">
        <QIModalBody>
          <p className="text-center">
            {getLocalizedString("password_successfully_changed", "Password Successfully changed")}
          </p>
        </QIModalBody>
        <QIModalFooter>
          <QIButton className="qi-btn primary sm" onClick={() => setShowSuccessModal(false)}>
            {getLocalizedString("okay", "Okay")}
          </QIButton>
        </QIModalFooter>
      </QIModal>
    </>
  );
};

export const UserProfile = () => {
  const dropdownParams = { per_page: 1000, order_by: "name" };
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState({
    image: "",
    first_name: "",
    last_name: "",
    email: "",
    locale_id: "",
  });

  // Get the current user details from the local storage
  const currentUserDetails = FMDPStorage.get("current-user");

  // Get the current selected app
  const selectedAppId = FMDPStorage.get("selected-app-id");

  // Get the user config json and the localization profile
  let userConfigJson = "";
  let LocalizationProfile = "";
  currentUserDetails?.client_apps?.map((user) => {
    if (user?.id === selectedAppId) {
      userConfigJson = user?.client?.config_json;
    }
  });

  try {
    LocalizationProfile = JSON.parse(userConfigJson)?.tracking?.web?.localization_profile;
  } catch {}

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetLocalizationsQuery({
    language: currentUserDetails?.locale?.code,
    profile: LocalizationProfile || "Tracksynq Web",
    per_page: 1000,
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: userProfileSchema,
    onSubmit: (data) => updateUser(data),
  });

  // Get the langauges list using Dropdown Search
  const {
    data: languages,
    setSearchKey: setLanguagesSearch,
    isSuccess: isLanguagesSuccess,
  } = useDropDownSearch({
    useData: useGetLanguagesQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.locale_id,
    additionalParams: { ...dropdownParams },
  });

  const [, { data: currentUser }] = useCreateAuthMutation({
    fixedCacheKey: "shared-sso-data",
  });

  const [update, { error: updateError, isSuccess: updateSuccess }] = useUpdateUserProfileMutation();

  const cancelUpdate = () => {
    const { first_name, last_name, email, image, locale } = currentUser;
    const selectedApps = currentUser?.client_apps?.map((app) => app.id);
    formik.setValues({
      first_name,
      last_name,
      email,
      image,
      client_apps: selectedApps,
      locale_id: locale?.id,
    });
    setEditMode(false);
  };

  const toggleEditMode = () => {
    if (editMode) {
      cancelUpdate();
    } else {
      setEditMode(true);
    }
  };

  const updateUser = (formData) => {
    let fObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key == "image" && formData.image == null) {
        fObj.append("remove_image", true);
      } else if (!(key == "image" && formData.image == "") && formData[key] != null) {
        fObj.append(key, formData[key]);
      }
    });

    update({ formData: fObj, id: currentUser.id });
  };

  // Updating the local storage
  useEffect(() => {
    if (updateSuccess) {
      // If preferred language is selected the the local storage is updated
      if (formik?.values?.locale_id) {
        const selectedLanguage = languages?.data.find((lan) => lan?.id == formik?.values.locale_id);
        const updatedUser = { ...currentUser, locale: selectedLanguage };
        FMDPStorage.set("current-user", updatedUser);
      }
      window.location.reload();
    }
  }, [updateSuccess]);

  useEffect(() => {
    const { first_name, last_name, email, picture, locale } = currentUser;
    const selectedApps = currentUser?.client_apps?.map((app) => app?.id);
    setInitialValues({
      first_name,
      last_name,
      email,
      image: picture,
      client_apps: selectedApps,
      locale_id: locale?.id,
    });
  }, []);

  useEffect(() => {
    if (updateError) {
      formik.setErrors(parseServerError(updateError));
    }
  }, [updateError]);

  return (
    <>
      <div className="user-profile-details">
        <header className="main-container_header">
          <div className="profile-header-content">
            <h1 className="page-title xl">{getLocalizedString("user_profile", "User Profile")}</h1>
            <SvgIcon
              wrapperClass="edit-icon clickable"
              name="edit"
              svgClass="icon-edit"
              onClick={toggleEditMode}
            />
          </div>
        </header>
        <div className="user-profile-details_form-container">
          <div className="profile-form">
            <div className="profile-form_photo profile-image-upload-guide-fix">
              <QIPhotoUploader
                placeHolderImg={userImg}
                src={formik.values.image}
                onChange={(files) =>
                  formik.setFieldValue("image", files?.length > 0 ? files[0] : null)
                }
                readOnly={!editMode}
                disabled={!editMode}
                showJustImage
                base64={false}
                initalImageUrl={formik.values.image}
                className="profile-photo-upload"
                error={formik.touched.image && formik.errors.image}
                photoGuide={
                  editMode &&
                  `
                <h4 className="font-semibold">${getLocalizedString(
                  "image_upload_guide",
                  "Image Upload guide"
                )} - </h4>
                <div className="ml-2">
                  <p>${getLocalizedString("max_size", "Max size")} 40kb</p>
                  <p>${getLocalizedString("file_format", "File format")} .jpg/ .jpeg/ .png/ .gif</p>
                  <p>${getLocalizedString(
                    "preferred_image_aspect_ratio",
                    "Preferred image aspect ratio"
                  )} 1:1</p>
                </div>
              `
                }
              />
            </div>
            <div className="profile-form_right">
              <QIInput
                label={getLocalizedString("first_name", "First Name")}
                {...formik.getFieldProps("first_name")}
                error={formik.touched.first_name && formik.errors.first_name}
                readOnly={!editMode}
                disabled={!editMode}
              />
              <QIInput
                label={getLocalizedString("last_name", "Last Name")}
                {...formik.getFieldProps("last_name")}
                error={formik.touched.last_name && formik.errors.last_name}
                readOnly={!editMode}
                disabled={!editMode}
              />
              <QIInput
                label={getLocalizedString("email", "Email")}
                {...formik.getFieldProps("email")}
                readOnly
                disabled
              />
              <QICustomSelect
                label={getLocalizedString("preferred_language", "Preferred Language")}
                {...formik.getFieldProps("locale_id")}
                onChange={(value) => {
                  formik.setFieldValue("locale_id", value);
                  setLanguagesSearch("");
                }}
                error={formik.touched.locale_id && formik.errors.locale_id}
                readOnly={!editMode}
                disabled={!editMode}
                labelClassName="preferred-language"
                onSearch={setLanguagesSearch}
              >
                <>
                  {languages?.data?.map((lan, index) => (
                    <li value={lan.id} key={index}>
                      {lan.name}
                    </li>
                  ))}
                </>
              </QICustomSelect>
              <QIMultiSelectDropDown
                label={getLocalizedString("apps", "Apps")}
                data={currentUser.client_apps}
                selected={formik.values.client_apps}
                readOnly
              />

              <div className="user-profile-details_footer">
                {editMode && (
                  <>
                    <QIButton className="secondary sm mt-4 mr-2 " onClick={cancelUpdate}>
                      Cancel
                    </QIButton>
                    <QIButton className="primary sm mt-4" onClick={formik.handleSubmit}>
                      Update
                    </QIButton>
                  </>
                )}
              </div>
            </div>
          </div>

          <ChangePasswordForm
            show={showChangePassword}
            onHide={() => setShowChangePassword(false)}
          />
        </div>
      </div>
    </>
  );
};
