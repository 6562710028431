import { useState, useEffect } from "react";
import { mergeObjects } from "../../../../shared/helper";
import { useFormik } from "formik";
import { pluginFormValidation } from "../../validations";
import {
  useGetPluginQuery,
  useCreatePluginMutation,
  useUpdatePluginMutation,
  useGetAppsQuery,
  useGetContainersQuery,
} from "../../services";
import { useForm } from "../../../../hooks";
import * as Components from "../../../../components";
import { FormContainer } from "../../Shared";
import { getLocalizedString } from "../../../../shared/translation";
import { da } from "date-fns/locale";

export const PluginForm = ({ idToEdit = null, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [mpcontainers, setMPContainers] = useState([
    { id: 1, name: "Devices" },
    { id: 2, name: "Vehicles" },
    { id: 3, name: "Groups" },
    { id: 4, name: "Configurations" },
    { id: 5, name: "Users And Roles" },
  ]);

  const [tcontainers, setTcontainers] = useState([
    { id: 1, name: "Vehicles" },
    { id: 2, name: "Trips" },
    { id: 3, name: "Activities" },
    { id: 4, name: "Events" },
  ]);

  const [appMap, setAppMap] = useState({});
  const [mpMap, setMMap] = useState({});
  const [tMap, setTMap] = useState({});

  const [initialValues, setInitialValues] = useState({
    bundle: null,
    name: "",
    path: "",
    description: "",
    author: "",
    version: "",
    status: "under review",
    url: "localhost:8000",
    scope: "",
    module: "",
    permission: [],
    mp_containers: [],
    tracker_containers: [],
    is_enabled: false,
    loadedBundle: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: pluginFormValidation(!!idToEdit),
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createPlugin, update: updatePlugin } = useForm({
    createMutation: useCreatePluginMutation,
    updateMutation: useUpdatePluginMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  // Fetch the data if edit form
  const { data: selectedPlugin } = useGetPluginQuery({ id: idToEdit }, { skip: !idToEdit });

  // Get list of apps subscribed by client
  const { data: clientApp = { data: [] }, isSuccess: clientAppSuccess } = useGetAppsQuery();

  //Get Containers query

  const { data: newContainers } = useGetContainersQuery({ page: 0, per_page: 500 });

  // Modify Containers data if new containers are present
  useEffect(() => {
    if (newContainers?.data?.length) {
      const mpData = [];
      const tData = [];

      newContainers.data.forEach((container) => {
        if (!container?.system_default) {
          if (container?.client_app_names?.includes("Management Portal")) {
            mpData.push({ id: container?.id, name: container.name });
          } else {
            tData.push({ id: container?.id, name: container.name });
          }
        }
      });

      setMPContainers((prev) => [...prev, ...mpData]);
      setTcontainers((prev) => [...prev, ...tData]);
    }
  }, [newContainers]);

  useEffect(() => {
    if (idToEdit && selectedPlugin?.bundle_url?.url) {
      fetch(selectedPlugin?.bundle_url?.url)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = selectedPlugin?.bundle_url?.url.split("/").pop();
          const file = new File([blob], fileName, { type: blob.type });
          formik.setFieldValue("bundle", file);
          formik.setFieldValue("loadedBundle", fileName); // Ensure loadedBundle is updated
          setIsFileLoaded(true); // Mark file as loaded
        })
        .catch((error) => {
          console.error("Error fetching the bundle file:", error);
          formik.setFieldValue("loadedBundle", ""); // Handle error case
          setIsFileLoaded(true); // Proceed even if there's an error
        });
    }
  }, [selectedPlugin, idToEdit]);

  useEffect(() => {
    if (idToEdit && selectedPlugin) {
      const updatedPlugin = {
        ...selectedPlugin,
        permission: selectedPlugin.permission.map((s) => parseInt(s, 10)),
        mp_containers: selectedPlugin.mp_containers?.map((s) => parseInt(s, 10)),
        tracker_containers: selectedPlugin.tracker_containers?.map((s) => parseInt(s, 10)),
      };

      setInitialValues(mergeObjects(initialValues, updatedPlugin));

      const tempMPData = selectedPlugin?.mp_containers?.map((mp_container) => ({
        id: 1,
        mp_containers: mp_container,
        tracker_containers: "",
      }));

      const tempTData = selectedPlugin?.tracker_containers?.map((tracker_container) => ({
        id: 2,
        mp_containers: "",
        tracker_containers: tracker_container,
      }));

      const combinedData = [...tempMPData, ...tempTData];
      setReorderedMappingData(combinedData);
    }
  }, [selectedPlugin, idToEdit]);

  const handleFormSubmit = (formData) => {
    setIsLoading(true);
    let fObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "bundle") {
        fObj.append(key, formData[key]);
      } else {
        if (typeof formData[key] === "object") {
          fObj.append(key, JSON.stringify(formData[key]));
        } else {
          fObj.append(key, formData[key]);
        }
      }
    });

    if (idToEdit) {
      updatePlugin({ formData: fObj, id: idToEdit }).then(() => setIsLoading(false));
    } else {
      createPlugin({ formData: fObj }).then(() => setIsLoading(false));
    }
  };

  const applications = [
    { id: 1, name: "Management Portal" },
    { id: 2, name: "Tracking" },
  ];

  useEffect(() => {
    if (applications || mpcontainers || tcontainers) {
      const newAppMap = {}; // Temporary object to store key-value pairs
      applications.forEach((app) => {
        newAppMap[app.id] = app.name;
      });

      setAppMap(newAppMap);

      let newMpMap = {}; // Temporary object to store key-value pairs
      mpcontainers.forEach((mp) => {
        newMpMap[mp.id] = mp.name;
      });

      setMMap(newMpMap);

      let newTMap = {}; // Temporary object to store key-value pairs
      tcontainers.forEach((t) => {
        newTMap[t.id] = t.name;
      });

      setTMap(newTMap);
    }
  }, [mpcontainers, tcontainers]);

  useEffect(() => {
    setDisplayNameMap({ id: appMap, mp_containers: mpMap, tracker_containers: tMap });
  }, [appMap, mpMap, tMap]);

  const [mappingValues, setmappingValues] = useState({
    id: "",
    mp_containers: "",
    tracker_containers: "",
  });

  const formikMapping = useFormik({
    initialValues: mappingValues,
    enableReinitialize: true,
  });

  const [reorderedMappingData, setReorderedMappingData] = useState([]);
  const [displayNameMap, setDisplayNameMap] = useState({});
  const mapColumnNames = ["id", "mp_containers", "tracker_containers"];

  const handleSubmitContainer = (values) => {
    formik.setFieldValue("permission", Array.from(new Set(values.map((val) => val.id))));
    if (formikMapping.values.id === 1) {
      const filteredMPContainers = values
        .filter((val) => val.mp_containers !== "")
        .map((val) => val.mp_containers);
      formik.setFieldValue("mp_containers", filteredMPContainers);
    } else if (formikMapping.values.id === 2) {
      const filteredTrackerContainers = values
        .filter((val) => val.tracker_containers !== "")
        .map((val) => val.tracker_containers);
      formik.setFieldValue("tracker_containers", filteredTrackerContainers);
    } else {
      const filteredMPContainers = values
        .filter((val) => val.mp_containers !== "")
        .map((val) => val.mp_containers);
      const filteredTrackerContainers = values
        .filter((val) => val.tracker_containers !== "")
        .map((val) => val.tracker_containers);
      formik.setFieldValue("mp_containers", filteredMPContainers);
      formik.setFieldValue("tracker_containers", filteredTrackerContainers);
    }
  };

  const handleOnCancel = () => {
    formikMapping.setFieldValue("id", "");
    formikMapping.setFieldValue("mp_containers", "");
    formikMapping.setFieldValue("tracker_containers", "");
  };

  const [headers, setHeaders] = useState([
    {
      label: "Applications",
      key: "permission",
      error: "",
      dataType: "",
    },
    {
      label: "Container",
      key: "mp_containers || tracker_containers",
      error: "",
      dataType: "",
    },
    {
      className: "action",
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChange = (name, value) => {
    formikMapping.setFieldValue(name, JSON?.parse(value)?.id);
    if (name === "id") {
      formikMapping.setFieldValue("mp_containers", "");
      formikMapping.setFieldValue("tracker_containers", "");
    }
  };

  useEffect(() => {
    if (formik.values.bundle) {
      const scopeName = formik.values.bundle.name.split(".")[0];
      const moduleName = scopeName.charAt(0).toUpperCase() + scopeName.slice(1);
      formik.setFieldValue("scope", scopeName);
      formik.setFieldValue("module", moduleName);
    }
    if (formik.values.name) {
      formik.setFieldValue("path", formik.values.name);
    }
  }, [formik.values]);

  return (
    <FormContainer
      resourceName={"Plugin"}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
      isLoading={isLoading}
    >
      {!idToEdit && (
        <Components.QIFileUploader
          label="JavaScript bundle"
          placeholder="Only .js files"
          onChange={(files) => formik.setFieldValue("bundle", files[0])}
          error={formik.touched.bundle && formik.errors.bundle}
        />
      )}
      {idToEdit && (
        <Components.QIInput
          label={getLocalizedString("javascript-bundle", "JavaScript bundle")}
          readOnly
          {...formik.getFieldProps("loadedBundle")}
          error={formik.touched.loadedBundle && formik.errors.loadedBundle}
          warningMessage={!isFileLoaded && "Loading Bundle"}
          className="grey"
        />
      )}
      <Components.QIInput
        label={"Plugin Name"}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label={"URL Path"}
        disabled
        {...formik.getFieldProps("path")}
        error={formik.touched.path && formik.errors.path}
      />
      <Components.QIInput
        label={"Scope"}
        disabled
        {...formik.getFieldProps("scope")}
        error={formik.touched.scope && formik.errors.scope}
      />
      <Components.QIInput
        label={"Module"}
        disabled
        {...formik.getFieldProps("module")}
        error={formik.touched.module && formik.errors.module}
      />
      <Components.QIInput
        label={"Description"}
        {...formik.getFieldProps("description")}
        error={formik.touched.description && formik.errors.description}
      />
      <Components.QIInput
        label={"Author Name"}
        {...formik.getFieldProps("author")}
        // error={formik.touched.author && formik.errors.author}
      />
      <Components.QIInput
        label={"Version"}
        {...formik.getFieldProps("version")}
        //error={formik.touched.version && formik.errors.version}
      />
      <Components.QITableCustom
        handleSubmit={handleSubmitContainer}
        headers={headers}
        objectItem={formikMapping.values}
        validationRequired={false}
        handleOnCancel={handleOnCancel}
        initialValues={reorderedMappingData}
        displayNameMap={displayNameMap}
        mapColumnNames={mapColumnNames}
        //error={formik.touched.permission && formik.errors.permission}
      >
        <div className="flex items-center gap-8">
          <div className="qi-select_wrapper">
            <select className="qi-select_text" onChange={(e) => handleChange("id", e.target.value)}>
              <>
                <optgroup label="">
                  <input type="text" value={searchTerm} onChange={handleSearchChange} />
                </optgroup>
                <option value="select">select</option>
                {clientApp?.data
                  ?.filter((data) => data?.name.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map((data, index) => (
                    <option value={JSON.stringify(data)} key={index}>
                      {data?.name}
                    </option>
                  ))}
              </>
            </select>
            <div className="text-red-600 small-error">{headers[0]?.error}</div>
          </div>
          <div className="qi-select_wrapper">
            <select
              className="qi-select_text"
              onChange={(e) =>
                handleChange(
                  formikMapping.values.id === 1 ? "mp_containers" : "tracker_containers",
                  e.target.value
                )
              }
            >
              <>
                <optgroup label="">
                  <input type="text" value={searchTerm} onChange={handleSearchChange} />
                </optgroup>
                <option value="select">select</option>
                {formikMapping.values.id === 1
                  ? mpcontainers
                      .filter((data) => data?.name.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((data, index) => (
                        <option value={JSON.stringify(data)} key={index}>
                          {data?.name}
                        </option>
                      ))
                  : tcontainers
                      .filter((data) => data?.name.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((data, index) => (
                        <option value={JSON.stringify(data)} key={index}>
                          {data?.name}
                        </option>
                      ))}
              </>
            </select>
            <div className="text-red-600 small-error">{headers[1]?.error}</div>
          </div>
        </div>
      </Components.QITableCustom>
      <Components.QISwitch
        label={getLocalizedString("Enabled", "Enabled")}
        {...formik.getFieldProps("is_enabled")}
        onChange={() => formik.setFieldValue("is_enabled", !formik.values.is_enabled)}
      />
    </FormContainer>
  );
};
