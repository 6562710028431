import { useState, useEffect } from "react";
import TripsHeader from "./TripsHeader";
import TripsListWrapper from "./TripsListWrapper";
import { useGetTripsTrackQuery } from "../../services";
import moment from "moment";
import { NoData } from "../../../Shared";
import { QIErrorBoundary, QISpinner } from "../../../../components";
import { usePagination } from "../../../../hooks";

const Activities = ({
  deviceId,
  selectedRange,
  clientId,
  selectedVehiclePlate,
  type,
  setActivitiesTimeRange = () => {},
  setListCount = () => {},
}) => {
  const { page, setPage, perPage, pagination } = usePagination();

  const [selectedId, setSelectedId] = useState(); // To select the first row by default

  const [tracksData, setTracksData] = useState([]);
  useEffect(() => {
    setTracksData([]);
  }, [selectedRange, deviceId]);

  const {
    data: response,
    error,
    isFetching,
    isSuccess,
  } = useGetTripsTrackQuery(
    deviceId && {
      deviceId: deviceId,
      startTime: moment(selectedRange.startDate).valueOf(),
      endTime: moment(selectedRange.endDate).valueOf(),
      clientId: clientId,
      page: page - 1,
      per_page: perPage,
      plate_number: [selectedVehiclePlate],
      source_id: [deviceId],
      type: type,
      search: false,
    }
  );

  //To set the total count of data in the list
  useEffect(() => {
    setListCount(response?.total_count);
  }, [response?.total_count]);

  useEffect(() => {
    if (response?.activities?.length > 0) {
      setActivitiesTimeRange({
        start_time: moment(response?.activities[0]?.data?.start_time),
        end_time: moment(response?.activities[0]?.data?.end_time),
      });
      setSelectedId(0);
    }
  }, [response?.activities]);

  useEffect(() => {
    if (response && deviceId) {
      try {
        if (response?.activities.length) {
          setTracksData([...response?.activities]);
        }
      } catch (e) {
        setTracksData([]);
      }
    }
  }, [response, deviceId, selectedRange]);

  const message = {
    header: error?.data?.message,
  };

  return (
    <div className="trips">
      <TripsHeader />
      {error && <QIErrorBoundary message={message} />}
      {isFetching && (
        <div className="no-data">
          <QISpinner size="50px" />
        </div>
      )}
      {!isFetching && isSuccess && tracksData?.length ? (
        <TripsListWrapper
          tracksData={tracksData || []}
          pagination={{ ...pagination, count: response.total_count }}
          setActivitiesTimeRange={setActivitiesTimeRange}
          selectedId={selectedId}
        />
      ) : (
        isSuccess && !error && !isFetching && <NoData />
      )}
    </div>
  );
};

export default Activities;
