import { useState } from "react";
import {
  MPSharedTable,
  MPSharedHeader,
  MPSharedDeleteModal,
  TableDropDownView,
} from "../../Shared";
import { PluginForm } from "./form";
import { useSearchQuery, usePagination, useDelete } from "../../../../hooks";
import { useGetPluginsQuery, useDeletePluginMutation, useGetAppsQuery } from "../../services";
import { TickOrCross } from "../../../Shared";
import { getLocalizedString } from "../../../../shared/translation";

export const PluginsManagerContainer = () => {
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);

  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_path_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
    q,
  });

  // Get list of apps subscribed by client
  const { data: clientApp = { data: [] }, isSuccess: clientAppSuccess } = useGetAppsQuery();

  const onEdit = (plugin) => {
    setShowForm(true);
    setIdToEdit(plugin.id);
  };

  const onDelete = (plugin) => {
    setShowDeleteWarning(true);
    setIdToDelete(plugin.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const headers = [
    { label: "Name", key: "name", className: "date" },
    { label: "Path", key: "path", className: "date" },
    { label: "Description", key: "description", className: "date" },
    {
      label: "Enabled",
      nestedValue: true,
      getNestedValue: ({ is_enabled }) => {
        return <TickOrCross flag={is_enabled} />;
      },
      className: "enabled",
    },
    {
      label: "Apps",
      type: "component",
      className: "applications date more",
      component: ({ data }) => (
        <TableDropDownView
          data={data?.permission.map((app_id) => clientApp.data[app_id - 1]?.name)}
        />
      ),
    },
    { label: "Author", key: "author", className: "date" },
    { label: "Version", key: "version" },
  ];

  const {
    deleteItem: deletePlugin,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeletePluginMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const getDeleteItemName = (id) => {
    const index = data.data.findIndex((item) => item.id === id);
    return data.data[index]?.name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          createNewHandler={onCreate}
          groupName={"client_config"}
          heading={getLocalizedString("settings", "Settings")}
          resourceName={getLocalizedString("plugins", "Plugins")}
          onEdit={onEdit}
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceholder={getLocalizedString(
            "search_by_name_path",
            "Search by name or path"
          )}
        ></MPSharedHeader>
        <MPSharedTable
          resourceName={getLocalizedString("plugins", "Plugins....")}
          isLoading={isFetching}
          isSuccess={isSuccess}
          error={error}
          headers={headers}
          data={data?.data}
          onDelete={onDelete}
          onEdit={onEdit}
          pagination={{
            ...pagination,
            count: data?.total_count,
          }}
          skipClientId
          skipCanEdit
          skipCanDelete
          className="plugins no-tabs"
          auditResource="Plugin"
          auditKey="path"
        />
      </article>
      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={"plugin"}
        error={deleteErrorMsg}
        onHide={() => {
          setDeleteErrorMsg();
          setShowDeleteWarning(false);
        }}
        itemName={getDeleteItemName(idToDelete)}
        onDelete={() => deletePlugin({ id: idToDelete })}
      />
      {showForm && <PluginForm idToEdit={idToEdit} onClose={setShowForm} />}
    </>
  );
};
