import { QIPagination, QISelect, QISlideSelect } from "../../../components";

export const PaginationHeader = ({ pagination, parentName, setShowDetails, setDockAlign }) => {
  let fromCount = Math.max(pagination.page - 1, 0) * pagination.perPage;
  let toCount = Math.min(pagination.page * pagination.perPage, pagination.count);

  return (
    <p className="showing-out-of text-sm text-right">
      Showing {fromCount} - {toCount} out of {pagination.count}
      {parentName && (
        <>
          &nbsp;of&nbsp;
          <strong>{`${parentName}`}</strong>
        </>
      )}
    </p>
  );
};

export const PaginationFooter = ({
  pagination,
  setDockAlign,
  setShowDetails,
  removeLayerOnClose,
  setDeviceId = () => {},
  setShowTrack,
  setHighlight,
}) => {
  let pageCount = Math.ceil(pagination.count / (pagination.perPage || 10));

  return (
    <aside className="qi-pagination-wrapper">
      {pagination.count > 20 && (
        <>
          <QISlideSelect pagination={pagination} />

          <QIPagination
            pageCount={pageCount}
            onPageChange={pagination.onPageChange}
            activePage={pagination.page}
            setDockAlign={setDockAlign}
            setShowDetails={setShowDetails}
            removeLayerOnClose={removeLayerOnClose}
            setDeviceId={setDeviceId}
            setShowTrack={setShowTrack}
            setHighlight={setHighlight}
          />
        </>
      )}
    </aside>
  );
};
