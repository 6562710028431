import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import {
  useCreateEventConfigurationMutation,
  useUpdateEventConfigurationMutation,
  useGetEventConfigurationQuery,
  useGetGeofencesQuery,
  useGetVariablesQuery,
  useGetEventConfigurationCustomCategoriesQuery,
} from "../services";
import { useForm, useDropDownSearch } from "../../../hooks";
import { EventConfigurationsFormValidation } from "../validations";
import { mergeObjects } from "../../../shared/helper";
import {
  events,
  eventTypes,
  unitTypes,
  batteryTypes,
  batteryUnitTypes,
  categories,
  numberTimestampDateVariableTypeCondition,
  textVariableTypeCondition,
  switchVariableTypeCondition,
} from "../../../shared/dropdownLists";
import { getLocalizedString } from "../../../shared/translation";
import { ConfirmationModal } from "../../Admin/Shared";
import { SvgIcon } from "../../Shared";
import { convertToEpoch } from "../../../shared/utility";

export const EventConfigurationsForm = ({ idToEdit = null, onClose }) => {
  // To maintain error message to choose at least one configuration
  const [configurationError, setConfigurationError] = useState("");

  // For geofence event if entry-exit toggle is off but speed and time limit is provided, need to show a confirmation
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationModalMessage, setShowConfirmationModalMessage] = useState("");

  const [showCalender, setShowCalender] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  // To handle submit form with confirmation message
  const [submitForm, setSubmitForm] = useState(true);

  // After confirmation modal is shown, submit form is allowed
  useEffect(() => {
    if (showConfirmationModal) {
      setSubmitForm(true);
    }
  }, [showConfirmationModal]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    event_type_id: "",
    min_idle_time: 30,
    max_idle_time: 300,
    unit: "",
    speed_limit: "",
    duration_threashold: "",
    report_interval_time: "",
    geofence_id: "",
    geofence_config: {
      inside: {
        enable: false,
        speed_limit: "",
        unit: "",
        duration_limit_in_sec: "",
      },
      outside: {
        enable: false,
      },
      entry: {
        enable: false,
      },
      exit: {
        enable: false,
      },
      cross: {
        enable: false,
      },
      duration_threshold_in_sec: "0",
    },
    battery_type: "",
    unit_value: "",
    enabled: false,
    category: "general",
    variable_id: "",
    start_condition: "",
    start_value: null,
    stop_value: null,
    trigger_interval: 0,
    start_status: "",
    stop_condition: "",
    stop_interval: 0,
    stop_status: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: EventConfigurationsFormValidation,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const { create: createEventConfiguration, update: updateEventConfiguration } = useForm({
    createMutation: useCreateEventConfigurationMutation,
    updateMutation: useUpdateEventConfigurationMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const { data = {} } = useGetEventConfigurationQuery({ id: idToEdit }, { skip: !idToEdit });

  const { data: eventConfigurationCustomCategories } =
    useGetEventConfigurationCustomCategoriesQuery();

  // Get the geofences list using Dropdown Search
  const {
    data: geofences,
    setSearchKey: setGeofenceSearchKey,
    isSuccess: isGeofenceSuccess,
  } = useDropDownSearch({
    useData: useGetGeofencesQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.geofence_id,
  });

  // Get the variables list using Dropdown Search
  const {
    data: variables,
    setSearchKey: setVariableSearchKey,
    isSuccess: isVariableSuccess,
  } = useDropDownSearch({
    useData: useGetVariablesQuery,
    simpleSearchKey: "name_or_display_name_or_section_name_or_unit_name_cont",
    selectedIds: formik.values.variable_id,
    additionalParams: {
      per_page: 2000,
      order_by: "variables.display_name",
      order_dir: "asc",
      variable_types: ["number", "text", "switch", "timestamp", "date"],
    },
  });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      const tempData = { ...data };

      // Modify the geofence_config property in tempData with outside as false
      tempData.geofence_config = {
        ...tempData.geofence_config,
        outside: { enable: false },
      };

      setInitialValues(mergeObjects(initialValues, tempData));
    }
  }, [data]);

  // If event type is geofence and entry-exit is off then only show the confirmation
  useEffect(() => {
    if (
      formik?.values?.event_type_id == events.Geofence &&
      formik?.values?.geofence_config?.entry?.enable &&
      formik?.values?.geofence_config?.exit?.enable
    ) {
      setSubmitForm(true);
    } else if (
      formik?.values?.event_type_id == events.Geofence &&
      (formik?.values?.geofence_config.inside.speed_limit ||
        formik?.values?.geofence_config.inside.duration_limit_in_sec) &&
      (!formik?.values?.geofence_config?.entry?.enable ||
        !formik?.values?.geofence_config?.exit?.enable)
    ) {
      setSubmitForm(false);
      if (
        formik?.values?.geofence_config?.inside?.speed_limit &&
        formik?.values?.geofence_config?.inside?.duration_limit_in_sec
      ) {
        setShowConfirmationModalMessage('"Speed Limit & Time Limit" will be no longer available.');
      } else if (formik?.values?.geofence_config?.inside?.speed_limit) {
        setShowConfirmationModalMessage('"Speed Limit" will be no longer available.');
      } else if (formik?.values?.geofence_config?.inside?.duration_limit_in_sec) {
        setShowConfirmationModalMessage('"Time Limit" will be no longer available.');
      }
    }
  }, [
    formik?.values?.geofence_config?.entry?.enable,
    formik?.values?.geofence_config?.exit?.enable,
  ]);

  // Set default values for event low battery and reset to 0 for the other events
  useEffect(() => {
    if (!idToEdit) {
      if (formik.values.event_type_id === events.Battery) {
        // Set default interval time to 60 secs
        formik.setFieldValue("report_interval_time", 60);
        // Set default value to 5
        formik.setFieldValue("unit_value", 5);
        // Set default battery type to internal
        formik.setFieldValue("battery_type", "internal");
        // Set default battery unit to percent
        formik.setFieldValue("unit", "percent");
      } else if (formik.values.event_type_id === events.Speeding) {
        //Set default unit to km_per_hour for speeding event
        formik.setFieldValue("unit", "km_per_hour");
      } else {
        // For the other events apart from low battery reset all
        formik.setFieldValue("report_interval_time", 1);
        formik.setFieldValue("unit_value", "");
        formik.setFieldValue("battery_type", "");
        formik.setFieldValue("unit", "");
      }
    }
  }, [formik.values.event_type_id]);

  useEffect(() => {
    if (formik?.values?.event_type_id === events.Ignition) {
      formik.setFieldValue("enabled", true);
    } else {
      formik.setFieldValue("enabled", false);
    }
  }, [formik?.values?.event_type_id]);

  // When speed limit is provided, unit km/hr is chosen by default else unit remains empty
  useEffect(() => {
    if (!formik?.values?.geofence_config?.inside?.unit) {
      if (formik?.values?.geofence_config?.inside?.speed_limit) {
        formik?.setFieldValue("geofence_config.inside.unit", "km_per_hour");
      }
    } else if (
      formik?.values?.geofence_config?.inside?.unit &&
      !formik?.values?.geofence_config?.inside?.speed_limit
    ) {
      formik?.setFieldValue("geofence_config.inside.unit", "");
    }
  }, [formik?.values?.geofence_config?.inside?.speed_limit]);

  const handleFormSubmit = (formData) => {
    if (formData?.event_type_id == events?.Ignition) {
      formData.enabled = true;
    }
    if (
      formData.event_type_id == events.Geofence &&
      !formData.geofence_config.entry.enable &&
      !formData.geofence_config.exit.enable &&
      !formData.geofence_config.inside.enable &&
      !formData.geofence_config.inside.speed_limit &&
      !formData.geofence_config.inside.duration_limit_in_sec &&
      !formData.geofence_config.cross.enable
    ) {
      setConfigurationError(
        getLocalizedString("choose_at_least_one_option", "Choose at least one option")
      );
    } else {
      setConfigurationError("");
      if (submitForm) {
        if (idToEdit) {
          if (formik?.values?.event_type_id == 3) {
            if (!formik?.values?.geofence_config?.duration_threshold_in_sec) {
              formik.setFieldError("geofence_config.duration_threshold_in_sec", "Required");
            } else if (
              !Number.isInteger(Number(formik?.values?.geofence_config?.duration_threshold_in_sec))
            ) {
              formik.setFieldError(
                "geofence_config.duration_threshold_in_sec",
                "Should be an integer number greater than or equal to 0"
              );
            } else if (formik?.values?.geofence_config?.duration_threshold_in_sec < 0) {
              formik.setFieldError(
                "geofence_config.duration_threshold_in_sec",
                "Should be an integer number greater than or equal to 0"
              );
            } else {
              updateEventConfiguration({ id: idToEdit, formData });
            }
          } else {
            updateEventConfiguration({ id: idToEdit, formData });
          }
        } else {
          if (formik?.values?.event_type_id == 3) {
            if (!formik?.values?.geofence_config?.duration_threshold_in_sec) {
              formik.setFieldError("geofence_config.duration_threshold_in_sec", "Required");
            } else if (
              !Number.isInteger(Number(formik?.values?.geofence_config?.duration_threshold_in_sec))
            ) {
              formik.setFieldError(
                "geofence_config.duration_threshold_in_sec",
                "Should be an integer number greater than or equal to 0"
              );
            } else if (formik?.values?.geofence_config?.duration_threshold_in_sec < 0) {
              formik.setFieldError(
                "geofence_config.duration_threshold_in_sec",
                "Should be an integer number greater than or equal to 0"
              );
            } else {
              createEventConfiguration({ formData });
            }
          } else {
            createEventConfiguration({ formData });
          }
        }
      } else {
        setShowConfirmationModal(true);
      }
    }
  };

  const handleTypeChange = (value) => {
    formik.setFieldValue("min_idle_time", 30);
    formik.setFieldTouched("min_idle_time", false);
    formik.setFieldValue("speed_limit", "");
    formik.setFieldTouched("speed_limit", false);
    formik.setFieldValue("duration_threashold", "");
    formik.setFieldTouched("duration_threashold", false);
    formik.setFieldValue("duration_threshold_in_sec", 0);
    formik.setFieldTouched("duration_threshold_in_sec", false);
    formik.setFieldValue("max_idle_time", 300);
    formik.setFieldTouched("max_idle_time", false);
    formik.setFieldValue("geofence_config.duration_threshold_in_sec", "0");
    formik.setFieldTouched("geofence_config.duration_threshold_in_sec", false);
    formik.setFieldValue("event_type_id", value);
  };

  const handleShowConfirmation = () => {
    return (
      <div className="confirmation-message">
        <span>
          <SvgIcon name="alert" className="confirmation-message_icon" />
        </span>
        <p className="text-center">{showConfirmationModalMessage}</p>
      </div>
    );
  };

  //Return the type of variable chosen
  const getStartStopConditionTypes = (value = formik?.values?.variable_id) => {
    let typeChosen = "";
    if (value) {
      variables?.data?.forEach((variable) => {
        if (variable?.id == value) {
          if (variable?.variable_type === "Number" || variable?.variable_type === "Timestamp") {
            typeChosen = "numberTimestampVariableTypeCondition";
          } else if (variable?.variable_type === "Text") {
            typeChosen = "textVariableTypeCondition";
          } else if (variable?.variable_type === "Switch") {
            typeChosen = "switchVariableTypeCondition";
          } else if (variable?.variable_type === "Date") {
            typeChosen = "dateVariableTypeCondition";
          }
        }
      });
      return typeChosen;
    }
  };

  // Return the variable type condition list depending on the variable type chosen
  const getConditionTypesList = () => {
    if (
      getStartStopConditionTypes() === "numberTimestampVariableTypeCondition" ||
      getStartStopConditionTypes() === "dateVariableTypeCondition"
    ) {
      return numberTimestampDateVariableTypeCondition;
    } else if (getStartStopConditionTypes() === "textVariableTypeCondition") {
      return textVariableTypeCondition;
    } else if (getStartStopConditionTypes() === "switchVariableTypeCondition") {
      return switchVariableTypeCondition;
    }
  };

  const handleVariableChange = (value) => {
    formik.setFieldValue("variable_id", value);
    setVariableSearchKey("");

    //Clear the previous start stop value and store the default value depending on the variable type chosen
    if (getStartStopConditionTypes(value) === "switchVariableTypeCondition") {
      formik?.setFieldValue("start_value", true);
      formik?.setFieldValue("stop_value", true);
    } else if (
      getStartStopConditionTypes(value) === "numberTimestampVariableTypeCondition" ||
      getStartStopConditionTypes(value) === "textVariableTypeCondition"
    ) {
      formik?.setFieldValue("start_value", "");
      formik?.setFieldValue("stop_value", "");
    } else if (getStartStopConditionTypes(value) === "dateVariableTypeCondition") {
      formik?.setFieldValue("start_value", null);
      formik?.setFieldValue("stop_value", null);
    }
  };

  const handleStartCondition = (value) => {
    formik.setFieldValue("start_condition", value);

    if (getStartStopConditionTypes() === "switchVariableTypeCondition") {
      formik.setFieldValue("start_value", true);
    } else if (getStartStopConditionTypes() === "dateVariableTypeCondition") {
      formik.setFieldValue("start_value", null);
    } else {
      formik.setFieldValue("start_value", "");
    }

    formik.setFieldValue("start_status", "");
    formik.setFieldValue("trigger_interval", 0);
  };

  const handleStopCondition = (value) => {
    formik.setFieldValue("stop_condition", value);
    if (getStartStopConditionTypes() === "switchVariableTypeCondition") {
      formik.setFieldValue("stop_value", true);
    } else if (getStartStopConditionTypes() === "dateVariableTypeCondition") {
      formik.setFieldValue("stop_value", null);
    } else {
      formik.setFieldValue("stop_value", "");
    }

    formik.setFieldValue("stop_status", "");
    formik.setFieldValue("stop_interval", 0);
  };

  // In custom event if the condition is present only then the value field should be 0
  useEffect(() => {
    if (formik?.values?.start_condition === "is_present_only") {
      formik.setFieldValue("start_value", "0");
    }
    if (formik?.values?.stop_condition === "is_present_only") {
      formik.setFieldValue("stop_value", "0");
    }
  }, [formik?.values?.start_condition, formik?.values?.stop_condition]);

  return (
    <FormContainer
      resourceName={getLocalizedString("event", "Event")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QICustomSelect
        label={getLocalizedString("type", "Type")}
        {...formik.getFieldProps("event_type_id")}
        onChange={(value) => handleTypeChange(value)}
        error={formik.touched.event_type_id && formik.errors.event_type_id}
        className="narrow-customselect-dropdown"
        autoHeightMax="149px"
        disabled={idToEdit}
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {eventTypes?.map((value, index) => (
            <li value={value.id} key={index}>
              {value.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>
      {formik?.values?.event_type_id == events.Idling && (
        //For event type idling
        <>
          <Components.QIInput
            label={getLocalizedString("minimum_idle_time_in_secs", "Minimum Idle Time(in secs)")}
            {...formik.getFieldProps("min_idle_time")}
            error={formik.touched.min_idle_time && formik.errors.min_idle_time}
          />
        </>
      )}
      {formik?.values?.event_type_id == events.Speeding && (
        //For event type speeding
        <>
          <div className="flex justify-between country-code-and-number">
            <Components.QIInput
              label={getLocalizedString("speed_limit", "Speed Limit")}
              className="country-code-and-number_item2"
              customLabelClass="phone-number"
              customWrapperClass="phone-number-wrapper"
              {...formik.getFieldProps("speed_limit")}
              error={formik.touched.speed_limit && formik.errors.speed_limit}
            />

            <Components.QICustomSelect
              label={getLocalizedString("unit", "Unit")}
              className="country-code-and-number_item1"
              {...formik.getFieldProps("unit")}
              onChange={(value) => formik.setFieldValue("unit", value)}
            >
              <>
                {unitTypes?.map((value, index) => (
                  <li value={value.id} key={index}>
                    {value.name}
                  </li>
                ))}
              </>
            </Components.QICustomSelect>
          </div>

          <Components.QIInput
            label={getLocalizedString("duration_threshold_in_secs", "Duration Threshold(in secs)")}
            {...formik.getFieldProps("duration_threashold")}
            error={formik.touched.duration_threashold && formik.errors.duration_threashold}
          />
        </>
      )}
      {formik?.values?.event_type_id == events.Geofence && (
        //For event type geofence
        <>
          <Components.QICustomSelect
            label={getLocalizedString("geofences", "Geofences")}
            {...formik.getFieldProps("geofence_id")}
            onChange={(value) => {
              formik.setFieldValue("geofence_id", value);
              setGeofenceSearchKey("");
            }}
            onSearch={setGeofenceSearchKey}
            error={formik.touched.geofence_id && formik.errors.geofence_id}
            labelClassName="geofence"
            className="event-configuration-geofence-wrapper"
          >
            <>
              <li value="">{getLocalizedString("select", "Select")}</li>
              {geofences?.data?.map((value, index) => (
                <li value={value.id} key={index}>
                  {value.name}
                </li>
              ))}
            </>
          </Components.QICustomSelect>
          <div
            className={`configurations-wrapper qi-form-element ${
              configurationError && "configurations-wrapper-error"
            }`}
          >
            <label className="qi-input_label">
              {getLocalizedString("configurations", "Configurations")}
            </label>
            <div className="w-9/12">
              <Components.QISwitch
                label={getLocalizedString("entry", "Entry")}
                value={formik.values?.geofence_config?.entry?.enable}
                onChange={() => {
                  formik.setFieldValue(
                    "geofence_config.entry.enable",
                    !formik.values?.geofence_config?.entry?.enable
                  );
                }}
                error={
                  formik.touched.geofence_config?.entry?.enable &&
                  formik.errors.geofence_config?.entry?.enable
                }
              />
              <Components.QISwitch
                label={getLocalizedString("exit", "Exit")}
                value={formik.values?.geofence_config?.exit?.enable}
                onChange={() => {
                  formik.setFieldValue(
                    "geofence_config.exit.enable",
                    !formik.values?.geofence_config?.exit.enable
                  );
                }}
                error={
                  formik.touched.geofence_config?.exit?.enable &&
                  formik.errors.geofence_config?.exit?.enable
                }
              />
              <Components.QISwitch
                label={getLocalizedString("inside", "Inside")}
                value={formik.values?.geofence_config?.inside?.enable}
                onChange={() => {
                  formik.setFieldValue(
                    "geofence_config.inside.enable",
                    !formik.values?.geofence_config?.inside?.enable
                  );
                }}
                error={
                  formik.touched?.geofence_config?.inside?.enable &&
                  formik.errors?.geofence_config?.inside?.enable
                }
              />
              <div className="speed-limit-and-unit">
                <Components.QIInput
                  label={getLocalizedString("speed_limit", "Speed Limit")}
                  className="speed-limit-and-unit_item1"
                  customLabelClass="speed-limit"
                  customWrapperClass="speed-limit-wrapper"
                  disabled={
                    formik?.values?.geofence_config?.entry?.enable &&
                    formik?.values?.geofence_config?.exit?.enable
                      ? false
                      : true
                  }
                  {...formik.getFieldProps("geofence_config.inside.speed_limit")}
                  error={
                    formik?.touched?.geofence_config?.inside?.speed_limit &&
                    formik.errors?.geofence_config?.inside?.speed_limit
                  }
                />
                <Components.QICustomSelect
                  label={getLocalizedString("unit", "Unit")}
                  className="speed-limit-and-unit_item2"
                  labelClassName="unit-dropdown"
                  disabled={
                    formik?.values?.geofence_config?.inside?.speed_limit &&
                    formik?.values?.geofence_config?.entry?.enable &&
                    formik?.values?.geofence_config?.exit?.enable
                      ? false
                      : true
                  }
                  {...formik.getFieldProps("geofence_config.inside.unit")}
                  onChange={(value) => formik.setFieldValue("geofence_config.inside.unit", value)}
                  error={
                    formik?.touched?.geofence_config?.inside?.unit &&
                    formik?.errors?.geofence_config?.inside?.unit
                  }
                >
                  <>
                    {unitTypes?.map((value, index) => (
                      <li value={value.id} key={index}>
                        {value.name}
                      </li>
                    ))}
                  </>
                </Components.QICustomSelect>
              </div>
              <Components.QIInput
                label={getLocalizedString("time_limit", "Time Limit(in secs)")}
                className="time-limit"
                disabled={
                  formik?.values?.geofence_config?.entry?.enable &&
                  formik?.values?.geofence_config?.exit?.enable
                    ? false
                    : true
                }
                {...formik.getFieldProps("geofence_config.inside.duration_limit_in_sec")}
                error={
                  formik?.touched?.geofence_config?.inside?.duration_limit_in_sec &&
                  formik.errors?.geofence_config?.inside?.duration_limit_in_sec
                }
              />
              {/* <Components.QISwitch
                label={getLocalizedString("outside", "Outside")}
                value={formik.values?.geofence_config?.outside?.enable}
                onChange={() => {
                  formik.setFieldValue(
                    "geofence_config.outside.enable",
                    !formik.values?.geofence_config?.outside?.enable
                  );
                }}
                error={
                  formik.touched?.geofence_config?.outside?.enable &&
                  formik.errors?.geofence_config?.outside?.enable
                }
              /> */}
              <Components.QISwitch
                label={getLocalizedString("cross", "Cross")}
                value={formik.values?.geofence_config?.cross?.enable}
                onChange={() => {
                  formik.setFieldValue(
                    "geofence_config.cross.enable",
                    !formik.values?.geofence_config?.cross?.enable
                  );
                }}
                error={
                  formik.touched?.geofence_config?.cross?.enable &&
                  formik.errors?.geofence_config?.cross?.enable
                }
              />
              <Components.QIInput
                label={getLocalizedString(
                  "duration_threshold_in_secs",
                  "Duration Threshold(in secs)"
                )}
                {...formik.getFieldProps("geofence_config.duration_threshold_in_sec")}
                error={
                  formik?.touched?.geofence_config?.duration_threshold_in_sec &&
                  formik?.errors?.geofence_config?.duration_threshold_in_sec
                }
              />
              {configurationError && <p className="configurations-error">{configurationError}</p>}
            </div>
          </div>
        </>
      )}
      {formik?.values?.event_type_id == events.Battery && (
        //For event type low battery
        <>
          <Components.QICustomSelect
            label={getLocalizedString("battery_type", "Battery Type")}
            className="country-code-and-number_item1"
            {...formik.getFieldProps("battery_type")}
            onChange={(value) => formik.setFieldValue("battery_type", value)}
            error={formik?.touched?.battery_type && formik?.errors?.battery_type}
          >
            <>
              {batteryTypes?.map((value, index) => (
                <li value={value.id} key={index}>
                  {value.name}
                </li>
              ))}
            </>
          </Components.QICustomSelect>
          <div className="flex justify-between country-code-and-number">
            <Components.QIInput
              label={getLocalizedString("threshold", "Threshold")}
              className="country-code-and-number_item2"
              customLabelClass="phone-number"
              customWrapperClass="phone-number-wrapper"
              {...formik.getFieldProps("unit_value")}
              error={formik?.touched?.unit_value && formik?.errors?.unit_value}
            />

            <Components.QICustomSelect
              label={getLocalizedString("unit", "Unit")}
              className="country-code-and-number_item1"
              {...formik.getFieldProps("unit")}
              onChange={(value) => formik.setFieldValue("unit", value)}
              error={formik?.touched?.unit && formik?.errors?.unit}
            >
              <>
                {batteryUnitTypes?.map((value, index) => (
                  <li value={value.id} key={index}>
                    {value.name}
                  </li>
                ))}
              </>
            </Components.QICustomSelect>
          </div>
        </>
      )}

      {formik?.values?.event_type_id == events.Offline && (
        //For event type offline
        <Components.QIInput
          label={getLocalizedString("duration_threshold_in_secs", "Duration Threshold(in secs)")}
          {...formik.getFieldProps("duration_threashold")}
          error={formik.touched.duration_threashold && formik.errors.duration_threashold}
        />
      )}

      {formik?.values?.event_type_id == events.Battery && (
        //For event types idling, speeding and low battery
        <Components.QIInput
          label={getLocalizedString(
            "report_interval_time_in_secs",
            "Report Interval Time(in secs)"
          )}
          {...formik.getFieldProps("report_interval_time")}
          error={formik.touched.report_interval_time && formik.errors.report_interval_time}
        />
      )}

      {formik?.values?.event_type_id == events.Custom && (
        <>
          <Components.QICustomSelect
            label={getLocalizedString("category", "Category")}
            {...formik.getFieldProps("category")}
            onChange={(value) => formik.setFieldValue("category", value)}
            error={formik.touched.category && formik.errors.category}
            className="narrow-customselect-dropdown"
            autoHeightMax="149px"
            disabled={idToEdit}
          >
            {eventConfigurationCustomCategories?.length > 0 ? (
              eventConfigurationCustomCategories?.map((value, index) => (
                <li value={value.id} key={index}>
                  {value.name}
                </li>
              ))
            ) : (
              <li className="no-item-availlable">No Item Available.</li>
            )}
          </Components.QICustomSelect>

          <Components.QICustomSelect
            label={getLocalizedString("variable", "Variable")}
            {...formik.getFieldProps("variable_id")}
            onChange={(value) => handleVariableChange(value)}
            onSearch={setVariableSearchKey}
            error={formik.touched.variable_id && formik.errors.variable_id}
          >
            <>
              <li value="">{getLocalizedString("select", "Select")}</li>
              {variables?.data?.map((value, index) => (
                <li value={value.id} key={index}>
                  {`${value?.display_name}`}
                  <div value={value.id}>
                    <span value={value.id} className="qi-custom-select_dropdown_list_item_chip">
                      <strong value={value.id}>Type: </strong>
                      {value?.variable_type}
                    </span>
                    <span value={value.id} className="qi-custom-select_dropdown_list_item_chip">
                      <strong value={value.id}>Section Name: </strong>
                      {value?.section_name}
                    </span>
                    <span value={value.id} className="qi-custom-select_dropdown_list_item_chip">
                      <strong value={value.id}>Unit: </strong>
                      {value.unit_name}
                    </span>
                  </div>
                </li>
              ))}
            </>
          </Components.QICustomSelect>

          <div className="qi-input credentials">
            <label className="qi-input_label">{getLocalizedString("start", "Start")}</label>
            <div className="w-9/12">
              <Components.QICustomSelect
                label={getLocalizedString("condition", "Condition")}
                {...formik.getFieldProps("start_condition")}
                onChange={(value) => handleStartCondition(value)}
                error={formik.touched.start_condition && formik.errors.start_condition}
                className="narrow-customselect-dropdown"
                autoHeightMax="149px"
                disabled={formik?.values?.variable_id ? false : true}
              >
                <>
                  <li value="">{getLocalizedString("select", "Select")}</li>
                  {getConditionTypesList()?.map((value, index) => (
                    <li value={value.id} key={index}>
                      {value.name}
                    </li>
                  ))}
                </>
              </Components.QICustomSelect>

              {formik?.values?.start_condition !== "is_present_only" &&
                getStartStopConditionTypes() === "numberTimestampVariableTypeCondition" && (
                  <Components.QIInput
                    label={getLocalizedString("value", "Value")}
                    {...formik.getFieldProps("start_value")}
                    error={formik.touched.start_value && formik.errors.start_value}
                    type="number"
                    disabled={formik?.values?.start_condition ? false : true}
                  />
                )}

              {formik?.values?.start_condition !== "is_present_only" &&
                getStartStopConditionTypes() === "textVariableTypeCondition" && (
                  <Components.QIInput
                    label={getLocalizedString("value", "Value")}
                    {...formik.getFieldProps("start_value")}
                    error={formik.touched.start_value && formik.errors.start_value}
                    disabled={formik?.values?.start_condition ? false : true}
                  />
                )}

              {getStartStopConditionTypes() === "dateVariableTypeCondition" &&
                formik?.values?.start_condition !== "is_present_only" && (
                  <Components.QICalendar
                    label={getLocalizedString("date", "Date")}
                    value={formik?.values?.start_value}
                    onChange={(date) => formik?.setFieldValue("start_value", convertToEpoch(date))}
                    error={formik.touched.start_value && formik.errors.start_value}
                    disabled={formik?.values?.start_condition ? false : true}
                  />
                )}

              <Components.QIInput
                label={getLocalizedString("trigger_interval_in_secs", "Trigger Interval(in secs)")}
                {...formik.getFieldProps("trigger_interval")}
                error={formik.touched.trigger_interval && formik.errors.trigger_interval}
                type="number"
                disabled={
                  formik?.values?.variable_id && formik?.values?.start_condition ? false : true
                }
              />

              <Components.QIInput
                label={getLocalizedString("status", "Status")}
                {...formik.getFieldProps("start_status")}
                error={formik.touched.start_status && formik.errors.start_status}
                disabled={
                  formik?.values?.variable_id && formik?.values?.start_condition ? false : true
                }
              />
            </div>
          </div>

          <div className="qi-input credentials">
            <label className="qi-input_label">{getLocalizedString("stop", "Stop")}</label>
            <div className="w-9/12">
              <Components.QICustomSelect
                label={getLocalizedString("condition", "Condition")}
                {...formik.getFieldProps("stop_condition")}
                onChange={(value) => handleStopCondition(value)}
                error={formik.touched.stop_condition && formik.errors.stop_condition}
                className="narrow-customselect-dropdown"
                autoHeightMax="149px"
                disabled={formik?.values?.variable_id ? false : true}
              >
                <>
                  <li value="">{getLocalizedString("select", "Select")}</li>
                  {getConditionTypesList()?.map((value, index) => (
                    <li value={value.id} key={index}>
                      {value.name}
                    </li>
                  ))}
                </>
              </Components.QICustomSelect>

              {formik?.values?.stop_condition !== "is_present_only" &&
                getStartStopConditionTypes() === "numberTimestampVariableTypeCondition" && (
                  <Components.QIInput
                    label={getLocalizedString("value", "Value")}
                    {...formik.getFieldProps("stop_value")}
                    error={formik.touched.stop_value && formik.errors.stop_value}
                    type="number"
                    disabled={formik?.values?.stop_condition ? false : true}
                  />
                )}

              {formik?.values?.stop_condition !== "is_present_only" &&
                getStartStopConditionTypes() === "textVariableTypeCondition" && (
                  <Components.QIInput
                    label={getLocalizedString("value", "Value")}
                    {...formik.getFieldProps("stop_value")}
                    error={formik.touched.stop_value && formik.errors.stop_value}
                    disabled={formik?.values?.stop_condition ? false : true}
                  />
                )}

              {getStartStopConditionTypes() === "dateVariableTypeCondition" &&
                formik?.values?.stop_condition !== "is_present_only" && (
                  <Components.QICalendar
                    label={getLocalizedString("date", "Date")}
                    value={formik?.values?.stop_value}
                    onChange={(date) => formik?.setFieldValue("stop_value", convertToEpoch(date))}
                    disabled={formik?.values?.stop_condition ? false : true}
                  />
                )}

              <Components.QIInput
                label={getLocalizedString("interval_in_secs", "Interval(in secs)")}
                {...formik.getFieldProps("stop_interval")}
                error={formik.touched.stop_interval && formik.errors.stop_interval}
                type="number"
                disabled={
                  formik?.values?.variable_id && formik?.values?.stop_condition ? false : true
                }
              />

              <Components.QIInput
                label={getLocalizedString("status", "Status")}
                {...formik.getFieldProps("stop_status")}
                error={formik.touched.stop_status && formik.errors.stop_status}
                disabled={
                  formik?.values?.variable_id && formik?.values?.stop_condition ? false : true
                }
              />
            </div>
          </div>
        </>
      )}

      {showConfirmationModal && submitForm && (
        <ConfirmationModal
          onOkay={() => {
            setShowConfirmationModal(false);
          }}
          show={showConfirmationModal}
          headerText="Confirmation Alert!"
        >
          {handleShowConfirmation()}
        </ConfirmationModal>
      )}
    </FormContainer>
  );
};
