import moment from "moment";
import { getDuration, calculateDistance, eventsType, getTimeGap, ignitionStatus } from "../utils";
import spriteNative from "../assets/nativeIcons/icons.svg";
import sprite from "../../../assets/icons.svg";
import { getLocalizedString } from "../../../shared/translation";
import { utcToLocalGlobal } from "../../../shared/utility";

export const ListCard = ({
  data,
  cardType,
  onRowClick,
  setHighlight,
  selectedRowId,
  mapData,
  setvehicleData,
}) => {
  const renderCard = data?.map((item, id) => {
    const {
      status,
      plate_number,
      duration,
      start_time,
      end_time,
      events,
      activities,
      source_time,
      device_name_list,
      device_id,
      gps_distance,
      data,
      mode,
      vehicle_manufacturer_model_name,
      vehicle_manufacturer_name,
    } = item;

    let { sourceTime, serverTime, ignition, speed } = mapData
      ? mapData[device_name_list] || mapData[device_id] || {}
      : {};

    const { status: vehicleStatus, activeWithin, activeWithinTooltip } = getTimeGap(sourceTime);
    let ignitionType = ignition === "Unknown" ? "mobile" : "tracker";

    const getEventTypeStatus = () => {
      if (events?.status) {
        return eventsType(events?.status);
      } else return "";
    };

    const getSubType = () => {
      if (events?.sub_type && events?.sub_type !== events?.status) {
        return "(" + eventsType(events?.sub_type) + ")";
      } else return "";
    };

    const getEventName = () => {
      return (
        events?.config?.name ||
        events?.config?.Config?.name ||
        activities?.data?.config?.Config?.name ||
        activities?.data?.config?.name
      );
    };

    const getTitle = (cardType) => {
      if (cardType === "Devices") {
        return `${item?.name} ( ${item?.device_manufacturer_name} - ${item?.device_model_name})`;
      } else if (cardType !== "Activities") {
        return plate_number;
      } else {
        return data?.plate_number;
      }
    };

    return (
      <li
        onClick={() => {
          onRowClick(item, id);
          setHighlight(id);
          try {
            setvehicleData({
              vehicle_manufacturer_model_name: vehicle_manufacturer_model_name,
              vehicle_manufacturer_name: vehicle_manufacturer_name,
            });
          } catch (e) {}
        }}
        className={`card-view-list_item ${
          cardType === "Vehicles" || cardType === "Devices"
            ? selectedRowId === item?.id
              ? "selected"
              : ""
            : selectedRowId === id
            ? "selected"
            : ""
        }`}
      >
        {/* Trips and Activity */}
        {(cardType === "Trips" || cardType === "Activities") && (
          <div className="status">
            {((mode === "TRIP" || mode === "trip") &&
              (data?.status === "Completed" || data?.status === "ended")) ||
            status === "Completed" ||
            status === "ended" ? (
              <span className="status-completed">
                <svg className="icon">
                  <use href={`${sprite}#tick`}></use>
                </svg>
              </span>
            ) : mode === "PARKED" || mode === "parked" ? (
              <span className="status-parked">
                <svg className="icon">
                  <use href={`${sprite}#parking`}></use>
                </svg>
              </span>
            ) : (
              <span className="status-in-progress">
                <svg className="icon">
                  <use href={`${sprite}#time`}></use>
                </svg>
              </span>
            )}
          </div>
        )}
        {/* Vehicles */}
        {(cardType === "Vehicles" || cardType === "Devices") && (
          <div className="ignition-status">
            <>
              <span className="icon-wrapper">
                <svg className="icon device">
                  <use href={`${spriteNative}#${ignitionType}`}></use>
                </svg>
              </span>

              <span className={`${ignitionType === "mobile" && `${status}_mobile`}`}></span>

              {ignitionStatus(ignition)}
            </>
          </div>
        )}

        <div
          className={`${
            cardType === "Vehicles"
              ? "vehicle-id"
              : cardType === "Devices"
              ? "device-id"
              : "card-view-list_item_info"
          }`}
        >
          <div className="item-id truncate" title={getTitle(cardType)}>
            {cardType === "Devices" ? (
              <>
                {item?.name}
                <p>
                  ({item?.device_manufacturer_name} - {item?.device_model_name})
                </p>
              </>
            ) : cardType !== "Activities" ? (
              cardType === "Vehicles" ? (
                plate_number || "-"
              ) : (
                plate_number
              )
            ) : (
              data?.plate_number
            )}
          </div>
          {(cardType === "Trips" || cardType === "Activities") && (
            <div className="trip-and-time">
              <span className="trip-time">
                {getDuration(cardType !== "Activities" ? duration : data?.duration)}
              </span>

              <span className="trip">
                {cardType === "Trips" && calculateDistance(gps_distance)}
                {cardType === "Activities" && mode === "TRIP"
                  ? calculateDistance(data?.gps_distance)
                  : ""}
              </span>
            </div>
          )}
          {cardType === "Events" && (
            <>
              <div className="events-and-status">
                <span className="event truncate" title={eventsType(events?.type) + getSubType()}>
                  {eventsType(events?.type)}
                  {getSubType()}
                </span>
                <span className="event-status truncate" title={getEventTypeStatus()}>
                  {getEventTypeStatus()}
                </span>
              </div>
              <div className="events-and-status">
                <span className="event-status truncate" title={getEventName()}>
                  {getEventName()}
                </span>
              </div>
            </>
          )}
        </div>

        {cardType === "Vehicles" && (
          <div className="speed">
            <div title={`${speed ? speed : "-"}`}>{`${speed ? speed : "-"}`}</div>
          </div>
        )}

        {(cardType === "Vehicles" || cardType === "Devices") && (
          <div className="status">
            <span className={vehicleStatus}></span>
            <span className="latest-data-time">{vehicleStatus}</span>
          </div>
        )}

        <div
          className={`${
            cardType === "Vehicles" || cardType === "Devices" ? "last-received" : "date-and-time"
          }`}
        >
          {cardType === "Trips" && (
            <div>
              <div className="time-stamp trip-start-time">
                {start_time && moment(start_time).format(" DD/MM/YY - hh:mm A")}
              </div>
              <div className="time-stamp trip-end-time">
                {end_time ? moment(end_time).format(" DD/MM/YY - hh:mm A") : "In Progress"}
              </div>
            </div>
          )}
          {cardType === "Activities" && (
            <div>
              <div className="time-stamp trip-start-time">
                {data?.start_time && moment(data?.start_time).format(" DD/MM/YY - hh:mm A")}
              </div>
              <div className="time-stamp trip-end-time">
                {data?.end_time
                  ? moment(data?.end_time).format(" DD/MM/YY - hh:mm A")
                  : "In Progress"}
              </div>
            </div>
          )}
          {cardType === "Events" && (
            <div>
              <div className="time-stamp">
                {moment(source_time).format(" DD/MM/YYYY - hh:mm:ss A")}
              </div>
            </div>
          )}

          {(cardType === "Vehicles" || cardType === "Devices") && (
            <div title={activeWithinTooltip}>{activeWithin}</div>
          )}
        </div>
      </li>
    );
  });
  return <>{renderCard}</>;
};
