import TripsMap from "./TripsMap";
import "./trips.scss";
import { useGetTripsQuery } from "../services";
import { useEffect, useState } from "react";
import moment from "moment";
import { MapViewFlyOut, ListCard } from "../Shared";
import { header } from "./tableHeader";
import { QIDragAndDropDetails } from "../../../components";
import { Dnd, MinimisedTab, useDndContainer, WindowTab } from "../Dnd";
import { displayKeyName, valueFormatter } from "../utils";
import { useWebSocket } from "../../../hooks";
import { extractKeyValuePairs } from "../utils";
import { FMDPStorage } from "../../../shared/helper";
import Scrollbars from "react-custom-scrollbars";
import { useVariableDataQuery } from "../../../hooks/QIVariableDataQuery";
import { getLocalizedString } from "../../../shared/translation";
import { useGetPluginsQuery } from "../../ManagementPortal/services";
import { PluginContainer } from "../../Plugins";
import { useLocation } from "react-router-dom";

export const TripsAlternateContainer = () => {
  const selectedPlateNumbers = FMDPStorage.get("selected-plate-numbers")?.length
    ? FMDPStorage.get("selected-plate-numbers")
    : [""];

  const location = useLocation();
  const routeData = location.state;
  const { variableData } = useVariableDataQuery();
  const [mapProvider, setMapProvider] = useState();
  const [mapboxAccessToken, setMapBoxAccessToken] = useState();
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState();
  const [deviceId, setDeviceId] = useState(null);
  const [highlight, setHighlight] = useState(null);
  const [tripTime, setTripTime] = useState(null);
  const [tripSearch, setTripSearch] = useState(routeData?.plate_number || "");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [deviceData, setDeviceData] = useState(null);
  const [sourceIds, setSourceIds] = useState([]);
  const [mapRef, setMapRef] = useState(null);
  const [trackMapRef, setTrackMapRef] = useState(null);
  const [selectedRow, setSelectedRow] = useState(0);
  // const [selectedVehiclePlate, setselectedVehiclePlate] = useState(null);
  const [showTrack, setShowTrack] = useState(false);

  const [selectedRange, setSelectedRange] = useState({
    startDate: routeData?.start_time || moment().subtract(24, "hours"),
    endDate: routeData?.end_time || moment(),
  });
  const [resetCount, setResetCount] = useState(0);
  const [clientId, setClientId] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [mapExpand, setMapExpand] = useState(false);
  const [showEventsDetails, setShowEventsDetails] = useState(false);
  const [dockAlign, setDockAlign] = useState("false");
  const [maximizeMinimize, setmaximizeMinimize] = useState(false);
  const [currentTab, setCurrentTab] = useState("Map");
  const [clientConfig, setClientConfig] = useState({});

  //Pagination
  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  //Get Client ID & Config
  useEffect(() => {
    const selectedClientId = JSON.parse(localStorage.getItem("selected-app-id"));
    const currentUser = JSON.parse(localStorage.getItem("current-user"))?.client_apps?.find(
      (item) => {
        return item.id === selectedClientId;
      }
    );
    setClientId(currentUser?.client?.id);
    try {
      if (currentUser?.client?.config_json) {
        const mapProvider = JSON.parse(currentUser?.client?.config_json)?.tracking?.web?.map;
        const clientConfig = JSON.parse(currentUser?.client?.config_json);

        if (mapProvider?.provider === "mapbox" && mapProvider?.mapboxAccessToken) {
          setClientConfig(clientConfig);
          setMapProvider("mapbox");
          setMapBoxAccessToken(mapProvider.mapboxAccessToken);
          setGoogleMapsApiKey(null);
        } else if (mapProvider?.provider === "google" && mapProvider?.googleMapsApiKey) {
          setClientConfig(clientConfig);
          setMapProvider("google");
          setMapBoxAccessToken(null);
          setGoogleMapsApiKey(mapProvider.googleMapsApiKey);
        } else {
          setClientConfig(clientConfig);
          setMapProvider("qiMap");
          setMapBoxAccessToken(null);
          setGoogleMapsApiKey(null);
        }
      }
    } catch (e) {
      console.log(e);
    }

    //clean up client id on component unmount
    return () => setClientId(null);
  }, []);

  //clean up calendar value on compoonent unmount
  useEffect(() => {
    return setResetCount((prev) => prev + 1);
  }, []);

  //on page change remvove row selection

  useEffect(() => {
    removeSelection();
  }, [page]);

  useEffect(() => {
    removeSelection();
  }, [selectedRange]);

  const currentUser = FMDPStorage.get("current-user");

  const selectedAppId = FMDPStorage.get("selected-app-id");
  const getFilterList = (type) => {
    const currentAppRoles = currentUser?.client_app_roles;
    let hasAdminRole = false;
    currentAppRoles?.filter((clientData) => {
      if (clientData?.client_app_id === selectedAppId) {
        clientData?.roles?.filter((role) => {
          if (role?.name === "Admin" || role?.name === "Fleet Admin") {
            hasAdminRole = true;
          }
        });
      }
    });
    if (type === "checkAdmin") {
      if (hasAdminRole === false) {
        return false;
      } else {
        return true;
      }
    } else {
      if (hasAdminRole === false && type === "device") {
        return "";
      } else if (hasAdminRole === false && type === "plateNo") {
        return selectedPlateNumbers?.length > 0 ? selectedPlateNumbers : "";
      } else {
        return "";
      }
    }
  };

  //Get Trips data
  const {
    data: tripsData,
    error,
    isLoading,
  } = useGetTripsQuery(
    clientId && {
      isAdmin: getFilterList("checkAdmin"),
      startTime: moment(selectedRange.startDate).valueOf(),
      endTime: moment(selectedRange.endDate).valueOf(),
      // deviceId: getFilterList("device"),
      plate_number: selectedPlateNumbers,
      search: true,
      clientId: clientId,
      page: page - 1,
      per_page: perPage,
      q: tripSearch?.toUpperCase(),
    }
  );

  const removeSelection = () => {
    setSelectedRowId(null);
    setDeviceId(null);
    setTripTime(null);
    setShowEventsDetails(false);
    setDockAlign("cross");
  };

  const onRowClick = (data, id) => {
    if (selectedRowId === id) {
      removeSelection();
    } else {
      setSelectedRowId(id);
      setDeviceId(data?.source_id);
      setTripTime({
        start_time: data?.start_time,
        end_time: data?.end_time || data.last_time,
      });
      setShowEventsDetails(data);
      setDockAlign("split-down");
    }
  };

  //If routed path
  useEffect(() => {
    if (routeData && routeData?.source_id) {
      onRowClick(routeData);
    }
  }, [routeData]);

  const { realTimedata } = useWebSocket(String(clientId), "all", "sub_all");

  const openDeviceModal = () => {
    setShowDetails(false);
    addNewBox(deviceId);
  };

  const removeLayerOnClose = () => {
    try {
      if (mapProvider === "mapbox") {
        // Helper function to remove a Mapbox layer and its source
        const removeLayerAndSource = (id) => {
          if (trackMapRef.getLayer(id)) {
            trackMapRef.removeLayer(id);
          }
          if (trackMapRef.getSource(id)) {
            trackMapRef.removeSource(id);
          }
        };

        // Remove layers and sources specific to Mapbox
        if (mapRef) {
          removeLayerAndSource(`imei-details_${selectedRow - 1}`);
        }
        if (trackMapRef) {
          removeLayerAndSource(`track_${deviceId}`);
        }
      } else if (mapProvider === "google") {
        // Clear all overlays (markers, polylines) specific to Google Maps
        const removeGoogleOverlays = (prefix) => {
          for (let i = 0; i < count; i++) {
            const overlayGroup = window[`${prefix}_${i}_google`];
            if (overlayGroup) {
              overlayGroup.forEach((overlay) => overlay.setMap(null));
              window[`${prefix}_${i}_google`] = null; // Clear reference
            }
          }
        };

        // Remove Google-specific overlays
        if (mapRef) {
          removeGoogleOverlays(`imei-details`);
        }
        if (trackMapRef) {
          removeGoogleOverlays(`track`);
        }
      } else if (mapProvider === "qiMap") {
        // Specific handling for `qiMap`
        mapRef && mapRef.removeLayerSource(`imei-details_${selectedRow - 1}`);
        trackMapRef && trackMapRef.removeLayerSource(`track_${deviceId}`);
      }
    } catch (error) {
      console.error("Error removing layers or overlays:", error);
    }
  };

  const { activeBoxes, addNewBox, removeBox, revertBox, isItemsDragging, handleDragChange } =
    useDndContainer();

  const keyValues = extractKeyValuePairs(showEventsDetails);
  const renderDetails = keyValues.map((pair) => {
    const key = Object.keys(pair)[0];
    let value = pair[key];

    return (
      <>
        {value !== null && !key.startsWith("_") && (
          <li className="details-block_other-data_item">
            <strong title={key} className="details-block_other-data_item_label">
              {getLocalizedString(key, displayKeyName(variableData, key))}
            </strong>
            <span
              title={valueFormatter(key, value, variableData)}
              className="details-block_other-data_item_value"
            >
              {valueFormatter(key, value, variableData)}
            </span>
          </li>
        )}
      </>
    );
  });

  const getListDetails = () => {
    return (
      <section className="details-block_data-container">
        <ul className="details-block_data details-block_other-data">{renderDetails}</ul>
      </section>
    );
  };

  const funSetActive = () => {
    setCurrentTab("Map");
  };

  const showdetailsTabs = (tab) => {
    setCurrentTab(tab);
  };

  const { data: dataPlugins = { data: [], total_count: 0 } } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  const addPlugins = dataPlugins.data.filter(
    (plugin) =>
      plugin.tracker_containers.map((s) => parseInt(s, 10)).includes(2) && plugin.is_enabled
  );
  const isPluginTab = addPlugins.some((plugin) => plugin.name === currentTab);
  const selectedPlugin = addPlugins.find((plugin) => plugin.name == currentTab);

  return (
    <>
      <div className="tracking-trips">
        <div className="tracking-app-main-container">
          <MapViewFlyOut
            data={tripsData?.trips || []}
            resetCount={resetCount}
            setResetCount={setResetCount}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            error={error}
            setDeviceId={setDeviceId}
            highlight={highlight}
            setHighlight={setHighlight}
            setTripTime={setTripTime}
            listType={"Trips"}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            simpleSearch={tripSearch}
            setSimpleSearch={setTripSearch}
            setShowDetails={setShowEventsDetails}
            showDetails={showEventsDetails}
            setDockAlign={setDockAlign}
            setMapExpand={setMapExpand}
            selectedPage={"Trips"}
            title={getLocalizedString("trips", "Trips")}
            header={header}
            pagination={{ ...pagination, count: tripsData?.total_count }}
            isLoading={isLoading}
            search_by_message={getLocalizedString("search_by_plate_no", "Search by Plate No")}
          >
            <Scrollbars>
              <ListCard
                data={tripsData?.trips || []}
                cardType="Trips"
                onRowClick={onRowClick}
                highlight={highlight}
                setHighlight={setHighlight}
                selectedRowId={selectedRowId}
              />
            </Scrollbars>
          </MapViewFlyOut>

          <article className={`tracking-app-main-container_block ${currentTab}`}>
            <nav className="map-view-tabs">
              <ul className="map-and-details_tabs">
                <li>
                  <span
                    className={`map-and-details_tabs_item ${currentTab === "Map" && "active"}`}
                    onClick={funSetActive}
                  >
                    {getLocalizedString("map", "Map")}
                  </span>
                </li>
                {addPlugins &&
                  addPlugins.map((plugin, index) => (
                    <li key={index}>
                      <span
                        className={`map-and-details_tabs_item ${
                          currentTab === plugin.name && "active"
                        }`}
                        onClick={() => showdetailsTabs(plugin.name)}
                      >
                        {getLocalizedString("plugins", plugin.name)}
                      </span>
                    </li>
                  ))}
              </ul>
            </nav>
            <article
              className={`map-and-details events-map ${dockAlign} ${
                maximizeMinimize ? "minimized-details" : ""
              }`}
            >
              {currentTab === "Map" ? (
                <TripsMap
                  dockAlign={dockAlign}
                  mapExpand={mapExpand}
                  deviceId={deviceId}
                  selectedRange={tripTime}
                  maximizeMinimize={maximizeMinimize}
                  mapProvider={mapProvider}
                  mapboxAccessToken={mapboxAccessToken}
                  googleMapsApiKey={googleMapsApiKey}
                />
              ) : isPluginTab ? (
                <PluginContainer selectedPlugin={selectedPlugin} />
              ) : null}

              {showEventsDetails && (
                <article className={`details-block`}>
                  <QIDragAndDropDetails
                    setDetails={setShowEventsDetails}
                    setPopup={openDeviceModal}
                    deviceData={showEventsDetails || {}}
                    removeLayerOnClose={removeLayerOnClose}
                    removeRowSelection={removeSelection}
                    setDockAlign={setDockAlign}
                    dockAlign={dockAlign}
                    deviceId={deviceId}
                    realTimedata={realTimedata}
                    setDeviceId={setDeviceId}
                    showTrack={showTrack}
                    setShowTrack={setShowTrack}
                    headerName={getLocalizedString("plate_number", "Plate Number")}
                    listDetails={getListDetails()}
                    selectedVehiclePlate={showEventsDetails?.plate_number}
                    maximizeMinimize={maximizeMinimize}
                    setmaximizeMinimize={setmaximizeMinimize}
                  />
                </article>
              )}
            </article>
          </article>
          <div>
            <MinimisedTab layoutName="trackingLayout" />
          </div>
        </div>
        <Dnd
          layoutName="trackingLayout"
          isItemsDragging={isItemsDragging}
          handleDragChange={handleDragChange}
          setDetails={setShowDetails}
        >
          {Object.keys(activeBoxes).map((key) => (
            <WindowTab
              key={key}
              id={key}
              label={key}
              {...activeBoxes[key].props}
              onClose={removeBox}
              onMinimise={revertBox}
              setShowDetails={setShowDetails}
              setDockAlign={setDockAlign}
              setDeviceId={setDeviceId}
              setShowTrack={setShowTrack}
              selectedVehiclePlate={showEventsDetails?.plate_number}
              setHighlight={setHighlight}
              removeLayerOnClose={removeLayerOnClose}
              removeRowSelection={removeSelection}
            >
              {getListDetails()}
            </WindowTab>
          ))}
        </Dnd>
      </div>
    </>
  );
};
