import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import {
  useCreateEventConfigurationMutation,
  useUpdateEventConfigurationMutation,
  useGetEventConfigurationQuery,
} from "../services";
import { useForm } from "../../../hooks";
import { TripsFormValidation } from "../validations";
import { mergeObjects } from "../../../shared/helper";
import { planTypes } from "../../../shared/dropdownLists";
import { getLocalizedString } from "../../../shared/translation";

export const TripsForm = ({ idToEdit = null, onClose, setUpdated }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    max_idle_time: 300,
    plan: "",
    min_distance_travelled: 10,
    event_type_id: 2,
    // min_idle_time: 30,
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: TripsFormValidation,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const {
    create: createEventConfiguration,
    update: updateEventConfiguration,
    updateSuccess,
    updateError,
    createSuccess,
  } = useForm({
    createMutation: useCreateEventConfigurationMutation,
    updateMutation: useUpdateEventConfigurationMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const { data = {} } = useGetEventConfigurationQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  //Set minimum distance travelled to null in case of ignition based
  useEffect(() => {
    if (formik.values.plan === planTypes[1].id) {
      formik.setFieldValue("min_distance_travelled", "");
    }
  }, [formik.values.plan]);

  const handleFormSubmit = async (formData) => {
    if (idToEdit) {
      await updateEventConfiguration({ id: idToEdit, formData });
      setUpdated(true);
    } else {
      await createEventConfiguration({ formData });
      setUpdated(true);
    }
  };

  const handlePlanChange = (value) => {
    if (value === "ignition_based") {
      formik.setFieldValue("min_distance_travelled", "");
      formik.setFieldTouched("min_distance_travelled", false);
    } else if (value === "gps_based" || value === "smart") {
      formik.setFieldValue("min_distance_travelled", 10);
      formik.setFieldTouched("min_distance_travelled", false);
    }
    formik.setFieldValue("plan", value);
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("trip", "Trip")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      <Components.QICustomSelect
        label={getLocalizedString("type", "Type")}
        {...formik.getFieldProps("plan")}
        onChange={(value) => handlePlanChange(value)}
        error={formik.touched.plan && formik.errors.plan}
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {planTypes?.map((value, index) => (
            <li value={value.id} key={index}>
              {value.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      {formik?.values?.plan && (
        <>
          {/* <Components.QIInput
            label="Minimum Idle Time(in secs)"
            {...formik.getFieldProps("min_idle_time")}
            type="number"
            error={formik.touched.min_idle_time && formik.errors.min_idle_time}
          /> */}

          <Components.QIInput
            label={getLocalizedString("maximum_idle_time_in_secs", "Maximum Idle Time(in secs)")}
            {...formik.getFieldProps("max_idle_time")}
            type="number"
            error={formik.touched.max_idle_time && formik.errors.max_idle_time}
          />

          <Components.QIInput
            label={getLocalizedString(
              "minimum_distance_travelled_in_metres",
              "Minimum Distance Travelled (in metres)"
            )}
            {...formik.getFieldProps("min_distance_travelled")}
            error={
              formik.values.plan === "gps_based" || formik.values.plan === "smart"
                ? formik.touched.min_distance_travelled && formik.errors.min_distance_travelled
                : ""
            }
            disabled={
              formik.values.plan === "gps_based" || formik.values.plan === "smart" ? "" : "true"
            }
          />
        </>
      )}
    </FormContainer>
  );
};
