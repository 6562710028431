import { useState } from "react";
import { QITable } from "../../../../components";
import {
  extractKeyValuePairs,
  capitalizeAllFirstLetters,
  keyFormatter,
  valueFormatter,
  capitalizeFirstLetter,
  displayKeyName,
  extractKeyValuePairsTrackPoints,
} from "../../utils";
import moment from "moment";
import { PaginationFooter } from "../../../Shared";
import { getLocalizedString } from "../../../../shared/translation";

const TrackPointsTableWrapper = ({
  data,
  selectedColumns,
  pagination,
  isSuccess,
  variableData,
  selectedId,
  tableHandlers = {},
}) => {
  const headers = extractKeyValuePairsTrackPoints(data)?.map((item, id) => {
    const [key] = Object.entries(item)[0];
    return {
      label: displayKeyName(variableData, key),
      id: id,
      nestedValue: true,
      getNestedValue: ({ ...rest }) => {
        let val =
          rest[key] !== null && rest[key] !== undefined
            ? rest[key]
            : getLocalizedString("NA", "NA");

        /* if (
          key === "mcc" ||
          key === "lac" ||
          key === "mnc" ||
          key === "signal_strength" ||
          key === "cid" ||
          key === "lcid"
        ) {
          if (rest?.cell_info) {
            val = rest["cell_info"][0][key] ? String(rest["cell_info"][0][key]) : 0;
          } else {
            val = 0;
          }
        } */
        return (
          <h1 title={valueFormatter(key, val, variableData)}>
            {valueFormatter(key, val, variableData)}
          </h1>
        );
      },
    };
  });

  const filteredHeader = headers
    .filter((item, id) => {
      return selectedColumns.includes(item.id);
    })
    .sort((a, b) => {
      // "Source Time" should always come first
      if (a.label === "Source Time") return -1;
      if (b.label === "Source Time") return 1;

      // Sort by label alphabetically
      return a.label.localeCompare(b.label);
    });

  return (
    <>
      <div className="track-points-table">
        <QITable
          resourceName="Track Points"
          data={data || []}
          isFetching={false}
          isSuccess={true}
          headers={filteredHeader}
          handler={tableHandlers}
          showSelected={true}
          selectedId={selectedId}
        />
        {data.length > 0 && pagination && isSuccess && <PaginationFooter pagination={pagination} />}
      </div>
    </>
  );
};

export default TrackPointsTableWrapper;
