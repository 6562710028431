import React from "react";
import { useGetPluginsQuery, useGetAppsQuery } from "../../ManagementPortal/services";
import { usePagination } from "../../../hooks";
import { NavLink } from "react-router-dom";
import { NativeSvgIcon } from "../../ManagementPortal/Shared";
import { FMDPStorage } from "../../../shared/helper";

export const PluginsLayout = ({ rootPath }) => {
  const userApp = FMDPStorage.get("selected-app");

  const { page, setPage, perPage, pagination } = usePagination();

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  // Get list of apps subscribed by client
  const { data: clientApp = { data: [] }, isSuccess: clientAppSuccess } = useGetAppsQuery();

  const isPluginSupportedByApp = (plugin) =>
    plugin.permission.map((app_id) => clientApp.data[app_id - 1]?.name
      .replace(/\s+/g, '')).includes(userApp);

  const pluginLinks = data.data
    .filter((value, index, self) => index === self.findIndex((t) => t.path === value.path))
    .filter((plugin) => isPluginSupportedByApp(plugin))
    .map((plugin) => ({
      label: plugin.name,
      path: `plugin/${plugin.path}`,
      icon: plugin?.icon || "dashboard",
    }));

  return (
    <>
      {pluginLinks.map((plugin, index) => (
        <NavLink key={index} to={`/${rootPath}/${plugin.path}`} className={`qi-nav-bar_item `}>
          <NativeSvgIcon name={`${plugin.icon}`} />
          <div className="qi-nav-bar_item_info">
            <span className="">{plugin.label}</span>
          </div>
        </NavLink>
      ))}
    </>
  );
};
