import { useEffect, useState } from "react";
import { QIMapLoader } from "../../../components";
import { Icon, Style, Stroke } from "ol/style.js";
import { locationImg, endPointMarker, startPointMarker } from "../assets";
import { removeDuplicates } from "../utils";
import { useGetVehicleRoutingQuery } from "../services";
import moment from "moment";
import mapboxgl from "mapbox-gl";

/**
 * Component for displaying vehicle trips on a map.
 * @param {Object} props - Component props.
 * @param {string} props.deviceId - ID of the device/vehicle.
 * @param {Object} props.selectedRange - Selected time range for data retrieval.
 */

const TripsMap = ({
  deviceId,
  selectedRange,
  mapExpand,
  dockAlign,
  maximizeMinimize,
  refreshMap,
  mapProvider,
  mapboxAccessToken,
  googleMapsApiKey,
  listCount,
}) => {
  const [tripMapRef, setTripMapRef] = useState(null);
  //Sets new map everytime
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [mapTrackData, setmapTrackData] = useState([]);
  const [totalCount, setTotalCount] = useState(200);

  // Reset map data when selectedRange or deviceId changes
  useEffect(() => {
    setPage(0);
    setmapTrackData([]);
  }, [selectedRange, deviceId]);

  // Reset map data
  useEffect(() => {
    setmapTrackData([]);
  }, [refreshMap]);

  // Fetch trip map data using the custom hook
  const {
    data: resData,
    error,
    isLoading,
  } = useGetVehicleRoutingQuery(
    deviceId && {
      deviceId,
      per_page: totalCount,
      page,
      start_time: moment(selectedRange?.startDate || selectedRange?.start_time).valueOf(),
      end_time: moment(selectedRange?.endDate || selectedRange?.end_time).valueOf(),
      isTime: true,
    },
    // { refetchOnMountOrArgChange: true },
    { skip: listCount === 0 }
  );

  const showAddress = (device_data) => {
    if (device_data?.gps?.position?.address) {
      return `${device_data?.gps?.position?.address?.city},${device_data?.gps?.position?.address?.region},${device_data?.gps?.position?.address?.country}`;
    } else {
      return "No Recorded Data";
    }
  };

  // Collect and process map data
  useEffect(() => {
    try {
      if (deviceId) {
        if (resData && page <= Math.round(resData?.total_count / totalCount)) {
          resData?.data.forEach((device_data) => {
            if (device_data?.gps) {
              let h = {
                source_id: device_data?.source_id,
                lat: device_data?.gps?.position?.lat,
                lng: device_data?.gps?.position?.lng,
                direction: device_data?.velocity?.direction,
                speed: device_data?.velocity?.speed,
                "010d_vehicle_speed": device_data?.obd?.["010d_vehicle_speed"],
                address: showAddress(device_data),
              };
              setmapTrackData((prev) => [...prev, h]);
            }
          });
          setPage((c) => c + 1);
        }
      }
    } catch (e) {}
  }, [resData, selectedRange]);

  useEffect(() => {
    if (mapProvider === "qiMap") {
      try {
        if (tripMapRef && mapTrackData.length && deviceId) {
          deviceId && tripMapRef.removeLayerSource(`track_${count - 1}`);
          let layerName = `track_${count}`;

          tripMapRef.addLayer({ name: layerName });
          // Draw the last and first points of the track with specific styles
          tripMapRef.drawTrack({
            layerName: layerName,
            fitWithinView: true,
            trackType: "point",
            data: [{ coordinates: [mapTrackData[0]] }],
            style: {
              point: {
                style: (feature) => {
                  return new Style({
                    image: new Icon({
                      color: "#eaff00",
                      crossOrigin: "anonymous",
                      src: endPointMarker,
                      imgSize: [44, 44],
                      /* rotation: feature.direction, */
                    }),
                  });
                },
              },
            },
          });
          tripMapRef.drawTrack({
            layerName: layerName,
            fitWithinView: true,
            trackType: "point",
            data: [{ coordinates: [mapTrackData[mapTrackData.length - 1]] }],
            style: {
              point: {
                style: (feature) => {
                  return new Style({
                    image: new Icon({
                      color: "#0767D0",
                      crossOrigin: "anonymous",
                      src: startPointMarker,
                      imgSize: [36, 36],
                      /* rotation: feature.direction, */
                    }),
                  });
                },
              },
            },
          });
          // Draw the track line connecting all points
          tripMapRef.drawTrack({
            layerName: layerName,
            fitWithinView: true,
            trackType: "line",
            data: [{ coordinates: removeDuplicates(mapTrackData) }],
            style: {
              line: {
                style: (feature) => {
                  return new Style({
                    stroke: new Stroke({
                      color: "#0f53ff",
                      width: 5,
                    }),
                  });
                },
              },
            },
            tooltip: {
              showOn: "hover",
              content: (feature) => {
                let { coordinate } = feature.get("data");
                return `
                <div>Address: ${coordinate?.address}</div>
                <div>Latitude: ${Number(coordinate?.lat).toFixed(5)}</div>
                <div> Longitude: ${Number(coordinate?.lng).toFixed(5)} </div>
                <div>Direction: ${coordinate?.direction}</div>
                <div>Speed: ${Number(coordinate?.speed * 3.6).toFixed(1)} km/h</div>
                `;
              },
            },
          });
          tripMapRef.performFit(layerName);
          setCount((c) => c + 1);
        } else {
          tripMapRef?.removeLayer(`track_${count - 1}`);
          tripMapRef?.resetZoomLevel();
        }
      } catch (e) {}
    }
    if (mapProvider === "mapbox") {
      try {
        // Maintain references to the layers and markers
        let currentLayerIds = [];

        // Helper function to remove a layer and its source
        const removeLayerAndSource = (id) => {
          if (tripMapRef.getLayer(id)) {
            tripMapRef.removeLayer(id);
          }
          if (tripMapRef.getSource(id)) {
            tripMapRef.removeSource(id);
          }
        };

        // Function to clear all previous layers and sources
        const clearPreviousLayers = () => {
          currentLayerIds.forEach((layerId) => {
            removeLayerAndSource(layerId);
          });
          currentLayerIds = [];
        };

        // Function to clear previous markers
        const clearPreviousMarkers = () => {
          if (window.startMarker) {
            window.startMarker.remove();
            window.startMarker = null;
          }
          if (window.endMarker) {
            window.endMarker.remove();
            window.endMarker = null;
          }
        };

        if (tripMapRef && mapTrackData.length && deviceId) {
          const layerName = `track_${count}`;

          // Clear previous layers and markers
          clearPreviousLayers();
          clearPreviousMarkers();

          // Add track line as a source
          tripMapRef.addSource(`${layerName}_line`, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: mapTrackData.map((point) => [point.lng, point.lat]),
              },
            },
          });

          tripMapRef.addLayer({
            id: `${layerName}_line`,
            type: "line",
            source: `${layerName}_line`,
            paint: {
              "line-color": "#000", // Black line
              "line-width": 3,
            },
          });

          // Track current layer ID for future cleanup
          currentLayerIds.push(`${layerName}_line`);

          // Fit the map to the track bounds
          const bounds = mapTrackData.reduce(
            (bounds, coord) => bounds.extend([coord.lng, coord.lat]),
            new mapboxgl.LngLatBounds(
              [mapTrackData[0].lng, mapTrackData[0].lat],
              [mapTrackData[0].lng, mapTrackData[0].lat]
            )
          );

          tripMapRef.fitBounds(bounds, { padding: 20 });

          // Add start and end markers
          window.startMarker = new mapboxgl.Marker()
            .setLngLat([mapTrackData[0].lng, mapTrackData[0].lat])
            .addTo(tripMapRef);

          window.endMarker = new mapboxgl.Marker()
            .setLngLat([
              mapTrackData[mapTrackData.length - 1].lng,
              mapTrackData[mapTrackData.length - 1].lat,
            ])
            .addTo(tripMapRef);

          // Increment the count for the next layer
          setCount((c) => c + 1);
        } else {
          // Clear all layers and markers if no valid data
          clearPreviousLayers();
          clearPreviousMarkers();
        }
      } catch (e) {
        console.error("Error:", e);
      }
    }
    if (mapProvider === "google") {
      try {
        if (tripMapRef && mapTrackData.length && deviceId) {
          let layerName = `track_${count}`;

          // Remove previous tracks (if they exist)
          for (let i = 0; i < count; i++) {
            const previousTrack = window[`track_${i}_google`];
            if (previousTrack) {
              previousTrack.forEach((overlay) => overlay.setMap(null));
            }
          }

          // Create start and end markers using the default Google Maps marker
          const startMarker = new google.maps.Marker({
            position: { lat: mapTrackData[0].lat, lng: mapTrackData[0].lng },
            map: tripMapRef,
            // No custom icon, defaults to Google Maps marker
          });

          const endMarker = new google.maps.Marker({
            position: {
              lat: mapTrackData[mapTrackData.length - 1].lat,
              lng: mapTrackData[mapTrackData.length - 1].lng,
            },
            map: tripMapRef,
            // No custom icon, defaults to Google Maps marker
          });

          // Create a polyline using the default Google Maps polyline
          const trackLine = new google.maps.Polyline({
            path: mapTrackData.map((point) => ({
              lat: point.lat,
              lng: point.lng,
            })),
            geodesic: true,
            // Default polyline styling will be used
          });

          trackLine.setMap(tripMapRef);

          // Store the current track
          window[`track_${count}_google`] = [startMarker, endMarker, trackLine];

          // Adjust the map bounds to fit the track
          const bounds = new google.maps.LatLngBounds();
          mapTrackData.forEach((point) =>
            bounds.extend(new google.maps.LatLng(point.lat, point.lng))
          );
          tripMapRef.fitBounds(bounds);

          // Increment the count for the next layer
          setCount((c) => c + 1);
        } else {
          // Clear all previous Google Maps markers and lines
          for (let i = 0; i < count; i++) {
            const previousTrack = window[`track_${i}_google`];
            if (previousTrack) {
              previousTrack.forEach((overlay) => overlay.setMap(null));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [tripMapRef, mapTrackData, deviceId, dockAlign, mapProvider]);

  return (
    <div className={`map-container`}>
      <QIMapLoader
        mapExpand={mapExpand}
        dockAlign={dockAlign}
        setMapRef={setTripMapRef}
        containerId="device-map-container"
        maximizeMinimize={maximizeMinimize}
        mapProvider={mapProvider}
        mapboxAccessToken={mapboxAccessToken}
        googleMapsApiKey={googleMapsApiKey}
      ></QIMapLoader>
    </div>
  );
};

export default TripsMap;
