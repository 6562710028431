import { useState } from "react";
import {
  MPSharedTable,
  MPSharedHeader,
  MPSharedDeleteModal,
  TableDropDownView,
} from "../../Shared";
import { ContainerForm, PluginForm } from "./form";
import { useSearchQuery, usePagination, useDelete } from "../../../../hooks";
import {
  useGetPluginsQuery,
  useDeleteContainerMutation,
  useGetAppsQuery,
  useGetContainersQuery,
} from "../../services";
import { TickOrCross } from "../../../Shared";
import { getLocalizedString } from "../../../../shared/translation";
import { AddCustomPluginContainer } from "../../CustomPluginContainer/CustomPluginContainerManager";

export const ContainersManagerContainer = () => {
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);

  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_path_cont",
  });

  const {
    data: pluginData,
    isFetching,
    isSuccess,
    error,
  } = useGetPluginsQuery({
    page,
    per_page: 500,
  });

  //Get Containers Data
  const { data: newContainers } = useGetContainersQuery({
    page,
    per_page: perPage,
    order_dir: "desc",
    q,
  });

  // Get list of apps subscribed by client
  const { data: clientApp = { data: [] }, isSuccess: clientAppSuccess } = useGetAppsQuery();

  const onEdit = (container) => {
    setShowForm(true);
    setIdToEdit(container.id);
  };

  const onDelete = (container) => {
    setShowDeleteWarning(true);
    setIdToDelete(container.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const headers = [
    { label: "Name", key: "name", className: "date" },
    { label: "Path", key: "path", className: "date" },
    {
      label: "Apps",
      type: "component",
      className: "applications date more",
      component: ({ data }) => {
        return <TableDropDownView data={data?.client_app_names.map((app_name) => app_name)} />;
      },
    },
    {
      label: "Plugins",
      type: "component",
      className: "applications date more",
      component: ({ data }) => {
        return (
          <TableDropDownView
            data={
              pluginData?.data && data?.plugin_ids
                ? pluginData.data
                    .filter((item) => data.plugin_ids.includes(item.id))
                    .map((item) => item.name)
                : []
            }
          />
        );
      },
    },
  ];

  const {
    deleteItem: deleteContainer,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteContainerMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const getDeleteItemName = (id) => {
    const index = newContainers?.data.findIndex((item) => item.id === id);
    return newContainers?.data[index]?.name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          createNewHandler={onCreate}
          groupName={"client_config"}
          heading={getLocalizedString("settings", "Settings")}
          resourceName={getLocalizedString("containers", "Containers")}
          onEdit={onEdit}
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceholder={getLocalizedString(
            "search_by_name_path",
            "Search by name or path"
          )}
        ></MPSharedHeader>
        <MPSharedTable
          resourceName={getLocalizedString("containers", "Containers....")}
          isLoading={isFetching}
          isSuccess={isSuccess}
          error={error}
          headers={headers}
          data={newContainers?.data || []}
          onDelete={onDelete}
          onEdit={onEdit}
          pagination={{
            ...pagination,
            count: newContainers?.total_count,
          }}
          skipClientId
          skipCanEdit
          skipCanDelete
          className="plugins no-tabs"
          auditResource="Container"
          auditKey="path"
        />
      </article>
      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={"plugin"}
        error={deleteErrorMsg}
        onHide={() => {
          setDeleteErrorMsg();
          setShowDeleteWarning(false);
        }}
        itemName={getDeleteItemName(idToDelete)}
        onDelete={() => deleteContainer({ id: idToDelete })}
      />
      {showForm && <ContainerForm idToEdit={idToEdit} onClose={setShowForm} />}
    </>
  );
};
