import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { mergeObjects } from "../../../../shared/helper";
import { useForm, usePagination, useSearchQuery } from "../../../../hooks";
import * as Components from "../../../../components";
import { FormContainer } from "../../Shared";
import {
  useGetPluginsQuery,
  useCreateContainerMutation,
  useGetContainerQuery,
  useUpdateContainerMutation,
} from "../../services";
import { useNavigate } from "react-router-dom";
import { containerFormValidation } from "../../validations";
import { getLocalizedString } from "../../../../shared/translation";

export const ContainerForm = ({ idToEdit = null, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    path: "",
    plugin_ids: [],
    client_app_names: [],
    icon: "",
  });
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: containerFormValidation,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createContainer, update: updateContainer } = useForm({
    createMutation: useCreateContainerMutation,
    updateMutation: useUpdateContainerMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const handleFormSubmit = (formData) => {
    setIsLoading(true);
    let fObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "bundle") {
        fObj.append(key, formData[key]);
      } else {
        if (typeof formData[key] === "object") {
          fObj.append(key, JSON.stringify(formData[key]));
        } else {
          fObj.append(key, formData[key]);
        }
      }
    });

    createContainer({ formData: fObj }).then(() => {
      setIsLoading(false);
    });
  };

  const icons = [
    "device",
    "plugin",
    "vehicle",
    "management",
    "tracking",
    "group",
    "client",
    "user",
    "driver",
    "role",
    "audit-log",
    "backend",
    "geofence",
    "bell",
    "mobile",
    "event",
    "switch",
  ];

  const iconNames = [
    "Mobile",
    "User",
    "Book",
    "Bell",
    "Mobile",
    "Calendar",
    "Video",
    "Paperclip",
    "Camera",
    "Flag",
    "Telephone",
    "Alert",
    "Folder",
    "File",
    "Clock",
  ];

  const { page, setPage, perPage } = usePagination();

  const { q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_path_cont",
  });

  const { data = { data: [], total_count: 0 } } = useGetPluginsQuery({
    page,
    per_page: 500,
    order_by: "plugins.created_at",
    order_dir: "desc",
    q,
  });

  // Fetch the data if edit form
  const { data: selectedContainer } = useGetContainerQuery({ id: idToEdit }, { skip: !idToEdit });

  // Populate Edit Form
  useEffect(() => {
    if (idToEdit && selectedContainer) {
      let tempData = { ...selectedContainer };
      setInitialValues(mergeObjects(formik.initialValues, tempData));
    }
  }, [selectedContainer]);

  const enabledPlugins = data?.data.filter((plugin) => plugin.is_enabled);

  const appNames = ["Management Portal", "Tracking"];

  return (
    <FormContainer
      resourceName={"Custom Plugin Container"}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      isLoading={isLoading}
      idToEdit={idToEdit}
    >
      <Components.QIInput
        label={"Container Name"}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label={"Container Path"}
        {...formik.getFieldProps("path")}
        error={formik.touched.path && formik.errors.path}
      />
      <Components.QIMultiSelectDropDown
        label="Plugins"
        data={enabledPlugins || []}
        selected={formik.values.plugin_ids}
        onChange={(updatedList) => formik.setFieldValue("plugin_ids", updatedList)}
        error={formik.touched.plugins && formik.errors.plugins}
      />
      <Components.QICustomSelect
        label={getLocalizedString("icon", "Icon")}
        {...formik.getFieldProps("icon")}
        value={formik.values.icon}
        onChange={(value) => formik.setFieldValue("icon", value)}
        error={formik.touched.icon && formik.errors.icon}
      >
        {iconNames.map((value, index) => (
          <li value={value} key={index}>
            {value}
          </li>
        ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label={getLocalizedString("apps", "Apps")}
        {...formik.getFieldProps("client_app_names")}
        value={formik.values.client_app_names}
        onChange={(value) => formik.setFieldValue("client_app_names", [value])}
        error={formik.touched.client_app_names && formik.errors.client_app_names}
      >
        {appNames.map((value, index) => (
          <li value={value} key={index}>
            {value}
          </li>
        ))}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
