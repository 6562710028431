import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { mergeObjects } from "../../../../shared/helper";
import { useForm, usePagination, useSearchQuery } from "../../../../hooks";
import * as Components from "../../../../components";
import { FormContainer } from "../../Shared";
import {
  useCreateContainerMutation,
  useGetContainersQuery,
  useGetPluginsQuery,
  useUpdateContainerMutation,
} from "../../services";
import { useNavigate } from "react-router-dom";
import { containerFormValidation } from "../../validations";
import { getLocalizedString } from "../../../../shared/translation";

export const MPEditContainerForm = ({ idToEdit, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    path: "",
    plugins: [],
    isManagementPortal: true,
    icon: "",
  });
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: containerFormValidation,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createContainer, update: updateContainer } = useForm({
    createMutation: useCreateContainerMutation,
    updateMutation: useUpdateContainerMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const handleFormSubmit = (formData) => {
    setIsLoading(true);
    let fObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === "bundle") {
        fObj.append(key, formData[key]);
      } else {
        if (typeof formData[key] === "object") {
          fObj.append(key, JSON.stringify(formData[key]));
        } else {
          fObj.append(key, formData[key]);
        }
      }
    });

    updateContainer({ formData: fObj, id: idToEdit }).then(() => {
      setIsLoading(false);
      navigate("/management_portal/container/" + formData.path);
    });
  };

  const { data: newContainers } = useGetContainersQuery({ page: 0, per_page: 500 });
  const selectedContainer = newContainers?.find((container) => container.id === idToEdit);

  useEffect(() => {
    if (idToEdit && selectedContainer) {
      setInitialValues(mergeObjects(initialValues, selectedContainer));
    }
  }, [selectedContainer, idToEdit]);

  const icons = [
    "device",
    "plugin",
    "vehicle",
    "management",
    "tracking",
    "group",
    "client",
    "user",
    "driver",
    "role",
    "audit-log",
    "backend",
    "geofence",
    "bell",
    "mobile",
    "event",
    "switch",
  ];

  const iconNames = [
    "Mobile",
    "User",
    "Book",
    "Bell",
    "Mobile",
    "Calendar",
    "Video",
    "Paperclip",
    "Camera",
    "Flag",
    "Telephone",
    "Alert",
    "Folder",
    "File",
    "Clock",
  ];

  const { page, setPage, perPage } = usePagination();

  const { q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_path_cont",
  });

  const { data = { data: [], total_count: 0 } } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
    q,
  });

  const enabledPlugins = data?.data
    .filter((value, index, self) => index === self.findIndex((t) => t.path === value.path))
    .filter((plugin) => plugin.is_enabled);

  return (
    <FormContainer
      resourceName={"Custom Plugin Container"}
      closeForm={() => onClose(false)}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
      isLoading={isLoading}
    >
      <Components.QIInput
        label={"Container Name"}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label={"Container Path"}
        {...formik.getFieldProps("path")}
        error={formik.touched.path && formik.errors.path}
      />
      <Components.QIMultiSelectDropDown
        label="Plugins"
        data={enabledPlugins || []}
        selected={formik.values.plugins}
        onChange={(updatedList) => formik.setFieldValue("plugins", updatedList)}
        error={formik.touched.plugins && formik.errors.plugins}
      />
      <Components.QICustomSelect
        label={getLocalizedString("icon", "Icon")}
        {...formik.getFieldProps("icon")}
        onChange={(value) => formik.setFieldValue("icon", value)}
        error={formik.touched.icon && formik.errors.icon}
      >
        {iconNames.map((value, index) => (
          <li value={value} key={index}>
            {value}
          </li>
        ))}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
