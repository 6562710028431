import { QISpinner, QIErrorBoundary } from "../../../components";
import { EventsTableWrapper } from "./EventsTableWrapper";
import { header } from "./tableHeader";
import { removeDuplicates } from "../utils";
import { NoData } from "../../Shared";

const EventList = ({
  data,
  resetCount,
  selectedRange,
  setSelectedRange,
  error,
  listData,
  setDeviceId = () => {},
  setHighlight,
  highlight,
  setMapPoint = () => {},
  listType,
  setResetCount,
  page,
  perPage,
  setPage,
  setPerPage,
  setMapExpand,
  showEventsDetails,
  setDockAlign = () => {},
  eventSearch,
  setEventSearch,
  isSuccess,
  isFetching,
  selectedId,
}) => {
  //Pagination
  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  //Handle Row Selection
  const handleRowClick = (data) => {
    setDeviceId(data?.source_id);
    setMapPoint(data?.gps?.position);
  };

  const message = {
    header: error?.data?.message,
  };

  return (
    <>
      {error && <QIErrorBoundary message={message} />}
      {isFetching && (
        <div className="no-data">
          <QISpinner size="50px" />
        </div>
      )}
      {!isFetching && isSuccess && data?.events?.length ? (
        <div className="map-view-events">
          <EventsTableWrapper
            data={removeDuplicates(data?.events || [])}
            header={header}
            pagination={{ ...pagination, count: data?.total_count }}
            error={error}
            isSuccess={true}
            tableHandlers={{
              rowHandler: handleRowClick,
            }}
            highlight={highlight}
            setHighlight={setHighlight}
            listType={listType}
            selectedId={selectedId}
          />
        </div>
      ) : (
        isSuccess && !error && !isFetching && <NoData />
      )}
    </>
  );
};

export default EventList;
