import React, { useState } from "react";
import { ListCard, MapViewFlyOut } from "../Shared";
import { useGetDevicesQuery } from "../../ManagementPortal/services";
import { useGetVehiclesQuery } from "../../ManagementPortal/services";
import { useEffect } from "react";
import { FMDPStorage } from "../../../shared/helper";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "../../../shared/translation";

import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import Scrollbars from "react-custom-scrollbars";

let list_arr = [];
export const DeviceList = ({
  setDetails,
  setDeviceId = () => {},
  setSourceIds,
  sourceIds,
  showPontOnMap,
  mapData,
  setselectedVehiclePlate,
  setselectedDeviceName,
  setHighlight,
  highlight,
  setDockAlign,
  setShowDetails,
  removeLayerOnClose,
  setMapExpand,
  setShowTrack,
  dockAlign,
  setSimpleSearch,
  simpleSearch,
  currentTab,
  setVehicleData,
  selectedRowId,
  setSelectedRowId,
  vehicleData,
  setCurrentTab,
}) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [q, setQ] = useState("");

  const currentUser = FMDPStorage.get("current-user");
  const selectedAppId = FMDPStorage.get("selected-app-id");
  const dispatch = useDispatch();

  useEffect(() => {
    let obj = {
      name_or_device_id_or_filter_device_ph_with_country_code_cont: simpleSearch.toUpperCase(),
      filter_device_imei_cont: simpleSearch.toUpperCase(),
      m: "or",
    };
    setPage(1);
    setQ(encodeURIComponent(JSON.stringify(obj)));
  }, [simpleSearch]);

  useEffect(() => {
    let obj = {
      name_or_device_id_or_filter_device_ph_with_country_code_cont: simpleSearch.toUpperCase(),
      filter_device_imei_cont: simpleSearch.toUpperCase(),
      m: "or",
    };
    setQ(encodeURIComponent(JSON.stringify(obj)));
  }, [simpleSearch]);

  const getUserId = () => {
    const currentAppRoles = currentUser?.client_app_roles;
    const currentUserId = currentUser?.id;
    let hasAdminRole = false;
    currentAppRoles?.filter((clientData) => {
      if (clientData?.client_app_id === selectedAppId) {
        clientData?.roles?.filter((role) => {
          if (role?.name === "Admin" || role?.name === "Fleet Admin") {
            hasAdminRole = true;
          }
        });
      }
    });
    if (hasAdminRole === false) {
      return currentUserId;
    } else {
      return "";
    }
  };

  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };
  const {
    data: coreDevices = { data: [], total_count: 0 },
    error,
    isLoading,
  } = useGetVehiclesQuery({
    page,
    per_page: perPage,
    q,
    filter_user_id: getUserId(),
  });

  // const { data: devicesList } = useGetVehiclesQuery({
  //   page,
  //   per_page: 10000,
  //   q,
  //   filter_user_id: getUserId(),
  //   device_ids: sourceIds,
  // });

  const queryParams = getUserId()
    ? {
        page,
        per_page: perPage,
        device_ids: sourceIds,
        q,
      }
    : {
        page,
        per_page: perPage,
        q,
      };

  const { data: devicesData = { data: [], total_count: 0 } } = useGetDevicesQuery(queryParams);

  // If the user is non-admin, store the plate numbers which that particular user has
  // useEffect(() => {
  //   if (getUserId()) {
  //     FMDPStorage.set(
  //       "selected-plate-numbers",
  //       devicesList?.data?.map((device) => {
  //         return device?.plate_number;
  //       })
  //     );
  //   }
  // }, [devicesList]);

  // For page change set the map as default tab
  useEffect(() => {
    setCurrentTab("Map");
    setShowTrack(false);
    setDockAlign("cross");
  }, [page]);

  useEffect(() => {
    try {
      list_arr = [];
      coreDevices?.data.forEach((element, index) => {
        let x = { className: element.device_name_list + "_cls" };
        list_arr.push({ ...element, ...x });
      });

      let tempDevices = [];

      coreDevices?.data?.map((device) => {
        if (device?.device_name_list) {
          let temp = device?.device_name_list?.split(",");
          tempDevices = temp.concat(tempDevices);
        }
      });

      devicesData?.data?.map((device) => {
        if (device?.device_id) {
          if (!tempDevices?.includes(device?.device_id)) {
            tempDevices.push(device?.device_id);
          }
        }
      });

      setSourceIds((prev) => tempDevices);
    } catch (e) {}
  }, [coreDevices.data, devicesData?.data]);

  const onRowClick = (data, id) => {
    if (selectedRowId === data?.id) {
      setSelectedRowId(null);
      removeLayerOnClose();
      setDetails(false);
      setDeviceId(null);
      setDockAlign("cross");
      setCurrentTab("Map");
    } else {
      setSelectedRowId(data?.id);
      currentTab !== "TrackPoints" &&
        currentTab !== "Trips" &&
        currentTab !== "Activities" &&
        currentTab !== "Events" &&
        setDetails(true);
      removeLayerOnClose();
      setDeviceId(data?.device_id);
      setselectedVehiclePlate(data?.plate_number);
      setselectedDeviceName(data?.name);
      setCurrentTab("LiveTrack");
      try {
        showPontOnMap(data?.device_name_list, data?.plate_number);
      } catch (e) {}

      dockAlign !== "pop-out" && setDockAlign("split-down");
    }
  };

  useEffect(() => {
    try {
      if (!simpleSearch) {
        setSelectedRowId(null);
        removeLayerOnClose();
        setDetails(false);
        setDeviceId(null);
        setDockAlign("cross");
      }
    } catch (e) {
      console.log(e);
    }
  }, [page, simpleSearch]);

  const getHeaderList = () => {
    return (
      <header className="qi-list-view_header asset-list_header">
        <div
          className="qi-list-view_column ignition-status"
          title={getLocalizedString("ignition", "Ignition")}
        >
          {getLocalizedString("ignition", "Ignition")}
        </div>
        <div
          className="qi-list-view_column device-id"
          title={getLocalizedString("device_id", "Device ID")}
        >
          {getLocalizedString("device_id", "Device ID")}
        </div>
        <div className="qi-list-view_column status" title={getLocalizedString("status", "Status")}>
          {getLocalizedString("status", "Status")}
        </div>
        <div
          className="qi-list-view_column last-received"
          title={getLocalizedString("last_received", "Last Received")}
        >
          {getLocalizedString("last_received", "Last Received")}
        </div>
      </header>
    );
  };

  return (
    <>
      <MapViewFlyOut
        data={devicesData?.data}
        setSelectedRange={setSelectionRange}
        pagination={{ ...pagination, count: devicesData?.total_count }}
        error={error}
        highlight={highlight}
        setHighlight={setHighlight}
        setDockAlign={setDockAlign}
        setShowDetails={setShowDetails}
        removeLayerOnClose={removeLayerOnClose}
        setDeviceId={setDeviceId}
        setShowTrack={setShowTrack}
        simpleSearch={simpleSearch}
        selectedPage={"Vehicles"}
        title={getLocalizedString("devices", "Devices")}
        currentTab={currentTab}
        setselectedVehiclePlate={setselectedVehiclePlate}
        showPontOnMap={showPontOnMap}
        dockAlign={dockAlign}
        setDetails={setDetails}
        setSimpleSearch={setSimpleSearch}
        setMapExpand={setMapExpand}
        listType={"Vehicles"}
        isLoading={isLoading}
        search_by_message={getLocalizedString(
          "search_by_device_name_device_imei",
          "Search by Device Name, Device IMEI"
        )}
        getHeaderList={getHeaderList}
      >
        <Scrollbars>
          <ListCard
            data={devicesData?.data || []}
            cardType="Devices"
            onRowClick={onRowClick}
            highlight={highlight}
            setHighlight={setHighlight}
            selectedRowId={selectedRowId}
            mapData={mapData}
            setvehicleData={setVehicleData}
          />
        </Scrollbars>
      </MapViewFlyOut>
    </>
  );
};
