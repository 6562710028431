import * as Yup from "yup";

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

export function isValidJSON(message) {
  return this.test("is-valid-json", message, function (value) {
    const { path, createError } = this;

    try {
      JSON.parse(JSON.stringify(value));
    } catch {
      return createError({ path, message: message ?? "Not a valid JSON" });
    }
    return true;
  });
}

export function fileSizeValidation(size, message) {
  return this.test("fileSize", message, function (value) {
    const { path, createError } = this;

    if (value && value.image_data) {
      let fileSize = value.image_data.length * (3 / 4) - 1;

      if (fileSize > size) {
        return createError({
          path,
          message: message ?? `Image size should be less than ${size / 1000}kb`,
        });
      }
    } else if (value && value.size > size) {
      return createError({
        path,
        message: message ?? `Image size should be less than ${size / 1000}kb`,
      });
    }

    return true;
  });
}

// To check a valid url
export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

//Function to check the image type supported which accepts some default parameters
export function imageTypeValidation(
  fileExtensions = ["jpg", "png", "jpeg", "gif"],
  fileTypes = ["image/jpg", "image/png", "image/jpeg", "image/gif"],
  message = "Invalid image type"
) {
  return this.test("fileType", message, (value) => {
    // If value is a valid url then allow it
    if (validURL(value)) {
      return true;
    }

    //If image_extension and image_data in value are undefined then no error
    try {
      if (
        value?.image_extension === undefined &&
        value?.image_data === undefined &&
        Object?.keys(value)?.length === 2
      ) {
        value = undefined;
      }
    } catch (error) {}
    let checkField = null;
    //If we dont have image type we expect to have image_extension
    if (value?.image_extension === null || value?.image_extension === undefined) {
      checkField = "type";
    }
    //If we dont have image_extension we expect to have image type
    else if (value?.type === null || value?.type === undefined) {
      checkField = "image_extension";
    }
    //If checkfield is null show error
    try {
      if (
        value === undefined ||
        value === null ||
        fileExtensions.includes(value[checkField]?.toLowerCase()) ||
        fileTypes.includes(value[checkField]?.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  });
}

export function bundleSizeValidation(size, message) {
  return this.test("fileSize", message, function (value) {
    const { path, createError } = this;

    if (value && value.size) {
      let fileSize = value.size;
      let maxSizeInBytes = 1024 * 1024 * size;

      if (fileSize > maxSizeInBytes) {
        return createError({
          path,
          message: message ?? `File size should be less than ${size}MB`,
        });
      }
    }

    return true;
  });
}

//Function to check the bundle type supported which accepts some default parameters
export function bundleTypeValidation(
  fileExtensions = ["js"],
  fileTypes = ["text/javascript"],
  message = "Invalid file type"
) {
  return this.test("fileType", message, (value) => {
    try {
      if (value?.name === undefined || value?.type === undefined) {
        value = undefined;
      }
    } catch (error) {}
    try {
      const extension = value?.name.split(".").pop();
      if (
        value === undefined ||
        value === null ||
        (fileTypes.includes(value?.type?.toLowerCase()) && fileExtensions.includes(extension))
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  });
}

Yup.addMethod(Yup.mixed, "json", isValidJSON);
Yup.addMethod(Yup.mixed, "image", fileSizeValidation);
Yup.addMethod(Yup.mixed, "image_type", imageTypeValidation);
Yup.addMethod(Yup.mixed, "bundle_type", bundleTypeValidation);
Yup.addMethod(Yup.mixed, "bundle_size", bundleSizeValidation);

export const VehicleFormValidation = Yup.object({
  name: Yup.string().required("Required").min(2),
  vehicle_model_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_model_id: Yup.mixed().required("Required"),
  plate_number: Yup.string().required("Required"),
});

export const rolesFormValidation = Yup.object({
  name: Yup.string().required("Required"),
  app_id: Yup.string().required("Required"),
});

export const deviceFormSchema = Yup.object().shape(
  {
    name: Yup.string().required("Required"),
    device_manufacturer_id: Yup.mixed().required("Required"),
    device_model_id: Yup.string().required("Required"),
    device_id: Yup.string()
      .min(5)
      .max(36)
      .matches(
        /^[-/a-zA-Z0-9]+$/,
        "Must be alphanumeric, Only `-` and '/' are allowed special characters,  No spaces allowed"
      )
      .required("Required"),
    simcard_no: Yup.string()
      .min(5, "Should have minimum 5 characters")
      .max(20, "Should have maximum 20 characters")
      .matches(/^\S*$/, "Should not contain blank space")
      .matches(/^[0-9]*$/, "Should only contain numbers"),
    backend_ssl_certificate: Yup.mixed()
      .nullable(true)
      .test("fileFormat", "Unsupported file type(Only .crt and .pem)", (value) => {
        if (value == null) {
          return true;
        }
        let fileParts = value?.name?.split(".") || [];
        if (fileParts.length > 1) {
          let fileExt = fileParts[fileParts.length - 1];
          if (["pem", "crt"].includes(fileExt)) {
            return true;
          }
          return false;
        } else {
          return false;
        }
      }),
  },
  ["country_code", "simcard_phone_no"]
);

export const resellerDeviceFormSchema = Yup.object().shape(
  {
    name: Yup.string().required("Required"),
    device_manufacturer_id: Yup.mixed().required("Required"),
    device_model_id: Yup.string().required("Required"),
    client_id: Yup.string().required("Required"),
    device_id: Yup.string()
      .min(5)
      .max(36)
      .matches(
        /^[-/a-zA-Z0-9]+$/,
        "Must be alphanumeric, Only `-` and '/' are allowed special characters,  No spaces allowed"
      )
      .required("Required"),
    simcard_no: Yup.string()
      .min(5, "Should have minimum 5 characters")
      .max(20, "Should have maximum 20 characters")
      .matches(/^\S*$/, "Should not contain blank space")
      .matches(/^[0-9]*$/, "Should only contain numbers"),
    backend_ssl_certificate: Yup.mixed()
      .nullable(true)
      .test("fileFormat", "Unsupported file type(Only .crt and .pem)", (value) => {
        if (value == null) {
          return true;
        }
        let fileParts = value?.name?.split(".") || [];
        if (fileParts.length > 1) {
          let fileExt = fileParts[fileParts.length - 1];
          if (["pem", "crt"].includes(fileExt)) {
            return true;
          }
          return false;
        } else {
          return false;
        }
      }),
  },
  ["country_code", "simcard_phone_no"]
);

export const userFormSchema = Yup.object().shape(
  {
    email: Yup.string().email("Not a valid email").required("Required"),
    first_name: Yup.string().min(1, "Should have at least 1 character").required("Required"),
    last_name: Yup.string().when("first_name", {
      is: (firstName) => firstName?.length <= 1,
      then: Yup.string().required("Required"),
    }),
    external_driver_rfid: Yup.string().max(36, "ID/RFID must be 36 characters or less"),
    roles: Yup.array().min(1, "At least 1 role is required"),
    password: Yup.string()
      .required("Required")
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        `Password should meet the following criteria:
      <br>
      1. Must be at least 8 characters.
      <br>
      2. Must contain at least 1 Uppercase letter.
      <br>
      3. Must contain at least 1 Lowercase letter.
      <br>
      4. Must contain at least 1 Number.
      <br>
      5. Must contain at least 1 special character (@#$%^&*)`
      ),
    image: Yup.mixed().image_type().image(40000),
    country_code: Yup.string().when("phone_no", {
      is: (simCardPhoneNo) => simCardPhoneNo,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    phone_no: Yup.string().when("country_code", {
      is: (countryCode) => !countryCode,
      then: Yup.string().matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
      otherwise: Yup.string()
        .required("Required")
        .matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
    }),
  },
  ["country_code", "phone_no", "last_name", "first_name"]
);

export const userProfileSchema = Yup.object().shape(
  {
    email: Yup.string().email("Not a valid email").required("Required"),
    first_name: Yup.string().min(1, "Should have at least 1 character").required("Required"),
    last_name: Yup.string().when("first_name", {
      is: (firstName) => firstName?.length <= 1,
      then: Yup.string().required("Required"),
    }),
    image: Yup.mixed().image_type().image(40000),
  },
  ["last_name", "first_name"]
);

export const userEditFormSchema = Yup.object().shape(
  {
    email: Yup.string().email("Not a valid email").required("Required"),
    first_name: Yup.string().min(1, "Should have at least 1 character").required("Required"),
    last_name: Yup.string().when("first_name", {
      is: (firstName) => firstName?.length <= 1,
      then: Yup.string().required("Required"),
    }),
    image: Yup.mixed().image_type().image(40000),
    roles: Yup.array().min(1, "At least 1 role is required"),
    country_code: Yup.string().when("phone_no", {
      is: (simCardPhoneNo) => simCardPhoneNo,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    phone_no: Yup.string().when("country_code", {
      is: (countryCode) => !countryCode,
      then: Yup.string().matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
      otherwise: Yup.string()
        .required("Required")
        .matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
    }),
  },
  ["country_code", "phone_no", "last_name", "first_name"]
);

export const vehicleFormSchema = Yup.object({
  name: Yup.string().required("Required").min(2),
  vehicle_model_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_model_id: Yup.mixed().required("Required"),
  plate_number: Yup.string().required("Required"),
  engine_number: Yup.string().max(64, "Maximum 64 characters are allowed"),
  chasis_number: Yup.string().max(64, "Maximum 64 characters are allowed"),
});

export const vehicleModelFormShema = Yup.object({
  name: Yup.string().required("Required"),
  vehicle_manufacturer_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_model_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_model_variant_id: Yup.mixed().required("Required"),
  year: Yup.number().integer().min(1900).max(3000).typeError("Must be number"),
  capacity: Yup.number().integer().min(1).typeError("Must be number"),
});

export const vehicleModelVariantFormSchema = Yup.object({
  name: Yup.string().required("Required"),
  vehicle_manufacturer_id: Yup.mixed().required("Required"),
  vehicle_manufacturer_model_id: Yup.mixed().required("Required"),
});

export const changePasswordFormValidation = Yup.object({
  current_password: Yup.string().required("Required"),
  password: Yup.string()
    .required("Required")
    .notOneOf([Yup.ref("current_password")], "New password can't be same as current password")
    .matches(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      `Password should meet the following criteria:
    <br>
    1. Must be at least 8 characters.
    <br>
    2. Must contain at least 1 Uppercase letter.
    <br>
    3. Must contain at least 1 Lowercase letter.
    <br>
    4. Must contain at least 1 Number.
    <br>
    5. Must contain at least 1 special character (@#$%^&*)`
    ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match!")
    .required("Required"),
});

export const driverFormValidation = Yup.object().shape(
  {
    email: Yup.string().email("Not a valid email").required("Required"),
    first_name: Yup.string().min(3, "Minimum 3 characters required").required("Required"),
    last_name: Yup.string().required("Required"),
    driver_id: Yup.string().required("Required"),
    country_code: Yup.string().when("phone_no", {
      is: (phoneNo) => phoneNo,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    phone_no: Yup.string().when("country_code", {
      is: (countryCode) => !countryCode,
      then: Yup.string().matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
      otherwise: Yup.string()
        .required("Required")
        .matches(/^[0-9]{3,14}$/, "Not a valid phone number"),
    }),
  },
  ["country_code", "phone_no"]
);

export const pluginFormValidation = (isEditMode) =>
  Yup.object().shape({
    name: Yup.string().min(3, "Minimum 3 characters required").required("Required"),
    path: Yup.string().min(3, "Minimum 3 characters required").required("Required"),
    //author: Yup.string().min(3, "Minimum 3 characters required").required("Required"),
    //version: Yup.string().required("Required"),
    scope: Yup.string().required("Required"),
    module: Yup.string().required("Required"),
    bundle: isEditMode
      ? Yup.mixed() // No validation for bundle in edit mode
      : Yup.mixed().required("Bundle is required").bundle_size(10).bundle_type(),
    loadedBundle: isEditMode ? Yup.string().required("Bundle must be loaded") : Yup.string(),
    //permission: Yup.array().min(1, "Add at least one application"),
  });

export const containerFormValidation = Yup.object().shape({
  name: Yup.string().min(3, "Minimum 3 characters required").required("Required"),
  path: Yup.string().min(3, "Minimum 3 characters required").required("Required"),
  plugins: Yup.array().min(1, "Add atleast one plugin"),
  icon: Yup.string().required("Required"),
});

export const vManModelFormValidation = Yup.object({
  name: Yup.string().required("Required"),
  vehicle_manufacturer_id: Yup.string().required("Required"),
});

export const vManFormValidation = Yup.object({
  name: Yup.string().required("Required"),
});

export const vClassFormValidation = Yup.object({
  name: Yup.string().required("Required"),
});

export const vTypesFormValidation = Yup.object({
  name: Yup.string().required("Required"),
});

export const geofenceFormValidation = Yup.object({
  name: Yup.string().required("Required"),
  json_data: Yup.string()
    .required("Required")
    .test("valid-json", "Invalid JSON", (value) => {
      if (Number.isInteger(Number(value))) {
        return false;
      }
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    }),
});

export const backendsFormSchema = Yup.object({
  name: Yup.string()?.trim().required("Required"),
  backend_type: Yup.string().typeError("Invalid").required("Required"),
  host: Yup.string().required("Required"),
  path: Yup.string()
    .when("protocol", {
      is: (protocol) => protocol === "HTTP" || protocol === "HTTPS" || protocol === "MQTT",
      then: Yup.string().required("Required"),
    })
    .test("params", "Only one params is allowed", (value) => {
      let count = 0;
      for (let i = 0; i < value?.length; i++) {
        if (value[i] == ":") {
          count++;
        }
        if (count > 1) {
          return false;
        }
      }
      return true;
    })
    .test(
      "device_id",
      `Only allowed params : device_id
      <br>
      Format: (Optional Prefix)/:device_id(/optional suffix)
      <br>
      Eg: /fmc130/:device_id/data `,
      (value) => {
        let str = value?.split("/") || [];

        for (let i = 0; i < str.length; i++) {
          if (str[i].includes(":")) {
            if (str[i][0] == ":") {
              let substr = str[i].substring(1);
              if (substr == "device_id") {
                return true;
              }
              return false;
            } else {
              return false;
            }
          }
        }
        return true;
      }
    ),
  timeout: Yup.number()
    .integer("Must be integer")
    .typeError("Must be integer")
    .min(1, "Must be greater than or equal to 1")
    .max(30, "Must be less than or equal to 30")
    .required("Required"),
  maximum_retry_count: Yup.number()
    .when("protocol", {
      is: (protocol) => protocol !== "UDP",
      then: Yup.number().required("Required"),
    })
    .integer("Must be integer")
    .typeError("Must be integer")
    .min(1, "Must be greater than or equal to 1")
    .max(10, "Must be less than or equal to 10"),
  enabled: Yup.bool().typeError("Either true/false"),
  additional_text: Yup.mixed().json("Not a valid json"),
  ssl_certificate: Yup.mixed()
    .nullable(true)
    .test("fileFormat", "Unsupported file type(Only .crt and .pem)", (value) => {
      if (value == null) {
        return true;
      }
      let fileParts = value?.name?.split(".") || [];
      if (fileParts.length > 1) {
        let fileExt = fileParts[fileParts.length - 1];
        if (["pem", "crt"].includes(fileExt)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    }),
  keep_alive_interval: Yup.number().integer().typeError("Must be integer").min(0),
  port: Yup.number("Must be a positive number")
    .when(["backend_type"], {
      is: (backend_type) => backend_type == "TrackSynq",
      then: Yup.number().oneOf([13500], "Must be 13500"),
    })

    .integer()
    .typeError("Must be a valid port number")
    .min(1, "Must be a positive number"),
  event_ids: Yup.array().when("forwarding_option", {
    is: (forwardingOption) => forwardingOption === "events",
    then: Yup.array().min(1, "Required"),
  }),
});

export const clientConfigSchema = Yup.object({
  retention_period: Yup.number("Accept 0 or any positive whole number (decimal not allowed)")
    .integer("Accept 0 or any positive whole number (decimal not allowed)")
    .min(0, "Accept 0 or any positive whole number (decimal not allowed)")
    .typeError("Accept 0 or any positive whole number (decimal not allowed)")
    .max(999999999, "Maximum number exceeded")
    .required("Required"),
  image: Yup.mixed().image_type().image(40000),
});

export const appConfigSchema = Yup.object({
  image: Yup.mixed().image_type().image(40000),
});

export const driverGroupsFormSchema = Yup.object({
  name: Yup.string().required("Required"),
  app_id: Yup.string().required("Required"),
});

export const deviceGroupsFormSchema = Yup.object({
  name: Yup.string().required("Required"),
});

export const userGroupsFormSchema = Yup.object({
  name: Yup.string().required("Required"),
});

export const vehicleGroupsFormSchema = Yup.object({
  name: Yup.string().required("Required"),
});

export const VehicleGroupCsvUploadFormSchema = Yup.object({
  group_id: Yup.string().required("Required"),
  csv_column_mappings: Yup.object().shape({
    manufacturer: Yup.string().required("Required"),
    model: Yup.string().required("Required"),
    specification: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    plate_number: Yup.string().required("Required"),
  }),
  csv_file: Yup.mixed()
    .test("fileFormat", "Unsupported file type(Only .csv)", (value) => {
      if (value == null) {
        return true;
      }
      let fileParts = value?.name?.split(".") || [];
      if (fileParts.length > 1) {
        let fileExt = fileParts[fileParts.length - 1];
        if (["csv"].includes(fileExt)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    })
    .required("Required"),
});

export const DriverGroupCsvUploadFormSchema = Yup.object({
  csv_column_mappings: Yup.object().shape({
    email_id: Yup.string().required("Required"),
  }),
  csv_file: Yup.mixed()
    .test("fileFormat", "Unsupported file type(Only .csv)", (value) => {
      if (value == null) {
        return true;
      }
      let fileParts = value?.name?.split(".") || [];
      if (fileParts.length > 1) {
        let fileExt = fileParts[fileParts.length - 1];
        if (["csv"].includes(fileExt)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    })
    .required("Required"),
});

export const DeviceGroupCsvUploadFormSchema = Yup.object({
  group_id: Yup.string().required("Required"),
  csv_column_mappings: Yup.object().shape({
    manufacturer: Yup.string().required("Required"),
    model: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    imei: Yup.string().required("Required"),
  }),
  csv_file: Yup.mixed()
    .test("fileFormat", "Unsupported file type(Only .csv)", (value) => {
      if (value == null) {
        return true;
      }
      let fileParts = value?.name?.split(".") || [];
      if (fileParts.length > 1) {
        let fileExt = fileParts[fileParts.length - 1];
        if (["csv"].includes(fileExt)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    })
    .required("Required"),
});

export const UserGroupCsvUploadFormSchema = Yup.object({
  csv_column_mappings: Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    country_code: Yup.string().required("Required"),
    phone_number: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    active: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  }),
  csv_file: Yup.mixed()
    .test("fileFormat", "Unsupported file type(Only .csv)", (value) => {
      if (value == null) {
        return true;
      }
      let fileParts = value?.name?.split(".") || [];
      if (fileParts.length > 1) {
        let fileExt = fileParts[fileParts.length - 1];
        if (["csv"].includes(fileExt)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    })
    .required("Required"),
});

export const EventConfigurationsFormValidation = Yup.object().shape(
  {
    name: Yup.string().required("Required"),
    event_type_id: Yup.number().typeError("Invalid").required("Required"),
    category: Yup.mixed().when("event_type_id", {
      is: (type) => type == "6",
      then: Yup.mixed().required("Required"),
    }),
    variable_id: Yup.mixed().when("event_type_id", {
      is: (type) => type == "6",
      then: Yup.mixed().required("Required"),
    }),
    start_condition: Yup.mixed().when("event_type_id", {
      is: (type) => type == "6",
      then: Yup.mixed().required("Required"),
    }),
    start_value: Yup.mixed().when("event_type_id", {
      is: (type) => type == "6",
      then: Yup.mixed().required("Required"),
    }),
    min_idle_time: Yup.mixed("").when("event_type_id", {
      is: (type) => type == "0" || type == "2",
      then: Yup.number("Should be an integer number greater than or equal to 30")
        .integer("Should be an integer number greater than or equal to 30")
        .min(30, "Should be an integer number greater than or equal to 30")
        .typeError("Should be an integer number greater than or equal to 30")
        .max(999999999, "Maximum number exceeded")
        .required("Required"),
      otherwise: Yup.number(),
    }),
    trigger_interval: Yup.number("Should be an integer number greater than equal to 0")
      .integer("Should be an integer number greater than equal to 0")
      .min(0, "Should be an integer number greater than equal to 0")
      .typeError("Should be an integer number greater than equal to 0")
      .max(1209600, "Maximum number exceeded"),
    stop_interval: Yup.number("Should be an integer number greater than equal to 0")
      .integer("Should be an integer number greater than equal to 0")
      .min(0, "Should be an integer number greater than equal to 0")
      .typeError("Should be an integer number greater than equal to 0")
      .max(1209600, "Maximum number exceeded"),
    geofence_id: Yup.string().when("event_type_id", {
      is: (type) => type == "3",
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    speed_limit: Yup.number("Should be a number greater than 0").when("event_type_id", {
      is: (type) => type == "1",
      then: Yup.number()
        .positive("Should be a number greater than 0")
        .test("is-decimal", "Can have maximum 2 decimal digits", (val) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        })
        .typeError("Should be a number greater than 0")
        .max(999999999, "Maximum number exceeded")
        .required("Required"),
      otherwise: Yup.number(),
    }),
    unit: Yup.string().when("event_type_id", {
      is: (type) => type == "1" || type == "4",
      then: Yup.string().typeError("Invalid").required("Required"),
      otherwise: Yup.string(),
    }),
    duration_threashold: Yup.number("Should be an integer number greater than or equal to 0").when(
      "event_type_id",
      {
        is: (type) => type == "1", // If event_type_id is speeding
        then: Yup.number("Should be an integer number greater than or equal to 0")
          .typeError("Should be an integer number greater than or equal to 0")
          .integer("Should be an integer number greater than or equal to 0")
          .min(0, "Should be an integer number greater than or equal to 0")
          .max(999999999, "Maximum number exceeded")
          .required("Required"),
        otherwise: Yup.number().when("event_type_id", {
          is: (type) => type == "7", // If event_type_id is offline
          then: Yup.number("Should be an integer number greater than or equal to 30")
            .typeError("Should be an integer number greater than or equal to 30")
            .integer("Should be an integer number greater than or equal to 30")
            .min(30, "Should be an integer number greater than or equal to 30")
            .max(999999999, "Maximum number exceeded")
            .required("Required"),
          otherwise: Yup.number(),
        }),
      }
    ),

    max_idle_time: Yup.number().when("event_type_id", {
      is: (type) => type == "2",
      then: Yup.number()
        .integer("Should be an integer number greater than Minimum Idle Time")
        .typeError("Should be an integer number greater than Minimum Idle Time")
        .max(999999999, "Maximum number exceeded")
        .moreThan(Yup.ref("min_idle_time"), "Must be greater than Minimum Idle Time")
        .required("Required"),
      otherwise: Yup.number(),
    }),
    report_interval_time: Yup.number("Should be an integer number greater than or equal to 0").when(
      "event_type_id",
      {
        is: (type) => type == 4, // If event_type_id is battery
        then: Yup.number("Should be an integer number greater than or equal to 0")
          .integer("Should be an integer number greater than or equal to 0")
          .min(0, "Should be an integer number greater than or equal to 0")
          .typeError("Should be an integer number greater than or equal to 0")
          .max(999999999, "Maximum number exceeded")
          .required("Required"),
      }
    ),
    battery_type: Yup.string().when("event_type_id", {
      is: (type) => type == "4",
      then: Yup.string().required("Required"),
    }),
    unit_value: Yup.number("Should be a positive number less than or equal to 100")
      .typeError("Should be a positive number less than or equal to 100")
      .when("event_type_id", {
        is: (type) => type == "4",
        then: Yup.number("Should be a positive number less than or equal to 100")
          .typeError("Should be a positive number less than or equal to 100")
          .min(1, "Should be a number greater than or equal to 1")
          .test("is-decimal", "Can have maximum 2 decimal digits", (val) => {
            if (val !== undefined) {
              return patternTwoDigisAfterComma.test(val);
            }
            return true;
          })
          .max(100, "Should be a number less than or equal to 100")
          .required("Required"),
      }),
    geofence_config: Yup.object().shape({
      inside: Yup.object().shape({
        speed_limit: Yup.number()
          .typeError("Should be an integer greater than or equal to 10")
          .max(999999999, "Maximum number exceeded")
          .test({
            message: "Should be an integer greater than or equal to 10",
            test: (value) => value === undefined || (Number.isInteger(value) && value >= 10),
          }),
        duration_limit_in_sec: Yup.number()
          .typeError("Should be an integer greater than or equal to 60")
          .max(999999999, "Maximum number exceeded")
          .test({
            message: "Should be an integer greater than or equal to 60",
            test: (value) => value === undefined || (Number.isInteger(value) && value >= 60),
          }),
      }),
    }),
  },
  ["speed_limit", "unit", "min_idle_time", "duration_threashold", "max_idle_time"]
);

export const TripsFormValidation = Yup.object().shape(
  {
    name: Yup.string().required("Required"),
    plan: Yup.string().typeError("Invalid").required("Required"),
    // min_idle_time: Yup.number("Should be an integer number greater than or equal to 30")
    //   .integer("Should be an integer number greater than or equal to 30")
    //   .min(30, "Should be an integer number greater than or equal to 30")
    //   .typeError("Should be an integer number greater than or equal to 30")
    //   .max(999999999, "Maximum number exceeded")
    //   .required("Required"),
    max_idle_time: Yup.number()
      .integer("Should be an integer number greater than Minimum Idle Time")
      .typeError("Should be an integer number greater than Minimum Idle Time")
      .max(999999999, "Maximum number exceeded")
      .min(60, "Should be an integer number greater than or equal to 60")
      // .moreThan(Yup.ref("min_idle_time"), "Must be greater than Minimum Idle Time")
      .required("Required"),
    min_distance_travelled: Yup.mixed().when("plan", {
      is: (plan) => plan === "gps_based" || plan === "smart",
      then: Yup.number("Should be an integer number greater than 10")
        .typeError("Should be an integer number greater than 10")
        .integer("Should be an integer number greater than 10")
        .min(10, "Should be an integer number greater than 10")
        .required("Required"),
      otherwise: Yup.number(),
    }),
  },
  ["min_distance_travelled", "plan", "max_idle_time"]
);

export const EventConfigurationsPlanFormValidation = Yup.object({
  name: Yup.string().required("Required"),
});

export const reportConfigurationsFormSchema = Yup.object({
  name: Yup.string().required("Required"),
  report_type: Yup.mixed().required("Required"),
  variable_ids: Yup.array().when("report_type", {
    is: (reportType) => reportType === "trackpoint",
    then: Yup.array().min(1, "Required"),
  }),
});

export const ApiKeyValidation = Yup.object({
  name: Yup.string().required("Required"),
  note: Yup.string().required("Required"),
  client_app_role_id: Yup.mixed().required("Required"),
});

export const SimCardFormValidation = Yup.object({
  display_name: Yup.string().required("Required"),
  imsi: Yup.string().required("Required"),
  phone_no: Yup.string()
    .matches(/^[0-9]{3,14}$/, "Not a valid phone number")
    .required("Required"),
  sim_type: Yup.mixed().required("Required"),
  mobile_service_provider_id: Yup.string().required("Required"),
});

export const clientAdminUserValidation = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export const clientFormSchema = Yup.object({
  name: Yup.string()
    .test("len", "Cannot have more than 40 characters", (val) => {
      if (val === undefined || val.length <= 40) {
        return true;
      }
      return false;
    })
    .required("Required"),
  email: Yup.string().email("Not a valid Email").required("Required"),
  alternate_email: Yup.string().email("Not a valid Email"),
  app_ids: Yup.array().min(1, "Add atleast one Application"),
  admin_users: Yup.array().when("admin_user_ids", {
    is: (value) => {
      return !value || value.length === 0;
    },
    then: Yup.array().min(1, "Add atleast one user"),
  }),
  retention_period: Yup.number()
    .typeError("Accept 0 or any positive whole number (decimal not allowed)")
    .integer("Accept 0 or any positive whole number (decimal not allowed)")
    .min(0, "Accept 0 or any positive whole number (decimal not allowed)")
    .max(999999999, "Maximum number exceeded")
    .required("Required"),
});

export const editClientFormSchema = Yup.object({
  name: Yup.string()
    .test("len", "Cannot have more than 40 characters", (val) => {
      if (val === undefined || val.length <= 40) {
        return true;
      }
      return false;
    })
    .required("Required"),
  email: Yup.string().email("Not a valid Email").required("Required"),
  alternate_email: Yup.string().email("Not a valid Email"),
  app_ids: Yup.array().min(1, "Add atleast one Application"),
  retention_period: Yup.number()
    .typeError("Accept 0 or any positive whole number (decimal not allowed)")
    .integer("Accept 0 or any positive whole number (decimal not allowed)")
    .min(0, "Accept 0 or any positive whole number (decimal not allowed)")
    .max(999999999, "Maximum number exceeded")
    .required("Required"),
});

export const commandSendingSchema = Yup.object({
  delivery_mode: Yup.string()
    .when("country_code", {
      is: "+65", // Check if country_code is +65 (Singapore)
      then: Yup.string().oneOf(
        ["tcp"], // delivery_mode must be "TCP" when country_code is "+65"
        "Singapore phn no does not support SMS"
      ),
    })
    .required("Required"),
  command_type: Yup.mixed().required("Required"),
  command: Yup.string().when("command_type", {
    is: (value) => value === "raw",
    then: Yup.string().required("Required"),
  }),
});
