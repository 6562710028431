import { useState, useEffect, useCallback } from "react";
import { VehicleMap } from "../Vehicles/VehicleMap";
import { FMDPStorage } from "../../../shared/helper";
import { useGetDevicesQuery, useGetVehiclesQuery } from "../../ManagementPortal/services";
import { useGetDevicesTrackingQuery } from "../services";
import { useWebSocket } from "../../../hooks";
import { Icon, Style, Fill, Stroke, Text } from "ol/style.js";
import { locationImg } from "../assets/mapIcons";
import mapboxgl from "mapbox-gl";
import { debounce } from "../utils";

// Custom hook to get client configuration
const useClientConfig = () => {
  const [clientId, setClientId] = useState(null);
  const [clientConfig, setClientConfig] = useState({});
  const currentUser = FMDPStorage.get("current-user");
  const selectedAppId = FMDPStorage.get("selected-app-id");
  const [mapProvider, setMapProvider] = useState();
  const [mapboxAccessToken, setMapBoxAccessToken] = useState();
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState();

  useEffect(() => {
    const selectedClientId = JSON.parse(localStorage.getItem("selected-app-id"));
    const currentUser = JSON.parse(localStorage.getItem("current-user"))?.client_apps?.find(
      (item) => item.id === selectedClientId
    );
    setClientId(currentUser?.client?.id);
    if (currentUser?.client?.config_json) {
      try {
        const mapProvider = JSON.parse(currentUser?.client?.config_json)?.tracking?.web?.map;
        const clientConfig = JSON.parse(currentUser?.client?.config_json);

        if (mapProvider?.provider === "mapbox" && mapProvider?.mapboxAccessToken) {
          setClientConfig(clientConfig);
          setMapProvider("mapbox");
          setMapBoxAccessToken(mapProvider.mapboxAccessToken);
          setGoogleMapsApiKey(null);
        } else if (mapProvider?.provider === "google" && mapProvider?.googleMapsApiKey) {
          setClientConfig(clientConfig);
          setMapProvider("google");
          setMapBoxAccessToken(null);
          setGoogleMapsApiKey(mapProvider.googleMapsApiKey);
        } else {
          setClientConfig(clientConfig);
          setMapProvider("qiMap");
          setMapBoxAccessToken(null);
          setGoogleMapsApiKey(null);
        }
      } catch (e) {
        console.error(e);
      }
    }

    return () => setClientId(null);
  }, []);

  return {
    clientId,
    clientConfig,
    currentUser,
    selectedAppId,
    mapProvider,
    googleMapsApiKey,
    mapboxAccessToken,
    setMapProvider,
  };
};

// Custom hook to get user ID
const useUserId = (currentUser, selectedAppId) => {
  return useCallback(() => {
    const currentAppRoles = currentUser?.client_app_roles;
    const currentUserId = currentUser?.id;
    let hasAdminRole = false;

    currentAppRoles?.forEach((clientData) => {
      if (clientData?.client_app_id === selectedAppId) {
        clientData?.roles?.forEach((role) => {
          if (role?.name === "Admin" || role?.name === "Fleet Admin") {
            hasAdminRole = true;
          }
        });
      }
    });

    return hasAdminRole ? "" : currentUserId;
  }, [currentUser, selectedAppId]);
};

// Custom hook to manage tracking data
const useTrackingData = (sourceIds, deviceId, realTimedata) => {
  const { data: trackingData = { data: [] } } = useGetDevicesTrackingQuery({
    device_ids: sourceIds,
  });
  const [mapData, setMapData] = useState({});
  const [trackingPoints, setTrackingPoints] = useState([]);
  const [deviceData, setDeviceData] = useState(null);

  const processTrackingData = useCallback(
    debounce((trackingData, realTimedata) => {
      const filteredPosition = {};
      const points = [];

      trackingData.latest_data.forEach(({ device_data }) => {
        const sourceId = device_data?.source_id;
        const realTimeDataForSource = realTimedata[sourceId];
        const position = realTimeDataForSource?.gps?.position || device_data?.gps?.v?.position;
        const direction =
          realTimeDataForSource?.velocity?.direction || device_data?.velocity?.v?.direction;
        let speed = realTimeDataForSource?.velocity?.speed || device_data?.velocity?.v?.speed;
        const sourceTime =
          realTimeDataForSource?.source_time ||
          device_data?.source_time?.v ||
          device_data?.source_time;
        const serverTime =
          realTimeDataForSource?.server_time ||
          device_data?.server_time?.v ||
          device_data?.server_time;
        const plate_number = device_data?.plate_number?.v;
        speed = speed == 0 || speed ? `${(speed * 3.6).toFixed(1)} km/h` : "Not Available";
        const ignition = realTimeDataForSource?.ignition ?? device_data?.ignition?.v ?? "Unknown";

        if (sourceId && position && position.lat !== 0 && position.lng !== 0) {
          filteredPosition[sourceId] = {
            speed,
            direction,
            ignition,
            sourceTime,
            plate_number,
            serverTime,
          };
          points.push({ ...position, imei: sourceId, direction, speed, plate_number });
        }
      });

      setMapData(filteredPosition);
      setTrackingPoints(points.filter((device) => device.lat !== 0 && device.lng !== 0));
    }, 3000), // Adjust delay as needed
    []
  );

  useEffect(() => {
    let device;
    if (trackingData && trackingData?.latest_data?.length) {
      device =
        (trackingData?.latest_data || []).find(
          ({ device_data }) => device_data?.source_id === deviceId
        ) || {};
      setDeviceData(device?.device_data || {});
    } else {
      setDeviceData({});
    }
  }, [trackingData, deviceId]);

  useEffect(() => {
    if (!trackingData?.latest_data) return;
    processTrackingData(trackingData, realTimedata);
  }, [trackingData, realTimedata, processTrackingData]);

  return { mapData, trackingPoints, deviceData };
};

export const DashBoardContainer = () => {
  const [mapRef, setMapRef] = useState(null);
  const [mapExpand, setMapExpand] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(1000);
  const [q, setQ] = useState("");
  const [sourceIds, setSourceIds] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [count, setCount] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true); // Flag to check if initial load

  const { clientId, currentUser, selectedAppId, mapProvider, mapboxAccessToken, googleMapsApiKey } =
    useClientConfig();
  const getUserId = useUserId(currentUser, selectedAppId);

  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };
  const { data: coreDevices = { data: [], total_count: 0 } } = useGetVehiclesQuery({
    page,
    per_page: perPage,
    q,
    filter_user_id: getUserId(),
  });

  const queryParams = getUserId()
    ? { page, per_page: perPage, device_ids: sourceIds, q }
    : { page, per_page: perPage, q };

  const { data: devicesData = { data: [], total_count: 0 } } = useGetDevicesQuery(queryParams);

  let list_arr;

  useEffect(() => {
    try {
      list_arr = [];
      coreDevices?.data.forEach((element, index) => {
        let x = { className: element.device_name_list + "_cls" };
        list_arr.push({ ...element, ...x });
      });

      let tempDevices = [];

      coreDevices?.data?.map((device) => {
        if (device?.device_name_list) {
          let temp = device?.device_name_list?.split(",");
          tempDevices = temp.concat(tempDevices);
        }
      });

      devicesData?.data?.map((device) => {
        if (device?.device_id) {
          if (!tempDevices?.includes(device?.device_id)) {
            tempDevices.push(device?.device_id);
          }
        }
      });

      setSourceIds((prev) => tempDevices);
    } catch (e) {}
  }, [coreDevices.data, devicesData?.data]);

  const { realTimedata } = useWebSocket(String(clientId), "all", "sub_all");
  const { mapData, trackingPoints, deviceData } = useTrackingData(
    sourceIds,
    deviceId,
    realTimedata
  );

  //Map tracking
  useEffect(() => {
    const layerName = `test_${count}`;
    const prevLayer = `test_${count - 1}`;
    if (mapProvider === "qiMap") {
      if (mapRef && trackingPoints.length) {
        count && mapRef.removeLayer(prevLayer);
        mapRef.addLayer({ name: layerName });
        mapRef.drawTrack({
          layerName: layerName,
          fitWithinView: true,
          trackType: "point",
          data: [{ coordinates: trackingPoints }],
          style: {
            point: {
              style: (feature) => {
                return new Style({
                  image: new Icon({
                    color: "#fff",
                    crossOrigin: "anonymous",
                    src: locationImg,
                    imgSize: [26, 26],
                    rotation: feature.direction,
                  }),
                  text: new Text({
                    text: feature.plate_number || feature.imei,
                    font: "14px Calibri,sans-serif",
                    fill: new Fill({
                      color: "#000", // Black font
                    }),
                    backgroundFill: new Fill({
                      color: "#fff",
                    }),
                    padding: [5, 5, 5, 5], // Padding inside the box
                    offsetY: -30, // Moves the text box above the marker
                    textAlign: "center", // Aligns the text in the center of the box
                    backgroundStroke: new Stroke({
                      color: "#000", // Border color around the box
                      width: 1, // Border width
                    }),
                  }),
                });
              },
            },
          },
        });

        // Only fit the map on initial load
        if (initialLoad) {
          mapRef.performFit(layerName);
          setInitialLoad(false); // Disable initial load after first fit
        }
        //mapRef.performFit(layerName);
      } else {
        mapRef?.removeLayer(`test_${count - 1}`);
      }
      try {
        //Navigating back and forth from Live Tracking
        deviceId && showPontOnMap(deviceId, selectedVehiclePlate);
      } catch (e) {}
    } else if (mapRef && trackingPoints.length && mapProvider === "mapbox" && mapboxAccessToken) {
      const onStyleLoaded = () => {
        // Register a custom icon
        mapRef.loadImage(
          "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
          (error, image) => {
            if (error) throw error;

            // Check if the image is already added
            if (!mapRef.hasImage("custom-icon")) {
              mapRef.addImage("custom-icon", image);
            }

            // Transform data for Mapbox
            const cleanedData = trackingPoints.map((item) => ({
              coordinates: [item.lng, item.lat],
              plate_number: item.plate_number,
              direction: item.direction,
            }));

            // Remove the previous layer if it exists
            if (mapRef.getLayer(layerName)) {
              mapRef.removeLayer(layerName);
              mapRef.removeSource(layerName);
            }

            // Add a new GeoJSON source with tracking points
            mapRef.addSource(layerName, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: cleanedData.map((point) => ({
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: point.coordinates, // [lng, lat]
                  },
                  properties: {
                    plate_number: point.plate_number,
                    direction: point.direction,
                  },
                })),
              },
            });

            // Add a layer for the points
            mapRef.addLayer({
              id: layerName,
              type: "symbol",
              source: layerName,
              layout: {
                "icon-image": "custom-icon", // Custom icon registered in the map
                "icon-size": 0.6,
                "icon-rotate": ["get", "direction"], // Rotate icon based on direction
                "text-field": ["get", "plate_number"],
                "text-size": 12,
                "text-offset": [0, -1.5], // Move text above the marker
                "text-anchor": "top",
              },
              paint: {
                "text-color": "#000000",
                "text-halo-color": "#ffffff",
                "text-halo-width": 1,
              },
            });

            // Ensure selected vehicle appears on top
            mapRef.setLayoutProperty(layerName, "icon-allow-overlap", true);
            /* mapRef.setPaintProperty(layerName, "icon-opacity", [
              "case",
              ["==", ["get", "plate_number"], selectedVehiclePlate],
              1, // Selected vehicle opacity
              0.6, // Non-selected opacity
            ]); */

            // Fit the map to the tracking points on initial load
            if (initialLoad) {
              const coordinates = cleanedData.map((p) => p.coordinates);
              const bounds = coordinates.reduce(
                (bounds, coord) => bounds.extend(coord),
                new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
              );

              mapRef.fitBounds(bounds, { padding: 50, duration: 1000 });
              setInitialLoad(false);
            }

            // Center on a selected vehicle dynamically
            if (deviceId && selectedVehiclePlate) {
              const selectedPoint = cleanedData.find(
                (p) => p.plate_number === selectedVehiclePlate
              );
              if (selectedPoint) {
                mapRef.flyTo({
                  center: selectedPoint.coordinates,
                  zoom: 15,
                  speed: 0.8,
                });
              }
            }
          }
        );
      };

      // Check if the style is already loaded
      if (mapRef.isStyleLoaded()) {
        onStyleLoaded();
      } else {
        // Wait for the style to load
        mapRef.on("style.load", onStyleLoaded);
      }
    } else if (mapProvider === "google" && mapRef && trackingPoints.length && googleMapsApiKey) {
      // For Google Maps
      const bounds = new google.maps.LatLngBounds();

      // Clear previous markers and overlays
      if (window.googleMarkers) {
        window.googleMarkers.forEach((marker) => marker.setMap(null)); // Clear markers
      }
      if (window.googleOverlays) {
        window.googleOverlays.forEach((overlay) => overlay.setMap(null)); // Clear overlays
      }

      // Initialize arrays
      window.googleMarkers = [];
      window.googleOverlays = [];

      // Add markers with styled labels for tracking points
      trackingPoints.forEach((point) => {
        const position = { lat: point.lat, lng: point.lng };

        // Create the marker
        const marker = new google.maps.Marker({
          position,
          map: mapRef,
          icon: {
            url: locationImg, // Marker icon
            scaledSize: new google.maps.Size(26, 26),
          },
        });

        // Custom label styling
        const labelDiv = document.createElement("div");
        labelDiv.style.position = "absolute";
        labelDiv.style.background = "#fff";
        labelDiv.style.color = "#000";
        labelDiv.style.padding = "5px";
        labelDiv.style.border = "1px solid #000";
        labelDiv.style.borderRadius = "4px";
        labelDiv.style.textAlign = "center";
        labelDiv.style.font = "14px Calibri, sans-serif";
        labelDiv.style.transform = "translate(-50%, -50%)"; // Center the label
        labelDiv.innerText = point.plate_number;

        // Add a custom overlay
        const overlay = new google.maps.OverlayView();
        overlay.onAdd = function () {
          const pane = overlay.getPanes().overlayLayer;
          pane.appendChild(labelDiv);
        };
        overlay.draw = function () {
          const projection = overlay.getProjection();
          const pointPos = projection.fromLatLngToDivPixel(position);
          labelDiv.style.left = `${pointPos.x}px`;
          labelDiv.style.top = `${pointPos.y - 30}px`; // Offset above marker
        };
        overlay.onRemove = function () {
          if (labelDiv.parentNode) {
            labelDiv.parentNode.removeChild(labelDiv);
          }
        };
        overlay.setMap(mapRef);

        // Extend bounds for auto-fit
        bounds.extend(position);

        // Save marker and overlay for later removal
        window.googleMarkers.push(marker);
        window.googleOverlays.push(overlay);
      });

      // Fit bounds on initial load
      if (initialLoad) {
        mapRef.fitBounds(bounds);
        setInitialLoad(false);
      }

      if (deviceId && selectedVehiclePlate) {
        const selectedPoint = trackingPoints.find((p) => p.plate_number === selectedVehiclePlate);
        if (selectedPoint) {
          // Center the map on the selected vehicle
          mapRef.panTo({
            lat: selectedPoint.lat,
            lng: selectedPoint.lng,
          });

          // Set zoom level
          mapRef.setZoom(15);

          // Optionally, you can animate this using setTimeout for gradual zoom
          mapRef.panTo({
            lat: selectedPoint.lat,
            lng: selectedPoint.lng,
          });
          setTimeout(() => {
            mapRef.setZoom(15);
          }, 500); // Adjust delay for smoother animation
        }
      }
    }
    //Increase layer count
    setCount((c) => c + 1);
  }, [mapRef, trackingPoints, deviceId, initialLoad, mapProvider]);

  return (
    <>
      <VehicleMap
        mapProvider={mapProvider}
        mapExpand={mapExpand}
        mapRef={mapRef}
        setMapRef={setMapRef}
        mapboxAccessToken={mapboxAccessToken}
        googleMapsApiKey={googleMapsApiKey}
      />
    </>
  );
};
