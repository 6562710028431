import { useEffect } from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes";
import { getLocalizedString } from "../../../shared/translation";

export const WindowTab = ({
  id,
  left,
  top,
  label,
  onClose,
  onMinimise,
  onDragging,
  setShowDetails,
  children,
  setDockAlign,
  selectedVehiclePlate,
  setDeviceId = () => {},
  setShowTrack,
  setHighlight,
  removeLayerOnClose,
  removeRowSelection,
  headerName = getLocalizedString("vehicle_plate_number", "Vehicle Plate Number"),
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.WINDOW,
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  );

  useEffect(() => {
    onDragging(isDragging);
  }, [isDragging]);

  return (
    <div className="window" ref={drag} style={{ top, left }}>
      <div className="flex window-header border">
        <h1>
          {headerName}: {selectedVehiclePlate}
        </h1>
        <div className="flex noselect">
          {/* <div className="minimise">l</div>
          <div className="minimise">b</div>
          <div className="minimise">r</div> */}
          {onMinimise && (
            <div
              className="minimise"
              onClick={() => {
                onMinimise(id, setShowDetails);
                setDockAlign("split-down");
              }}
            >
              -
            </div>
          )}
          {onClose && (
            <div
              className="maximise"
              onClick={() => {
                try {
                  onClose(id);
                  setDeviceId(null);
                  setShowTrack(false);
                  setDockAlign("cross");
                  setHighlight(null);
                  removeRowSelection();
                  removeLayerOnClose();
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              x
            </div>
          )}
        </div>
      </div>
      <div className="window-body">{children}</div>
    </div>
  );
};
