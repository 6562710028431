import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { QICheckBox } from "../../components";
import PropTypes, { func, object } from "prop-types";
import sprite from "./assets.svg";
import { SvgIcon } from "../../containers/Shared/";

/**
 * This is a Table with dynamic number of headers.
 *
 * @param {object} header - An array of objects having list of headers with key
 * header : [
 * {checkbox:true , statusKey: 'id'} // checkbox: if want to render checkbox, statusKey: an key in data-item
  { label: 'Name', key: 'name', sortable: true, defaultSort: true },
  { label: 'Email', key: 'email' },
  { label: 'Age', key: age, sortable: true },
  { label: 'action', type: component, component: ReactComponent } // Will pass row data as data props to React component
  { label: 'Address', nestedValue:true, getNestedValue:()=>getNestedValue(element)} // pass a function to parse nested value
];
 * @param {object}  handler - An object containing functions with key same as header key
 * handler: {
    @param allSelectHandler: A function within handler to select all row. // will get call back when all the rows are selected
    @param checkboxHandler:  A function within handler to select individual  row having parameter to select the row data,
    @param rowHandler: A callback function to onClick event of any row,
  },
 * @param {string}  className - A string to set class to root node
 * @param {array} status - An array which consist the main key to denote a item is selected or not
 * @param {array}  data - An array for data (first priority over fetchData)
 * data: data = [
  { key: 1, name: 'Rahul', email: 'rajsrivastvaa@gmail.com',age: '22' },
  { key: 2, name: 'Rohit', email: 'rajsrivastvaa@gmail.com',age: '21' },
  { key: 3, name: 'Rama', email: 'rajsrivastvaa@gmail.com', age: '23' },
  { key: 4, name: 'Abhishek', email: 'rajsrivastvaa@gmail.com', age: '24' },
  { key: 5, name: 'Abhinav', email: 'rajsrivastvaa@gmail.com', age: '25' },
];
 * @example
 *  <QITable
 *    data={data}
 *    fetchData={() => fetchData}
 *    handler={handler}
 *    headers={headers} />
 *
 */
let sortingStatus = {};
let currentSortKey = false;
let sortType = "asc";

export const QITable = ({
  headers,
  striped,
  className,
  handler,
  data = [],
  status = [],
  tableKey,
  maxHeight = "100%",
  highlight,
  setHighlight,
  listType,
  detailedView = false,
  detailedData,
  listHeaderOptions,
  showSelected = false,
  selectedId = null,
  ...props
}) => {
  const [tableData, setTableData] = useState(data);
  const [showDetails, setShowDetails] = useState({});
  const [selectedRowId, setSelectedRowId] = useState(); // To maintain the selected row

  // Set the selected row if passsed through props
  useEffect(() => {
    setSelectedRowId(selectedId);
  }, [selectedId]);

  useEffect(() => {
    const initialShowDetails = {};
    tableData.forEach((item) => {
      initialShowDetails[item.id] = false;
    });
    setShowDetails(initialShowDetails);
  }, [tableData]);

  useEffect(() => {
    for (let i = 0; i < headers.length; i += 1) {
      if (!currentSortKey && headers[i].defaultSort) {
        currentSortKey = headers[i].key;
      }
      sortingStatus = { ...sortingStatus, [headers[i].key]: false };
    }
    sortingStatus[currentSortKey] = true;
  }, [headers]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const sort = (sortingKey) => {
    if (currentSortKey === sortingKey) {
      sortType = sortType === "asc" ? "desc" : "asc";
    } else {
      sortingStatus[currentSortKey] = false;
      currentSortKey = sortingKey;
      sortingStatus[currentSortKey] = true;
      sortType = "asc";
    }
    // Have to add API CALL SUCH THAT COMPONENT WILL BE RERENDERED
    handler.sortingHandler(sortingKey, sortType);
  };

  const getDisplayText = (text) => {
    if (text === 0) {
      return "0";
    }
    if (!text) {
      return "";
    }
    return text;
  };

  const getTooltip = (header, rowData) => {
    let title = "";

    if (header.nestedValue) {
      const nestedValue = header.getNestedValue(rowData);
      const typeofNestedValue = typeof nestedValue;
      if (typeofNestedValue === "string" || typeofNestedValue === "number") {
        title = nestedValue;
      } else {
        title = rowData[header.key] || header.alternate;
      }
    } else {
      title = rowData[header.key] || header.alternate;
    }
    if (title === undefined || title === null) {
      return ``;
    } else return `${title}`;
  };

  const handleDetails = (element) => {
    setShowDetails((prevState) => {
      const updatedState = {};

      for (const key in prevState) {
        // Set the specific key to its inverse value
        if (key == element.id) {
          updatedState[key] = !prevState[key];
        } else {
          // Set other keys to false
          updatedState[key] = false;
        }
      }
      return updatedState;
    });
  };

  return (
    <section className={`qi-list-view ${className || ""}`}>
      {listHeaderOptions !== "tracking-portal" && (
        <div className="qi-list-view_header">
          {headers.map((element, index) => (
            <div
              data-testid="sort"
              key={index}
              className={`qi-list-view_column ${element.className || ""}`}
              onClick={element.sortable && (() => sort(element.key))}
              title={element.label}
            >
              {element.checkbox && handler.allSelectHandler && (
                <QICheckBox
                  checked={status.length === tableData.length}
                  changeHandler={handler.allSelectHandler}
                />
              )}
              {element.label}
              {element.defaultSort && currentSortKey === false && sort(element.key)}
              {element.sortable && element.defaultSort && currentSortKey === false && (
                <span className="icon-wrapper active">
                  <svg className="icon">
                    <use href={`${sprite}#down-arrow`}></use>
                  </svg>
                </span>
              )}
              {element.sortable && !sortingStatus[element.key] && (
                <span className="icon-wrapper">
                  <svg className="icon">
                    <use href={`${sprite}#down-arrow`}></use>
                  </svg>
                </span>
              )}
              {sortingStatus[element.key] && sortType === "asc" && (
                <span className="icon-wrapper">
                  <svg className="icon">
                    <use href={`${sprite}#down-arrow`}></use>
                  </svg>
                </span>
              )}
              {sortingStatus[element.key] && sortType === "desc" && (
                <span className="icon-wrapper">
                  <svg className="icon">
                    <use href={`${sprite}#down-up`}></use>
                  </svg>
                </span>
              )}
            </div>
          ))}
        </div>
      )}

      <Scrollbars autoHeight autoHeightMax={maxHeight}>
        <ul className="qi-list-view_list">
          {tableData.map((element, index) => {
            return (
              <li
                data-testid="tr"
                key={index}
                id={element?.device_id}
                onClick={() => {
                  setSelectedRowId(index);
                  handler.rowHandler && handler.rowHandler(element);
                  try {
                    setHighlight(element?.id || element?.packet_id);
                  } catch (e) {
                    console.log(e);
                  }
                }}
                className={`qi-list-view_list_item-wrapper ${
                  selectedRowId === index && showSelected ? "selected" : ""
                } ${showDetails[element.id || element?.packet_id] ? "expanded" : ""} ${
                  element?.system_default ? "grey" : ""
                }`}
              >
                <div
                  className={`qi-list-view_list_item ${element.className || ""} ${
                    (element?.id || element?.packet_id) === highlight &&
                    (listType === "Trips" || listType === "Events" || listType === "Vehicles")
                      ? "selected"
                      : ""
                  }`}
                >
                  {headers.map((header, index) => (
                    <React.Fragment key={index}>
                      {header.checkbox && handler.checkboxHandler && (
                        <div
                          data-testid="td"
                          key={header.key}
                          className={`qi-list-view_column ${header.className || ""}`}
                        >
                          <QICheckBox
                            changeHandler={() => handler.checkboxHandler(element)}
                            checked={status.includes(element[header.statusKey])}
                          />
                        </div>
                      )}
                      {!header.checkbox && header.type === "component" && header.component && (
                        <div
                          data-testid="td"
                          key={header.key}
                          className={`qi-list-view_column ${header.className || ""}`}
                        >
                          <strong className="qi-list-view_column_key-label">
                            {header.label} :{" "}
                          </strong>
                          <header.component data={element} />
                        </div>
                      )}
                      {!header.checkbox && !header.type && (
                        <div
                          data-testid="td"
                          key={header.key}
                          className={`qi-list-view_column ${header.className || ""}`}
                          title={getTooltip(header, element)}
                          onClick={handler[header.key]}
                        >
                          <strong className="qi-list-view_column_key-label">
                            {header.label} :{" "}
                          </strong>
                          {header.nestedValue
                            ? header.getNestedValue(element)
                            : getDisplayText(element[header.key]) || header.alternate}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                  {detailedView && (
                    <span className="expand" onClick={() => handleDetails(element)}>
                      <SvgIcon name="up-arrow" wrapperClass="icon-expand" />
                    </span>
                  )}
                </div>
                {showDetails[element.id] && detailedData(element)}
              </li>
            );
          })}
        </ul>
      </Scrollbars>
    </section>
  );
};

QITable.defaultProps = {
  handler: {},
  hover: true,
  striped: true,
  responsive: "lg",
  className: "",
  fetchData: null,
  data: [],
  status: [],
};

QITable.propTypes = {
  headers: PropTypes.arrayOf(Object).isRequired,
  fetchData: PropTypes.func,
  hover: PropTypes.bool,
  striped: PropTypes.bool,
  responsive: PropTypes.oneOf([true, false, "lg", "sm", "md", "xl"]),
  className: PropTypes.string,
  handler: PropTypes.objectOf(func),
  data: PropTypes.arrayOf(object),
  status: PropTypes.array,
  tableKey: PropTypes.objectOf(String),
};
